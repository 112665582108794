import { useState } from 'react';
import Link from '@/components/helper/link';
import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from '@heroicons/react/solid';

export default function CollapsableLocation({
  element, close, closeParent, collapsed = true,
}) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const toggleCollapsed = (e) => {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
  };

  return (
    <ul key={element.name}>
      <li>
        <span className="flex items-center border-t border-gray-900">
          <Link href={element.href}>
            <a
              className={`${element.icon ? '' : ''} hover:underline text-primary flex-grow flex items-center justify-between text-base py-3 font-medium text-left`}
              onClick={() => {
                close();
                closeParent();
              }}
            >
              <span>{element.name}</span>
              {element.icon && (
                <ChevronRightIcon
                  className="flex-shrink-0 h-6 w-6 text-primary"
                  aria-hidden="true"
                />
              )}
            </a>
          </Link>
          {!element.icon && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isCollapsed ? (
                <ChevronDownIcon
                  className="flex-shrink-0 h-6 w-6 text-primary"
                  aria-hidden="true"
                  onClick={toggleCollapsed}
                />
              ) : (
                <ChevronUpIcon
                  className="flex-shrink-0 h-6 w-6 text-primary"
                  aria-hidden="true"
                  onClick={toggleCollapsed}
                />

              )}
            </>
          )}
        </span>
        {element.subList && (
          <ul className={`mb-3 w-full ${isCollapsed ? 'hidden' : ''}`}>
            {element.subList.map((a) => (
              <li key={a.name}>
                <Link
                  href={a.href}
                >
                  <a
                    className="text-primary hover:underline w-full text-left flex justify-start text-base py-1 font-light"
                    onClick={() => {
                      close();
                      closeParent();
                    }}
                  >
                    <ChevronRightIcon className="-ml-1.5 h-5 mr-2 w-5 grow-0 shrink-0" />
                    {' '}
                    <div dangerouslySetInnerHTML={{ __html: a.name }} />
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>
    </ul>
  );
}
