import {
  GlobeAltIcon,
  GlobeIcon,
  NewspaperIcon,
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
} from '@heroicons/react/outline';

import InspoIcon from '../../../../public/assets/icons/inspo.svg';
import MapIcon from '../../../../public/assets/icons/tour.svg';
// import DiscountIcon from '../../../../public/assets/icons/discount.svg';
import BlogIcon from '../../../../public/assets/icons/tips.svg';
import LicenseIcon from '../../../../public/assets/icons/license.svg';
import ChildrenIcon from '../../../../public/assets/icons/child.svg';
import BoatIcon from '../../../../public/assets/icons/boat.svg';
import DogIcon from '../../../../public/assets/icons/dog.svg';
import RouteIcon from '../../../../public/assets/icons/route.svg';
// import InsuranceIcon from '../../../../public/assets/icons/insurance.svg';
import MailIcon from '../../../../public/assets/icons/mail.svg';
import PartnerIcon from '../../../../public/assets/icons/partner.svg';
import appConfig from '../../../../app.config';

import deFlag from '../../../../public/flags/de.svg';
import frFlag from '../../../../public/flags/fr.svg';
import itFlag from '../../../../public/flags/it.svg';
import plFlag from '../../../../public/flags/pl.svg';
import nlFlag from '../../../../public/flags/nl.svg';
import esFlag from '../../../../public/flags/es.svg';
import dkFlag from '../../../../public/flags/dk.svg';
import noFlag from '../../../../public/flags/no.svg';
import hrFlag from '../../../../public/flags/hr.svg';
import slFlag from '../../../../public/flags/sl.svg';
import trFlag from '../../../../public/flags/tr.svg';
import grFlag from '../../../../public/flags/gr.svg';
import isFlag from '../../../../public/flags/is.svg';
import ruFlag from '../../../../public/flags/ru.svg';
import mxFlag from '../../../../public/flags/mx.svg';
import eeFlag from '../../../../public/flags/ee.svg';
import paFlag from '../../../../public/flags/pa.svg';
import caFlag from '../../../../public/flags/ca.svg';
import cyFlag from '../../../../public/flags/cy.svg';
import brFlag from '../../../../public/flags/br.svg';
import muFlag from '../../../../public/flags/mu.svg';
import meFlag from '../../../../public/flags/me.svg';
import ptFlag from '../../../../public/flags/pt.svg';
import roFlag from '../../../../public/flags/ro.svg';
import seFlag from '../../../../public/flags/se.svg';
import beFlag from '../../../../public/flags/be.svg';
import usFlag from '../../../../public/flags/us.svg';
import auFlag from '../../../../public/flags/au.svg';
import gbFlag from '../../../../public/flags/gb.svg';
import mvFlag from '../../../../public/flags/mv.svg';

import Favicon from '../../../../public/icons/favicon3.svg';

const faqs = [
  {
    name: 'Häufige Fragen',
    href: '/faq',
    list: [
      {
        name: 'Über CharterCheck',
        href: '/faq#ueber',
        collapsed: false,
        subList: [
          {
            name: 'Wer ist CharterCheck?',
            href: '/faq#ueber',
          },
          {
            name: 'Bekomme ich woanders günstigere Preise?',
            href: '/faq#ueber',
          },
          {
            name: 'Mit welchen Partnern arbeitet CharterCheck zusammen?',
            href: '/faq#ueber',
          },
          {
            name: 'Kann ich mich auch persönlich beraten lassen?',
            href: '/faq#ueber',
          },
        ],
      },
      {
        name: 'Voraussetzungen',
        href: '/faq#voraussetzungen',
        collapsed: false,
        subList: [
          {
            name: 'Benötige ich einen Bootsführerschein?',
            href: '/faq#voraussetzungen',
          },
          {
            name: 'Wann ist ein Boot führerscheinfrei?',
            href: '/faq#voraussetzungen',
          },
          {
            name: 'Charterbescheinigung oder Fahrtraining?',
            href: '/faq#voraussetzungen',
          },
          {
            name: 'Darf ich auch alleine fahren?',
            href: '/faq#voraussetzungen',
          },
        ],
      },
      {
        name: 'Auswahl Hausboot / Fahrgebiet',
        href: '/faq#auswahl',
        subList: [
          {
            name: 'Wie unterscheiden sich die Bootsklassen',
            href: '/faq#auswahl',
          },
          {
            name: 'Wie sind die Hausboote versichert?',
            href: '/faq#auswahl',
          },
          {
            name: 'Macht eine Zusatzversicherung Sinn?',
            href: '/faq#auswahl',
          },
          {
            name: 'Was ist eine Skipper-Haftpflichtversicherung?',
            href: '/faq#auswahl',
          },
        ],
      },
      {
        name: 'Reservierung & Buchung',
        href: '/faq#buchung',
        subList: [
          {
            name: 'Kann ich auch unverbindlich reservieren?',
            href: '/faq#buchung',
          },
          {
            name: 'Gibt es einen Mindestbuchungszeitraum?',
            href: '/faq#buchung',
          },
          {
            name: 'Wie lauten die Stornierungsbedingungen?',
            href: '/faq#buchung',
          },
          {
            name: 'Wann muss ich die Charter zahlen?',
            href: '/faq#buchung',
          },
        ],
      },
      {
        name: 'An Bord',
        href: '/faq#bord',
        subList: [
          {
            name: 'Kann ich einen Hund mitnehmen?',
            href: '/faq#bord',
          },
          {
            name: 'Mit wie vielen Personen darf ich auf&apos;s Boot?',
            href: '/faq#bord',
          },
          {
            name: 'Was ist der Unterschied zwischen Kojen und Kabinen?',
            href: '/faq#bord',
          },
          {
            name: 'Muss man mit den Booten immer in einem Hafen anlegen?',
            href: '/faq#bord',
          },
          {
            name: 'Kann ich einen Liegeplatz im Hafen reservieren?',
            href: '/faq#bord',
          },
          {
            name: 'Gibt es Kartenmaterial an Bord?',
            href: '/faq#bord',
          },
        ],
      },
    ],
  },
];

const HeaderData = [
  {
    name: 'Reviere',
    href: '/',
    icon: MapIcon,
    list: [
      {
        name: 'Top Reviere',
        header: true,
      },
      {
        name: 'Kroatien',
        icon: hrFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Kroatien',
            href: '/yachtcharter/kroatien',
            list: [
              {
                name: 'Istrien',
                href: '/yachtcharter/kroatien/istrien',
                subList: [
                  {
                    name: 'Pula',
                    href: '/yachtcharter/kroatien/istrien/pula',
                  },
                  {
                    name: 'Pomer',
                    href: '/yachtcharter/kroatien/istrien/pomer',
                  },
                  {
                    name: 'I\u010di\u0107i',
                    href: '/yachtcharter/kroatien/istrien/icici',
                  },
                  {
                    name: 'Rabac',
                    href: '/yachtcharter/kroatien/istrien/rabac',
                  },
                  {
                    name: 'Medulin',
                    href: '/yachtcharter/kroatien/istrien/medulin',
                  },
                  {
                    name: 'Rovinj',
                    href: '/yachtcharter/kroatien/istrien/rovinj',
                  },
                  {
                    name: 'Vrsar',
                    href: '/yachtcharter/kroatien/istrien/vrsar',
                  },
                  {
                    name: 'Vinkuran',
                    href: '/yachtcharter/kroatien/istrien/vinkuran',
                  },
                  {
                    name: 'Trget',
                    href: '/yachtcharter/kroatien/istrien/trget',
                  },
                  {
                    name: 'Marina Tehnomont Veruda, Pula',
                    href: '/yachtcharter/kroatien/istrien/marina-tehnomont-veruda-pula',
                  },
                ],
              },
              {
                name: 'Kvarner Bucht',
                href: '/yachtcharter/kroatien/kvarner-bucht',
                subList: [
                  {
                    name: 'Punat',
                    href: '/yachtcharter/kroatien/kvarner-bucht/punat',
                  },
                  {
                    name: 'Rijeka',
                    href: '/yachtcharter/kroatien/kvarner-bucht/rijeka',
                  },
                  {
                    name: 'Mali Lo\u0161inj',
                    href: '/yachtcharter/kroatien/kvarner-bucht/mali-losinj',
                  },
                  {
                    name: 'Novi Vinodolski',
                    href: '/yachtcharter/kroatien/kvarner-bucht/novi-vinodolski',
                  },
                  {
                    name: 'Kraljevica',
                    href: '/yachtcharter/kroatien/kvarner-bucht/kraljevica',
                  },
                  {
                    name: 'Crikvenica',
                    href: '/yachtcharter/kroatien/kvarner-bucht/crikvenica',
                  },
                  {
                    name: 'Rab',
                    href: '/yachtcharter/kroatien/kvarner-bucht/rab',
                  },
                  {
                    name: 'Malinska',
                    href: '/yachtcharter/kroatien/kvarner-bucht/malinska',
                  },
                ],
              },
              {
                name: 'S\u00fcddalmatien',
                href: '/yachtcharter/kroatien/sueddalmatien',
                subList: [
                  {
                    name: 'Slano',
                    href: '/yachtcharter/kroatien/sueddalmatien/slano',
                  },
                  {
                    name: 'Komolac',
                    href: '/yachtcharter/kroatien/sueddalmatien/komolac',
                  },
                  {
                    name: 'Zaton',
                    href: '/yachtcharter/kroatien/sueddalmatien/zaton',
                  },
                  {
                    name: 'Dubrovnik',
                    href: '/yachtcharter/kroatien/sueddalmatien/dubrovnik',
                  },
                  {
                    name: 'Plo\u010de',
                    href: '/yachtcharter/kroatien/sueddalmatien/ploce',
                  },
                ],
              },
              {
                name: 'Mitteldalmatien',
                href: '/yachtcharter/kroatien/mitteldalmatien',
                subList: [
                  {
                    name: 'Trogir',
                    href: '/yachtcharter/kroatien/mitteldalmatien/trogir',
                  },
                  {
                    name: 'Ba\u0161ka Voda',
                    href: '/yachtcharter/kroatien/mitteldalmatien/baska-voda',
                  },
                  {
                    name: 'Ka\u0161tel Gomilica',
                    href: '/yachtcharter/kroatien/mitteldalmatien/kastel-gomilica',
                  },
                  {
                    name: 'Split',
                    href: '/yachtcharter/kroatien/mitteldalmatien/split',
                  },
                  {
                    name: 'Seget Donji',
                    href: '/yachtcharter/kroatien/mitteldalmatien/seget-donji',
                  },
                  {
                    name: 'Podstrana',
                    href: '/yachtcharter/kroatien/mitteldalmatien/podstrana',
                  },
                  {
                    name: 'Tu\u010depi',
                    href: '/yachtcharter/kroatien/mitteldalmatien/tucepi',
                  },
                  {
                    name: '\u0160olta',
                    href: '/yachtcharter/kroatien/mitteldalmatien/solta',
                  },
                  {
                    name: 'Stobre\u010d',
                    href: '/yachtcharter/kroatien/mitteldalmatien/stobrec',
                  },
                  {
                    name: 'Vini\u0161\u0107e',
                    href: '/yachtcharter/kroatien/mitteldalmatien/vinisce',
                  },
                  {
                    name: 'Roga\u010d',
                    href: '/yachtcharter/kroatien/mitteldalmatien/rogac',
                  },
                  {
                    name: 'Milna',
                    href: '/yachtcharter/kroatien/mitteldalmatien/milna',
                  },
                  {
                    name: 'Ka\u0161tel Luk\u0161i\u0107',
                    href: '/yachtcharter/kroatien/mitteldalmatien/kastel-luksic',
                  },
                ],
              },
              {
                name: 'Norddalmatien',
                href: '/yachtcharter/kroatien/norddalmatien',
                subList: [
                  {
                    name: 'Rogoznica',
                    href: '/yachtcharter/kroatien/norddalmatien/rogoznica',
                  },
                  {
                    name: '\u0160ibenik',
                    href: '/yachtcharter/kroatien/norddalmatien/sibenik',
                  },
                  {
                    name: 'Primo\u0161ten',
                    href: '/yachtcharter/kroatien/norddalmatien/primosten',
                  },
                  {
                    name: 'Jezera',
                    href: '/yachtcharter/kroatien/norddalmatien/jezera',
                  },
                  {
                    name: 'Betina',
                    href: '/yachtcharter/kroatien/norddalmatien/betina',
                  },
                  {
                    name: 'Bibinje',
                    href: '/yachtcharter/kroatien/norddalmatien/bibinje',
                  },
                  {
                    name: 'Murter',
                    href: '/yachtcharter/kroatien/norddalmatien/murter',
                  },
                  {
                    name: 'Pirovac',
                    href: '/yachtcharter/kroatien/norddalmatien/pirovac',
                  },
                  {
                    name: 'Zadar',
                    href: '/yachtcharter/kroatien/norddalmatien/zadar',
                  },
                  {
                    name: 'Tribunj',
                    href: '/yachtcharter/kroatien/norddalmatien/tribunj',
                  },
                  {
                    name: 'Petr\u010dane',
                    href: '/yachtcharter/kroatien/norddalmatien/petrcane',
                  },
                  {
                    name: 'Suko\u0161an',
                    href: '/yachtcharter/kroatien/norddalmatien/sukosan',
                  },
                  {
                    name: 'Kukljica',
                    href: '/yachtcharter/kroatien/norddalmatien/kukljica',
                  },
                  {
                    name: 'Vodice',
                    href: '/yachtcharter/kroatien/norddalmatien/vodice',
                  },
                  {
                    name: 'Biograd',
                    href: '/yachtcharter/kroatien/norddalmatien/biograd',
                  },
                  {
                    name: 'Skradin',
                    href: '/yachtcharter/kroatien/norddalmatien/skradin',
                  },
                  {
                    name: 'Drage',
                    href: '/yachtcharter/kroatien/norddalmatien/drage',
                  },
                  {
                    name: 'Biograd na Moru',
                    href: '/yachtcharter/kroatien/norddalmatien/biograd-na-moru',
                  },
                ],
              },
              {
                name: 'Dalmatien',
                href: '/yachtcharter/kroatien/dalmatien',
                subList: [
                  {
                    name: 'Rogoznica',
                    href: '/yachtcharter/kroatien/dalmatien/rogoznica',
                  },
                  {
                    name: '\u0160ibenik',
                    href: '/yachtcharter/kroatien/dalmatien/sibenik',
                  },
                  {
                    name: 'Primo\u0161ten',
                    href: '/yachtcharter/kroatien/dalmatien/primosten',
                  },
                  {
                    name: 'Trogir',
                    href: '/yachtcharter/kroatien/dalmatien/trogir',
                  },
                  {
                    name: 'Slano',
                    href: '/yachtcharter/kroatien/dalmatien/slano',
                  },
                  {
                    name: 'Komolac',
                    href: '/yachtcharter/kroatien/dalmatien/komolac',
                  },
                  {
                    name: 'Ba\u0161ka Voda',
                    href: '/yachtcharter/kroatien/dalmatien/baska-voda',
                  },
                  {
                    name: 'Ka\u0161tel Gomilica',
                    href: '/yachtcharter/kroatien/dalmatien/kastel-gomilica',
                  },
                  {
                    name: 'Split',
                    href: '/yachtcharter/kroatien/dalmatien/split',
                  },
                  {
                    name: 'Jezera',
                    href: '/yachtcharter/kroatien/dalmatien/jezera',
                  },
                  {
                    name: 'Betina',
                    href: '/yachtcharter/kroatien/dalmatien/betina',
                  },
                  {
                    name: 'Bibinje',
                    href: '/yachtcharter/kroatien/dalmatien/bibinje',
                  },
                  {
                    name: 'Murter',
                    href: '/yachtcharter/kroatien/dalmatien/murter',
                  },
                  {
                    name: 'Biograd',
                    href: '/yachtcharter/kroatien/dalmatien/biograd',
                  },
                  {
                    name: 'Pirovac',
                    href: '/yachtcharter/kroatien/dalmatien/pirovac',
                  },
                  {
                    name: 'Zadar',
                    href: '/yachtcharter/kroatien/dalmatien/zadar',
                  },
                  {
                    name: 'Seget Donji',
                    href: '/yachtcharter/kroatien/dalmatien/seget-donji',
                  },
                  {
                    name: 'Podstrana',
                    href: '/yachtcharter/kroatien/dalmatien/podstrana',
                  },
                  {
                    name: 'Tribunj',
                    href: '/yachtcharter/kroatien/dalmatien/tribunj',
                  },
                  {
                    name: 'Petr\u010dane',
                    href: '/yachtcharter/kroatien/dalmatien/petrcane',
                  },
                  {
                    name: 'Tu\u010depi',
                    href: '/yachtcharter/kroatien/dalmatien/tucepi',
                  },
                  {
                    name: '\u0160olta',
                    href: '/yachtcharter/kroatien/dalmatien/solta',
                  },
                  {
                    name: 'Zaton',
                    href: '/yachtcharter/kroatien/dalmatien/zaton',
                  },
                  {
                    name: 'Suko\u0161an',
                    href: '/yachtcharter/kroatien/dalmatien/sukosan',
                  },
                  {
                    name: 'Dubrovnik',
                    href: '/yachtcharter/kroatien/dalmatien/dubrovnik',
                  },
                  {
                    name: 'Stobre\u010d',
                    href: '/yachtcharter/kroatien/dalmatien/stobrec',
                  },
                  {
                    name: 'Kukljica',
                    href: '/yachtcharter/kroatien/dalmatien/kukljica',
                  },
                  {
                    name: 'Vini\u0161\u0107e',
                    href: '/yachtcharter/kroatien/dalmatien/vinisce',
                  },
                  {
                    name: 'Zaton',
                    href: '/yachtcharter/kroatien/dalmatien/zaton',
                  },
                  {
                    name: 'Vodice',
                    href: '/yachtcharter/kroatien/dalmatien/vodice',
                  },
                  {
                    name: 'Biograd',
                    href: '/yachtcharter/kroatien/dalmatien/biograd',
                  },
                  {
                    name: 'Skradin',
                    href: '/yachtcharter/kroatien/dalmatien/skradin',
                  },
                  {
                    name: 'Plo\u010de',
                    href: '/yachtcharter/kroatien/dalmatien/ploce',
                  },
                  {
                    name: 'Roga\u010d',
                    href: '/yachtcharter/kroatien/dalmatien/rogac',
                  },
                  {
                    name: 'Milna',
                    href: '/yachtcharter/kroatien/dalmatien/milna',
                  },
                  {
                    name: 'Trogir',
                    href: '/yachtcharter/kroatien/dalmatien/trogir',
                  },
                  {
                    name: '\u0160ibenik',
                    href: '/yachtcharter/kroatien/dalmatien/sibenik',
                  },
                  {
                    name: 'Rab',
                    href: '/yachtcharter/kroatien/dalmatien/rab',
                  },
                  {
                    name: 'Zaton',
                    href: '/yachtcharter/kroatien/dalmatien/zaton',
                  },
                  {
                    name: 'Drage',
                    href: '/yachtcharter/kroatien/dalmatien/drage',
                  },
                  {
                    name: 'Ka\u0161tel Luk\u0161i\u0107',
                    href: '/yachtcharter/kroatien/dalmatien/kastel-luksic',
                  },
                  {
                    name: 'Biograd na Moru',
                    href: '/yachtcharter/kroatien/dalmatien/biograd-na-moru',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Griechenland',
        icon: grFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Griechenland',
            href: '/yachtcharter/griechenland',
            list: [
              {
                name: 'Ost Makedonien',
                href: '/yachtcharter/griechenland/ost-makedonien',
                subList: [
                  {
                    name: 'Kavala',
                    href: '/yachtcharter/griechenland/ost-makedonien/kavala',
                  },
                  {
                    name: 'Keramoti',
                    href: '/yachtcharter/griechenland/ost-makedonien/keramoti',
                  },
                  {
                    name: 'Thasos',
                    href: '/yachtcharter/griechenland/ost-makedonien/thasos',
                  },
                  {
                    name: 'Limenas Thassos',
                    href: '/yachtcharter/griechenland/ost-makedonien/limenas-thassos',
                  },
                  {
                    name: 'Limenas Avdiron',
                    href: '/yachtcharter/griechenland/ost-makedonien/limenas-avdiron',
                  },
                ],
              },
              {
                name: 'Makedonien',
                href: '/yachtcharter/griechenland/makedonien',
                subList: [
                  {
                    name: 'Neos Marmaras',
                    href: '/yachtcharter/griechenland/makedonien/neos-marmaras',
                  },
                  {
                    name: 'Thessaloniki',
                    href: '/yachtcharter/griechenland/makedonien/thessaloniki',
                  },
                  {
                    name: 'Ormos Panagias',
                    href: '/yachtcharter/griechenland/makedonien/ormos-panagias',
                  },
                  {
                    name: 'Nikiti',
                    href: '/yachtcharter/griechenland/makedonien/nikiti',
                  },
                  {
                    name: 'Halkidiki',
                    href: '/yachtcharter/griechenland/makedonien/halkidiki',
                  },
                ],
              },
              {
                name: 'Thessalien',
                href: '/yachtcharter/griechenland/thessalien',
                subList: [
                  {
                    name: 'Skiathos',
                    href: '/yachtcharter/griechenland/thessalien/skiathos',
                  },
                  {
                    name: 'Volos',
                    href: '/yachtcharter/griechenland/thessalien/volos',
                  },
                  {
                    name: 'Skopelos',
                    href: '/yachtcharter/griechenland/thessalien/skopelos',
                  },
                  {
                    name: 'Achillio',
                    href: '/yachtcharter/griechenland/thessalien/achillio',
                  },
                ],
              },
              {
                name: 'Ionische Inseln',
                href: '/yachtcharter/griechenland/ionische-inseln',
                subList: [
                  {
                    name: 'Lefkada',
                    href: '/yachtcharter/griechenland/ionische-inseln/lefkada',
                  },
                  {
                    name: 'Sami',
                    href: '/yachtcharter/griechenland/ionische-inseln/sami',
                  },
                  {
                    name: 'Agia Eufimia',
                    href: '/yachtcharter/griechenland/ionische-inseln/agia-eufimia',
                  },
                  {
                    name: 'Zakynthos',
                    href: '/yachtcharter/griechenland/ionische-inseln/zakynthos',
                  },
                  {
                    name: 'Kerkira',
                    href: '/yachtcharter/griechenland/ionische-inseln/kerkira',
                  },
                  {
                    name: 'Sivota',
                    href: '/yachtcharter/griechenland/ionische-inseln/sivota',
                  },
                  {
                    name: 'Corfu',
                    href: '/yachtcharter/griechenland/ionische-inseln/corfu',
                  },
                  {
                    name: 'Kontokali',
                    href: '/yachtcharter/griechenland/ionische-inseln/kontokali',
                  },
                  {
                    name: 'Nikiana',
                    href: '/yachtcharter/griechenland/ionische-inseln/nikiana',
                  },
                  {
                    name: 'Nidri',
                    href: '/yachtcharter/griechenland/ionische-inseln/nidri',
                  },
                  {
                    name: 'Kefalonia',
                    href: '/yachtcharter/griechenland/ionische-inseln/kefalonia',
                  },
                  {
                    name: 'Gouvia',
                    href: '/yachtcharter/griechenland/ionische-inseln/gouvia',
                  },
                  {
                    name: 'Vlicho',
                    href: '/yachtcharter/griechenland/ionische-inseln/vlicho',
                  },
                  {
                    name: 'Corfu, Mandraki',
                    href: '/yachtcharter/griechenland/ionische-inseln/corfu-mandraki',
                  },
                  {
                    name: 'Benitses, Corfu',
                    href: '/yachtcharter/griechenland/ionische-inseln/benitses-corfu',
                  },
                ],
              },
              {
                name: 'West Griechenland',
                href: '/yachtcharter/griechenland/west-griechenland',
                subList: [
                  {
                    name: 'Paleros',
                    href: '/yachtcharter/griechenland/west-griechenland/paleros',
                  },
                  {
                    name: 'Palairos',
                    href: '/yachtcharter/griechenland/west-griechenland/palairos',
                  },
                  {
                    name: 'Patra ',
                    href: '/yachtcharter/griechenland/west-griechenland/patra',
                  },
                ],
              },
              {
                name: 'Peloponnese',
                href: '/yachtcharter/griechenland/peloponnese',
                subList: [
                  {
                    name: 'Kalamata',
                    href: '/yachtcharter/griechenland/peloponnese/kalamata',
                  },
                  {
                    name: 'Ermioni',
                    href: '/yachtcharter/griechenland/peloponnese/ermioni',
                  },
                ],
              },
              {
                name: 'Athen',
                href: '/yachtcharter/griechenland/athen',
                subList: [
                  {
                    name: 'Alimos',
                    href: '/yachtcharter/griechenland/athen/alimos',
                  },
                  {
                    name: 'Lavrio',
                    href: '/yachtcharter/griechenland/athen/lavrio',
                  },
                  {
                    name: 'Elliniko',
                    href: '/yachtcharter/griechenland/athen/elliniko',
                  },
                  {
                    name: 'Piraeus',
                    href: '/yachtcharter/griechenland/athen/piraeus',
                  },
                  {
                    name: 'Athen',
                    href: '/yachtcharter/griechenland/athen/athen',
                  },
                  {
                    name: 'Palaio Faliro',
                    href: '/yachtcharter/griechenland/athen/palaio-faliro',
                  },
                  {
                    name: 'Pireas',
                    href: '/yachtcharter/griechenland/athen/pireas',
                  },
                  {
                    name: 'Glifada',
                    href: '/yachtcharter/griechenland/athen/glifada',
                  },
                  {
                    name: 'Nea Peramos - Attica',
                    href: '/yachtcharter/griechenland/athen/nea-peramos-attica',
                  },
                  {
                    name: 'Athens',
                    href: '/yachtcharter/griechenland/athen/athens',
                  },
                  {
                    name: 'Aegina',
                    href: '/yachtcharter/griechenland/athen/aegina',
                  },
                  {
                    name: 'Kallithea',
                    href: '/yachtcharter/griechenland/athen/kallithea',
                  },
                  {
                    name: 'Elefsina',
                    href: '/yachtcharter/griechenland/athen/elefsina',
                  },
                  {
                    name: 'Salamina',
                    href: '/yachtcharter/griechenland/athen/salamina',
                  },
                  {
                    name: 'Nea Peramos',
                    href: '/yachtcharter/griechenland/athen/nea-peramos',
                  },
                  {
                    name: 'Egina',
                    href: '/yachtcharter/griechenland/athen/egina',
                  },
                  {
                    name: 'Yacht Club Marina Salamina, Athens area',
                    href: '/yachtcharter/griechenland/athen/yacht-club-marina-salamina-athens-area',
                  },
                ],
              },
              {
                name: 'Kreta',
                href: '/yachtcharter/griechenland/kreta',
                subList: [
                  {
                    name: 'Heraklion',
                    href: '/yachtcharter/griechenland/kreta/heraklion',
                  },
                ],
              },
              {
                name: 'South Aegean',
                href: '/yachtcharter/griechenland/south-aegean',
                subList: [
                  {
                    name: 'Kos',
                    href: '/yachtcharter/griechenland/south-aegean/kos',
                  },
                  {
                    name: 'Tourlos',
                    href: '/yachtcharter/griechenland/south-aegean/tourlos',
                  },
                  {
                    name: 'Ermoupoli',
                    href: '/yachtcharter/griechenland/south-aegean/ermoupoli',
                  },
                  {
                    name: 'Syros',
                    href: '/yachtcharter/griechenland/south-aegean/syros',
                  },
                  {
                    name: 'Heraklion',
                    href: '/yachtcharter/griechenland/south-aegean/heraklion',
                  },
                  {
                    name: 'Paros',
                    href: '/yachtcharter/griechenland/south-aegean/paros',
                  },
                  {
                    name: 'Santorini',
                    href: '/yachtcharter/griechenland/south-aegean/santorini',
                  },
                  {
                    name: 'Mykonos',
                    href: '/yachtcharter/griechenland/south-aegean/mykonos',
                  },
                  {
                    name: 'Mikonos',
                    href: '/yachtcharter/griechenland/south-aegean/mikonos',
                  },
                  {
                    name: 'Piso Livadi',
                    href: '/yachtcharter/griechenland/south-aegean/piso-livadi',
                  },
                  {
                    name: 'Rhodos',
                    href: '/yachtcharter/griechenland/south-aegean/rhodos',
                  },
                  {
                    name: 'Ialisos',
                    href: '/yachtcharter/griechenland/south-aegean/ialisos',
                  },
                  {
                    name: 'Naoussa',
                    href: '/yachtcharter/griechenland/south-aegean/naoussa',
                  },
                ],
              },
              {
                name: 'Kykladen',
                href: '/yachtcharter/griechenland/kykladen',
                subList: [
                  {
                    name: 'Tourlos',
                    href: '/yachtcharter/griechenland/kykladen/tourlos',
                  },
                  {
                    name: 'Ermoupoli',
                    href: '/yachtcharter/griechenland/kykladen/ermoupoli',
                  },
                  {
                    name: 'Syros',
                    href: '/yachtcharter/griechenland/kykladen/syros',
                  },
                  {
                    name: 'Paros',
                    href: '/yachtcharter/griechenland/kykladen/paros',
                  },
                  {
                    name: 'Santorini',
                    href: '/yachtcharter/griechenland/kykladen/santorini',
                  },
                  {
                    name: 'Mykonos',
                    href: '/yachtcharter/griechenland/kykladen/mykonos',
                  },
                  {
                    name: 'Mikonos',
                    href: '/yachtcharter/griechenland/kykladen/mikonos',
                  },
                  {
                    name: 'Piso Livadi',
                    href: '/yachtcharter/griechenland/kykladen/piso-livadi',
                  },
                  {
                    name: 'Naoussa',
                    href: '/yachtcharter/griechenland/kykladen/naoussa',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Italien',
        icon: itFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Italien',
            href: '/yachtcharter/italien',
            list: [
              {
                name: 'Venedig / Venezien',
                href: '/yachtcharter/italien/venedig-venezien',
                subList: [
                  {
                    name: 'Chioggia',
                    href: '/yachtcharter/italien/venedig-venezien/chioggia',
                  },
                  {
                    name: 'Navene di Malcesine',
                    href: '/yachtcharter/italien/venedig-venezien/navene-di-malcesine',
                  },
                  {
                    name: 'Caorle ',
                    href: '/yachtcharter/italien/venedig-venezien/caorle',
                  },
                  {
                    name: 'Lignano Sabbiadoro',
                    href: '/yachtcharter/italien/venedig-venezien/lignano-sabbiadoro',
                  },
                  {
                    name: 'Marano',
                    href: '/yachtcharter/italien/venedig-venezien/marano',
                  },
                  {
                    name: 'Casale sul Sile',
                    href: '/yachtcharter/italien/venedig-venezien/casale-sul-sile',
                  },
                  {
                    name: 'Precenicco',
                    href: '/yachtcharter/italien/venedig-venezien/precenicco',
                  },
                  {
                    name: 'Lughignano',
                    href: '/yachtcharter/italien/venedig-venezien/lughignano',
                  },
                  {
                    name: 'Precenicco',
                    href: '/yachtcharter/italien/venedig-venezien/precenicco',
                  },
                ],
              },
              {
                name: 'Sardinien',
                href: '/yachtcharter/italien/sardinien',
                subList: [
                  {
                    name: 'Cagliari',
                    href: '/yachtcharter/italien/sardinien/cagliari',
                  },
                  {
                    name: 'La Maddalena',
                    href: '/yachtcharter/italien/sardinien/la-maddalena',
                  },
                  {
                    name: 'Olbia',
                    href: '/yachtcharter/italien/sardinien/olbia',
                  },
                  {
                    name: 'Genova',
                    href: '/yachtcharter/italien/sardinien/genova',
                  },
                  {
                    name: 'Golfo Aranci',
                    href: '/yachtcharter/italien/sardinien/golfo-aranci',
                  },
                  {
                    name: 'Santa Maria Navarrese',
                    href: '/yachtcharter/italien/sardinien/santa-maria-navarrese',
                  },
                  {
                    name: 'Porto Rotondo',
                    href: '/yachtcharter/italien/sardinien/porto-rotondo',
                  },
                  {
                    name: 'Portisco',
                    href: '/yachtcharter/italien/sardinien/portisco',
                  },
                  {
                    name: 'Carloforte',
                    href: '/yachtcharter/italien/sardinien/carloforte',
                  },
                  {
                    name: 'Cannigione',
                    href: '/yachtcharter/italien/sardinien/cannigione',
                  },
                  {
                    name: 'Alghero',
                    href: '/yachtcharter/italien/sardinien/alghero',
                  },
                  {
                    name: 'Arzachena',
                    href: '/yachtcharter/italien/sardinien/arzachena',
                  },
                  {
                    name: 'Aranci',
                    href: '/yachtcharter/italien/sardinien/aranci',
                  },
                  {
                    name: 'Cannigione Arzachena',
                    href: '/yachtcharter/italien/sardinien/cannigione-arzachena',
                  },
                ],
              },
              {
                name: 'Sizilien',
                href: '/yachtcharter/italien/sizilien',
                subList: [
                  {
                    name: 'Tonnarella',
                    href: '/yachtcharter/italien/sizilien/tonnarella',
                  },
                  {
                    name: 'Marsala',
                    href: '/yachtcharter/italien/sizilien/marsala',
                  },
                  {
                    name: 'Palermo',
                    href: '/yachtcharter/italien/sizilien/palermo',
                  },
                  {
                    name: 'Furnari',
                    href: '/yachtcharter/italien/sizilien/furnari',
                  },
                  {
                    name: 'Ragusa',
                    href: '/yachtcharter/italien/sizilien/ragusa',
                  },
                  {
                    name: 'Milazzo',
                    href: '/yachtcharter/italien/sizilien/milazzo',
                  },
                  {
                    name: 'S. Agata di Militello',
                    href: '/yachtcharter/italien/sizilien/s-agata-di-militello',
                  },
                  {
                    name: "Capo d'Orlando",
                    href: '/yachtcharter/italien/sizilien/capo-dorlando',
                  },
                  {
                    name: 'Trapani',
                    href: '/yachtcharter/italien/sizilien/trapani',
                  },
                  {
                    name: 'San Vito Lo Capo',
                    href: '/yachtcharter/italien/sizilien/san-vito-lo-capo',
                  },
                  {
                    name: 'Lipari',
                    href: '/yachtcharter/italien/sizilien/lipari',
                  },
                  {
                    name: 'Reggio Calabria',
                    href: '/yachtcharter/italien/sizilien/reggio-calabria',
                  },
                  {
                    name: 'Kalkara',
                    href: '/yachtcharter/italien/sizilien/kalkara',
                  },
                  {
                    name: 'San Gregorio-bagnoli',
                    href: '/yachtcharter/italien/sizilien/san-gregorio-bagnoli',
                  },
                  {
                    name: 'Castellammare del Golfo',
                    href: '/yachtcharter/italien/sizilien/castellammare-del-golfo',
                  },
                  {
                    name: 'Il-Kalkara',
                    href: '/yachtcharter/italien/sizilien/il-kalkara',
                  },
                  {
                    name: 'Balestrate',
                    href: '/yachtcharter/italien/sizilien/balestrate',
                  },
                  {
                    name: "Capo D'orlando Marina",
                    href: '/yachtcharter/italien/sizilien/capo-dorlando-marina',
                  },
                  {
                    name: 'Marina Villa Igea, Palermo',
                    href: '/yachtcharter/italien/sizilien/marina-villa-igea-palermo',
                  },
                ],
              },
              {
                name: 'Kalabrien',
                href: '/yachtcharter/italien/kalabrien',
                subList: [
                  {
                    name: 'Marina',
                    href: '/yachtcharter/italien/kalabrien/marina',
                  },
                  {
                    name: 'Tropea',
                    href: '/yachtcharter/italien/kalabrien/tropea',
                  },
                  {
                    name: 'Vibo Marina',
                    href: '/yachtcharter/italien/kalabrien/vibo-marina',
                  },
                  {
                    name: 'Reggio Calabria',
                    href: '/yachtcharter/italien/kalabrien/reggio-calabria',
                  },
                ],
              },
              {
                name: 'Apulien',
                href: '/yachtcharter/italien/apulien',
                subList: [
                  {
                    name: 'Bari',
                    href: '/yachtcharter/italien/apulien/bari',
                  },
                  {
                    name: 'Rodi',
                    href: '/yachtcharter/italien/apulien/rodi',
                  },
                  {
                    name: 'San Foca',
                    href: '/yachtcharter/italien/apulien/san-foca',
                  },
                  {
                    name: 'Leuca',
                    href: '/yachtcharter/italien/apulien/leuca',
                  },
                  {
                    name: 'Taranto',
                    href: '/yachtcharter/italien/apulien/taranto',
                  },
                ],
              },
              {
                name: 'Basilikata',
                href: '/yachtcharter/italien/basilikata',
                subList: [
                  {
                    name: 'Marina',
                    href: '/yachtcharter/italien/basilikata/marina',
                  },
                ],
              },
              {
                name: 'Kampanien',
                href: '/yachtcharter/italien/kampanien',
                subList: [
                  {
                    name: 'Mergellina',
                    href: '/yachtcharter/italien/kampanien/mergellina',
                  },
                  {
                    name: 'Pozzuoli',
                    href: '/yachtcharter/italien/kampanien/pozzuoli',
                  },
                  {
                    name: 'Procida',
                    href: '/yachtcharter/italien/kampanien/procida',
                  },
                  {
                    name: 'Castellammare di Stabia',
                    href: '/yachtcharter/italien/kampanien/castellammare-di-stabia',
                  },
                  {
                    name: 'Agropoli',
                    href: '/yachtcharter/italien/kampanien/agropoli',
                  },
                  {
                    name: 'Amalfi',
                    href: '/yachtcharter/italien/kampanien/amalfi',
                  },
                  {
                    name: 'Porto di Castellammare di Stabia',
                    href: '/yachtcharter/italien/kampanien/porto-di-castellammare-di-stabia',
                  },
                  {
                    name: 'Salerno',
                    href: '/yachtcharter/italien/kampanien/salerno',
                  },
                  {
                    name: 'Napoli',
                    href: '/yachtcharter/italien/kampanien/napoli',
                  },
                  {
                    name: 'Naples',
                    href: '/yachtcharter/italien/kampanien/naples',
                  },
                  {
                    name: 'Neapel',
                    href: '/yachtcharter/italien/kampanien/neapel',
                  },
                ],
              },
              {
                name: 'Abruzzen',
                href: '/yachtcharter/italien/abruzzen',
                subList: [
                  {
                    name: 'Marina di Montenero di Bisaccia',
                    href: '/yachtcharter/italien/abruzzen/marina-di-montenero-di-bisaccia',
                  },
                  {
                    name: 'San Benedetto del Tronto',
                    href: '/yachtcharter/italien/abruzzen/san-benedetto-del-tronto',
                  },
                ],
              },
              {
                name: 'Latium',
                href: '/yachtcharter/italien/latium',
                subList: [
                  {
                    name: 'Rome',
                    href: '/yachtcharter/italien/latium/rome',
                  },
                  {
                    name: 'Anzio',
                    href: '/yachtcharter/italien/latium/anzio',
                  },
                  {
                    name: 'Nettuno',
                    href: '/yachtcharter/italien/latium/nettuno',
                  },
                ],
              },
              {
                name: 'Marken',
                href: '/yachtcharter/italien/marken',
                subList: [
                  {
                    name: 'San Benedetto del Tronto',
                    href: '/yachtcharter/italien/marken/san-benedetto-del-tronto',
                  },
                ],
              },
              {
                name: 'Toskana',
                href: '/yachtcharter/italien/toskana',
                subList: [
                  {
                    name: 'Cecina',
                    href: '/yachtcharter/italien/toskana/cecina',
                  },
                  {
                    name: 'Puntone',
                    href: '/yachtcharter/italien/toskana/puntone',
                  },
                  {
                    name: 'San Vincenzo',
                    href: '/yachtcharter/italien/toskana/san-vincenzo',
                  },
                  {
                    name: 'La Spezia',
                    href: '/yachtcharter/italien/toskana/la-spezia',
                  },
                  {
                    name: 'Viareggio',
                    href: '/yachtcharter/italien/toskana/viareggio',
                  },
                  {
                    name: 'Punta Ala',
                    href: '/yachtcharter/italien/toskana/punta-ala',
                  },
                  {
                    name: 'Portoferraio',
                    href: '/yachtcharter/italien/toskana/portoferraio',
                  },
                  {
                    name: 'Portovenere',
                    href: '/yachtcharter/italien/toskana/portovenere',
                  },
                  {
                    name: 'Bocca di Magra',
                    href: '/yachtcharter/italien/toskana/bocca-di-magra',
                  },
                  {
                    name: 'Castiglioncello',
                    href: '/yachtcharter/italien/toskana/castiglioncello',
                  },
                  {
                    name: 'Rosignano Solvay',
                    href: '/yachtcharter/italien/toskana/rosignano-solvay',
                  },
                  {
                    name: 'Salivoli',
                    href: '/yachtcharter/italien/toskana/salivoli',
                  },
                  {
                    name: 'Marina di Scarlino - Follonica',
                    href: '/yachtcharter/italien/toskana/marina-di-scarlino-follonica',
                  },
                  {
                    name: 'Rosignano Solvay-Castiglioncello',
                    href: '/yachtcharter/italien/toskana/rosignano-solvay-castiglioncello',
                  },
                ],
              },
              {
                name: 'Emilia Romana',
                href: '/yachtcharter/italien/emilia-romana',
                subList: [
                  {
                    name: 'Ravenna',
                    href: '/yachtcharter/italien/emilia-romana/ravenna',
                  },
                ],
              },
              {
                name: 'Ligurien',
                href: '/yachtcharter/italien/ligurien',
                subList: [
                  {
                    name: 'La Spezia',
                    href: '/yachtcharter/italien/ligurien/la-spezia',
                  },
                  {
                    name: 'Portovenere',
                    href: '/yachtcharter/italien/ligurien/portovenere',
                  },
                  {
                    name: 'Bocca di Magra',
                    href: '/yachtcharter/italien/ligurien/bocca-di-magra',
                  },
                ],
              },
              {
                name: 'Friaul-Julisch Venetien',
                href: '/yachtcharter/italien/friaul-julisch-venetien',
                subList: [
                  {
                    name: 'Izola',
                    href: '/yachtcharter/italien/friaul-julisch-venetien/izola',
                  },
                  {
                    name: 'Muggia',
                    href: '/yachtcharter/italien/friaul-julisch-venetien/muggia',
                  },
                  {
                    name: 'Lignano Sabbiadoro',
                    href: '/yachtcharter/italien/friaul-julisch-venetien/lignano-sabbiadoro',
                  },
                  {
                    name: 'Marano',
                    href: '/yachtcharter/italien/friaul-julisch-venetien/marano',
                  },
                  {
                    name: 'Marina Portoro\u017e',
                    href: '/yachtcharter/italien/friaul-julisch-venetien/marina-portoroz',
                  },
                  {
                    name: 'Precenicco',
                    href: '/yachtcharter/italien/friaul-julisch-venetien/precenicco',
                  },
                  {
                    name: 'Precenicco',
                    href: '/yachtcharter/italien/friaul-julisch-venetien/precenicco',
                  },
                ],
              },
              {
                name: 'Elba',
                href: '/yachtcharter/italien/elba',
                subList: [
                  {
                    name: 'Portoferraio',
                    href: '/yachtcharter/italien/elba/portoferraio',
                  },
                ],
              },
              {
                name: 'Neapel',
                href: '/yachtcharter/italien/neapel',
                subList: [
                  {
                    name: 'Mergellina',
                    href: '/yachtcharter/italien/neapel/mergellina',
                  },
                  {
                    name: 'Pozzuoli',
                    href: '/yachtcharter/italien/neapel/pozzuoli',
                  },
                  {
                    name: 'Procida',
                    href: '/yachtcharter/italien/neapel/procida',
                  },
                  {
                    name: 'Napoli',
                    href: '/yachtcharter/italien/neapel/napoli',
                  },
                  {
                    name: 'Naples',
                    href: '/yachtcharter/italien/neapel/naples',
                  },
                  {
                    name: 'Neapel',
                    href: '/yachtcharter/italien/neapel/neapel',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Karibik',
        icon: usFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Karibik',
            href: '/yachtcharter/karibik',
            list: [
              {
                name: 'Britische Jungferninseln',
                href: '/yachtcharter/karibik/britische-jungferninseln',
                subList: [
                  {
                    name: 'Road Town',
                    href: '/yachtcharter/karibik/britische-jungferninseln/road-town',
                  },
                  {
                    name: 'Nanny Cay',
                    href: '/yachtcharter/karibik/britische-jungferninseln/nanny-cay',
                  },
                  {
                    name: 'St. Thomas',
                    href: '/yachtcharter/karibik/britische-jungferninseln/st-thomas',
                  },
                  {
                    name: 'Red Hook',
                    href: '/yachtcharter/karibik/britische-jungferninseln/red-hook',
                  },
                  {
                    name: 'Tortola',
                    href: '/yachtcharter/karibik/britische-jungferninseln/tortola',
                  },
                  {
                    name: 'Tortola West End',
                    href: '/yachtcharter/karibik/britische-jungferninseln/tortola-west-end',
                  },
                  {
                    name: 'St. Thomas',
                    href: '/yachtcharter/karibik/britische-jungferninseln/st-thomas',
                  },
                  {
                    name: 'BVI, Scrub Island Marina',
                    href: '/yachtcharter/karibik/britische-jungferninseln/bvi-scrub-island-marina',
                  },
                  {
                    name: 'St. Thomas',
                    href: '/yachtcharter/karibik/britische-jungferninseln/st-thomas',
                  },
                  {
                    name: 'Bimini Marina, St Thomas, USVI',
                    href: '/yachtcharter/karibik/britische-jungferninseln/bimini-marina-st-thomas-usvi',
                  },
                  {
                    name: 'St. Thomas',
                    href: '/yachtcharter/karibik/britische-jungferninseln/st-thomas',
                  },
                ],
              },
              {
                name: 'Grenada',
                href: '/yachtcharter/karibik/grenada',
                subList: [
                  {
                    name: 'Grenada / St. George',
                    href: '/yachtcharter/karibik/grenada/grenada-st-george',
                  },
                  {
                    name: "Saint George's",
                    href: '/yachtcharter/karibik/grenada/saint-georges',
                  },
                  {
                    name: "St George's",
                    href: '/yachtcharter/karibik/grenada/st-georges',
                  },
                ],
              },
              {
                name: 'Guadeloupe',
                href: '/yachtcharter/karibik/guadeloupe',
                subList: [
                  {
                    name: 'Pointe a Pitre',
                    href: '/yachtcharter/karibik/guadeloupe/pointe-a-pitre',
                  },
                  {
                    name: 'Guadeloupe',
                    href: '/yachtcharter/karibik/guadeloupe/guadeloupe',
                  },
                  {
                    name: 'Pointe-\u00e0-Pitre',
                    href: '/yachtcharter/karibik/guadeloupe/pointe-a-pitre',
                  },
                ],
              },
              {
                name: 'Tortola',
                href: '/yachtcharter/karibik/tortola',
                subList: [
                  {
                    name: 'Road Town',
                    href: '/yachtcharter/karibik/tortola/road-town',
                  },
                  {
                    name: 'Nanny Cay',
                    href: '/yachtcharter/karibik/tortola/nanny-cay',
                  },
                  {
                    name: 'Tortola',
                    href: '/yachtcharter/karibik/tortola/tortola',
                  },
                  {
                    name: 'Tortola West End',
                    href: '/yachtcharter/karibik/tortola/tortola-west-end',
                  },
                ],
              },
              {
                name: 'Kuba',
                href: '/yachtcharter/karibik/kuba',
                subList: [
                  {
                    name: 'Cienfuegos',
                    href: '/yachtcharter/karibik/kuba/cienfuegos',
                  },
                ],
              },
              {
                name: 'Martinique',
                href: '/yachtcharter/karibik/martinique',
                subList: [
                  {
                    name: 'Le Marin',
                    href: '/yachtcharter/karibik/martinique/le-marin',
                  },
                ],
              },
              // {
              //  name: 'St. Lucia',
              //  href: '/yachtcharter/karibik/st-lucia',
              //  subList: [
              //    {
              //      name: 'St. Lucia',
              //      href: '/yachtcharter/karibik/st-lucia/st-lucia',
              //    },
              //  ],
              // },
              {
                name: 'Antigua',
                href: '/yachtcharter/karibik/antigua',
                subList: [
                  {
                    name: 'Antigua',
                    href: '/yachtcharter/karibik/antigua/antigua',
                  },
                  {
                    name: 'Jolly Harbour',
                    href: '/yachtcharter/karibik/antigua/jolly-harbour',
                  },
                  {
                    name: 'Antigua, Jolly Harbour Marina',
                    href: '/yachtcharter/karibik/antigua/antigua-jolly-harbour-marina',
                  },
                ],
              },
              {
                name: 'St. Martin',
                href: '/yachtcharter/karibik/st-martin',
                subList: [
                  {
                    name: 'Marigot',
                    href: '/yachtcharter/karibik/st-martin/marigot',
                  },
                  {
                    name: 'Saint Martin',
                    href: '/yachtcharter/karibik/st-martin/saint-martin',
                  },
                  {
                    name: 'Sint Maarten',
                    href: '/yachtcharter/karibik/st-martin/sint-maarten',
                  },
                  {
                    name: 'Saint Martin',
                    href: '/yachtcharter/karibik/st-martin/saint-martin',
                  },
                  {
                    name: 'Philipsburg',
                    href: '/yachtcharter/karibik/st-martin/philipsburg',
                  },
                ],
              },
              // {
              //  name: 'St. Vincent und die Grenadinen',
              //  href: '/yachtcharter/karibik/st-vincent-und-die-grenadinen',
              //  subList: [
              //    {
              //      name: 'Kingstown',
              //      href: '/yachtcharter/karibik/st-vincent-und-die-grenadinen/kingstown',
              //    },
              //    {
              //      name: 'St Vincent',
              //      href: '/yachtcharter/karibik/st-vincent-und-die-grenadinen/st-vincent',
              //   },
              //  ],
              // },
              {
                name: 'Bahamas',
                href: '/yachtcharter/karibik/bahamas',
                subList: [
                  {
                    name: 'Nassau',
                    href: '/yachtcharter/karibik/bahamas/nassau',
                  },
                  {
                    name: 'Marsh Harbour',
                    href: '/yachtcharter/karibik/bahamas/marsh-harbour',
                  },
                  {
                    name: 'George Town',
                    href: '/yachtcharter/karibik/bahamas/george-town',
                  },
                  {
                    name: 'Nassau, Palm Cay Marina',
                    href: '/yachtcharter/karibik/bahamas/nassau-palm-cay-marina',
                  },
                  {
                    name: 'Staniel Cay',
                    href: '/yachtcharter/karibik/bahamas/staniel-cay',
                  },
                  {
                    name: 'Spanish Wells',
                    href: '/yachtcharter/karibik/bahamas/spanish-wells',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Deutschland',
        icon: deFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Deutschland',
            href: '/yachtcharter/deutschland',
            list: [
              {
                name: 'Ostsee und Peene',
                href: '/yachtcharter/deutschland/ostsee-und-peene',
                subList: [
                  {
                    name: 'Barth',
                    href: '/yachtcharter/deutschland/ostsee-und-peene/barth',
                  },
                  {
                    name: 'Neukalen',
                    href: '/yachtcharter/deutschland/ostsee-und-peene/neukalen',
                  },
                  {
                    name: 'Kr\u00f6slin',
                    href: '/yachtcharter/deutschland/ostsee-und-peene/kroeslin',
                  },
                  {
                    name: 'L\u00fcbeck',
                    href: '/yachtcharter/deutschland/ostsee-und-peene/luebeck',
                  },
                  {
                    name: 'Putbus',
                    href: '/yachtcharter/deutschland/ostsee-und-peene/putbus',
                  },
                  {
                    name: 'Kiel',
                    href: '/yachtcharter/deutschland/ostsee-und-peene/kiel',
                  },
                  {
                    name: 'Altef\u00e4hr',
                    href: '/yachtcharter/deutschland/ostsee-und-peene/altefaehr',
                  },
                  {
                    name: 'Ribnitz-Damgarten',
                    href: '/yachtcharter/deutschland/ostsee-und-peene/ribnitz-damgarten',
                  },
                  {
                    name: 'Peenem\u00fcnde',
                    href: '/yachtcharter/deutschland/ostsee-und-peene/peenemuende',
                  },
                ],
              },
              {
                name: 'R\u00fcgen',
                href: '/yachtcharter/deutschland/ruegen',
                subList: [
                  {
                    name: 'Putbus',
                    href: '/yachtcharter/deutschland/ruegen/putbus',
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        name: 'Länder',
        header: true,
      },
      {
        name: 'Frankreich',
        icon: frFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Frankreich',
            href: '/yachtcharter/frankreich',
            list: [
              {
                name: 'Bretagne',
                href: '/yachtcharter/frankreich/bretagne',
                subList: [
                  {
                    name: 'Melesse',
                    href: '/yachtcharter/frankreich/bretagne/melesse',
                  },
                  {
                    name: 'Saint-Martin-sur-Oust',
                    href: '/yachtcharter/frankreich/bretagne/saint-martin-sur-oust',
                  },
                  {
                    name: 'Gl\u00e9nac',
                    href: '/yachtcharter/frankreich/bretagne/glenac',
                  },
                  {
                    name: 'Messac',
                    href: '/yachtcharter/frankreich/bretagne/messac',
                  },
                  {
                    name: 'La Trinit\u00e9-sur-Mer',
                    href: '/yachtcharter/frankreich/bretagne/la-trinite-sur-mer',
                  },
                  {
                    name: 'Redon',
                    href: '/yachtcharter/frankreich/bretagne/redon',
                  },
                  {
                    name: 'Saint Nicolas-des-Eaux',
                    href: '/yachtcharter/frankreich/bretagne/saint-nicolas-des-eaux',
                  },
                  {
                    name: 'Pl\u00e8neuf-Val-Andr\u00e8',
                    href: '/yachtcharter/frankreich/bretagne/pleneuf-val-andre',
                  },
                  {
                    name: 'Messac',
                    href: '/yachtcharter/frankreich/bretagne/messac',
                  },
                ],
              },
              {
                name: 'Okzitanien',
                href: '/yachtcharter/frankreich/okzitanien',
                subList: [
                  {
                    name: 'Lattes',
                    href: '/yachtcharter/frankreich/okzitanien/lattes',
                  },
                  {
                    name: 'Argens-Minervois',
                    href: '/yachtcharter/frankreich/okzitanien/argens-minervois',
                  },
                  {
                    name: 'Lauragais',
                    href: '/yachtcharter/frankreich/okzitanien/lauragais',
                  },
                  {
                    name: 'Valence-sur-Ba\u00efse',
                    href: '/yachtcharter/frankreich/okzitanien/valence-sur-baise',
                  },
                  // {
                  //  name: 'Cahors',
                  //  href: '/yachtcharter/frankreich/okzitanien/cahors',
                  // },
                  {
                    name: 'Bellegarde',
                    href: '/yachtcharter/frankreich/okzitanien/bellegarde',
                  },
                  {
                    name: "Saint-Nazaire-d'Aude",
                    href: '/yachtcharter/frankreich/okzitanien/saint-nazaire-daude',
                  },
                  {
                    name: 'Lauragais',
                    href: '/yachtcharter/frankreich/okzitanien/lauragais',
                  },
                  {
                    name: 'Bram',
                    href: '/yachtcharter/frankreich/okzitanien/bram',
                  },
                  {
                    name: 'Douelle',
                    href: '/yachtcharter/frankreich/okzitanien/douelle',
                  },
                  {
                    name: 'Tr\u00e8bes',
                    href: '/yachtcharter/frankreich/okzitanien/trebes',
                  },
                  {
                    name: 'Capestang',
                    href: '/yachtcharter/frankreich/okzitanien/capestang',
                  },
                  {
                    name: 'Castelnaudary ',
                    href: '/yachtcharter/frankreich/okzitanien/castelnaudary',
                  },
                  {
                    name: 'Homps',
                    href: '/yachtcharter/frankreich/okzitanien/homps',
                  },
                  {
                    name: 'Le Somail',
                    href: '/yachtcharter/frankreich/okzitanien/le-somail',
                  },
                  {
                    name: 'Agde',
                    href: '/yachtcharter/frankreich/okzitanien/agde',
                  },
                  {
                    name: 'Carcassonne',
                    href: '/yachtcharter/frankreich/okzitanien/carcassonne',
                  },
                  {
                    name: 'Carnon',
                    href: '/yachtcharter/frankreich/okzitanien/carnon',
                  },
                  {
                    name: 'Colombiers',
                    href: '/yachtcharter/frankreich/okzitanien/colombiers',
                  },
                  {
                    name: 'Luzech',
                    href: '/yachtcharter/frankreich/okzitanien/luzech',
                  },
                  {
                    name: 'Portiranges',
                    href: '/yachtcharter/frankreich/okzitanien/portiranges',
                  },
                  {
                    name: 'St. Gilles',
                    href: '/yachtcharter/frankreich/okzitanien/st-gilles',
                  },
                ],
              },
              {
                name: 'Nouvelle-Aquitanien',
                href: '/yachtcharter/frankreich/nouvelle-aquitanien',
                subList: [
                  {
                    name: 'Agen',
                    href: '/yachtcharter/frankreich/nouvelle-aquitanien/agen',
                  },
                  {
                    name: 'Buzet-sur-Baise',
                    href: '/yachtcharter/frankreich/nouvelle-aquitanien/buzet-sur-baise',
                  },
                  {
                    name: 'Sireuil',
                    href: '/yachtcharter/frankreich/nouvelle-aquitanien/sireuil',
                  },
                  {
                    name: "Le Mas d'Agenais",
                    href: '/yachtcharter/frankreich/nouvelle-aquitanien/le-mas-dagenais',
                  },
                  {
                    name: 'Jarnac',
                    href: '/yachtcharter/frankreich/nouvelle-aquitanien/jarnac',
                  },
                  {
                    name: 'La Rochelle',
                    href: '/yachtcharter/frankreich/nouvelle-aquitanien/la-rochelle',
                  },
                  {
                    name: 'Cognac',
                    href: '/yachtcharter/frankreich/nouvelle-aquitanien/cognac',
                  },
                  {
                    name: "Le Mas d'Agenais",
                    href: '/yachtcharter/frankreich/nouvelle-aquitanien/le-mas-dagenais',
                  },
                ],
              },
              {
                name: 'Korsika',
                href: '/yachtcharter/frankreich/korsika',
                subList: [
                  {
                    name: 'Propriano',
                    href: '/yachtcharter/frankreich/korsika/propriano',
                  },
                  {
                    name: 'Ajaccio',
                    href: '/yachtcharter/frankreich/korsika/ajaccio',
                  },
                ],
              },
              {
                name: 'Neukaledonien',
                href: '/yachtcharter/frankreich/neukaledonien',
                subList: [
                  {
                    name: 'Noumea',
                    href: '/yachtcharter/frankreich/neukaledonien/noumea',
                  },
                ],
              },
              {
                name: 'Canal du Midi',
                href: '/yachtcharter/frankreich/canal-du-midi',
                subList: [
                  {
                    name: 'Argens-Minervois',
                    href: '/yachtcharter/frankreich/canal-du-midi/argens-minervois',
                  },
                  {
                    name: 'Lauragais',
                    href: '/yachtcharter/frankreich/canal-du-midi/lauragais',
                  },
                  {
                    name: "Saint-Nazaire-d'Aude",
                    href: '/yachtcharter/frankreich/canal-du-midi/saint-nazaire-daude',
                  },
                  {
                    name: 'Bram',
                    href: '/yachtcharter/frankreich/canal-du-midi/bram',
                  },
                  {
                    name: 'Tr\u00e8bes',
                    href: '/yachtcharter/frankreich/canal-du-midi/trebes',
                  },
                  {
                    name: 'Capestang',
                    href: '/yachtcharter/frankreich/canal-du-midi/capestang',
                  },
                  {
                    name: 'Castelnaudary ',
                    href: '/yachtcharter/frankreich/canal-du-midi/castelnaudary',
                  },
                  {
                    name: 'Homps',
                    href: '/yachtcharter/frankreich/canal-du-midi/homps',
                  },
                  {
                    name: 'Le Somail',
                    href: '/yachtcharter/frankreich/canal-du-midi/le-somail',
                  },
                  {
                    name: 'Agde',
                    href: '/yachtcharter/frankreich/canal-du-midi/agde',
                  },
                  {
                    name: 'Carcassonne',
                    href: '/yachtcharter/frankreich/canal-du-midi/carcassonne',
                  },
                  {
                    name: 'Colombiers',
                    href: '/yachtcharter/frankreich/canal-du-midi/colombiers',
                  },
                  {
                    name: 'Portiranges',
                    href: '/yachtcharter/frankreich/canal-du-midi/portiranges',
                  },
                ],
              },
              {
                name: 'Franz\u00f6sische Mittelmeerk\u00fcste',
                href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste',
                subList: [
                  {
                    name: 'Lattes',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/lattes',
                  },
                  {
                    name: 'Bellegarde',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/bellegarde',
                  },
                  {
                    name: 'Saint-Mandrier-sur-Mer',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/saint-mandrier-sur-mer',
                  },
                  {
                    name: 'Bormes-les-Mimosas',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/bormes-les-mimosas',
                  },
                  {
                    name: 'Cogolin',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/cogolin',
                  },
                  {
                    name: 'Hyeres',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/hyeres',
                  },
                  {
                    name: 'Nice',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/nice',
                  },
                  {
                    name: 'Toulon',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/toulon',
                  },
                  {
                    name: 'Marseille',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/marseille',
                  },
                  {
                    name: 'Grimaud',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/grimaud',
                  },
                  {
                    name: 'Vallauris',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/vallauris',
                  },
                  {
                    name: 'Saint-Rapha\u00ebl',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/saint-raphael',
                  },
                  {
                    name: 'Agde',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/agde',
                  },
                  {
                    name: 'Carnon',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/carnon',
                  },
                  {
                    name: 'Colombiers',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/colombiers',
                  },
                  {
                    name: 'Portiranges',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/portiranges',
                  },
                  {
                    name: 'St. Gilles',
                    href: '/yachtcharter/frankreich/franzoesische-mittelmeerkueste/st-gilles',
                  },
                ],
              },
              {
                name: 'C\u00f4te d\u2019Azur',
                href: '/yachtcharter/frankreich/cote-d-azur',
                subList: [
                  {
                    name: 'Saint-Mandrier-sur-Mer',
                    href: '/yachtcharter/frankreich/cote-d-azur/saint-mandrier-sur-mer',
                  },
                  {
                    name: 'Bormes-les-Mimosas',
                    href: '/yachtcharter/frankreich/cote-d-azur/bormes-les-mimosas',
                  },
                  {
                    name: 'Cogolin',
                    href: '/yachtcharter/frankreich/cote-d-azur/cogolin',
                  },
                  {
                    name: 'Hyeres',
                    href: '/yachtcharter/frankreich/cote-d-azur/hyeres',
                  },
                  {
                    name: 'Nice',
                    href: '/yachtcharter/frankreich/cote-d-azur/nice',
                  },
                  {
                    name: 'Toulon',
                    href: '/yachtcharter/frankreich/cote-d-azur/toulon',
                  },
                  {
                    name: 'Marseille',
                    href: '/yachtcharter/frankreich/cote-d-azur/marseille',
                  },
                  {
                    name: 'Grimaud',
                    href: '/yachtcharter/frankreich/cote-d-azur/grimaud',
                  },
                  {
                    name: 'Vallauris',
                    href: '/yachtcharter/frankreich/cote-d-azur/vallauris',
                  },
                  {
                    name: 'Saint-Rapha\u00ebl',
                    href: '/yachtcharter/frankreich/cote-d-azur/saint-raphael',
                  },
                ],
              },
              {
                name: 'Camargue',
                href: '/yachtcharter/frankreich/camargue',
                subList: [
                  {
                    name: 'Lattes',
                    href: '/yachtcharter/frankreich/camargue/lattes',
                  },
                  {
                    name: 'Bellegarde',
                    href: '/yachtcharter/frankreich/camargue/bellegarde',
                  },
                  {
                    name: 'Agde',
                    href: '/yachtcharter/frankreich/camargue/agde',
                  },
                  {
                    name: 'Carnon',
                    href: '/yachtcharter/frankreich/camargue/carnon',
                  },
                  {
                    name: 'Portiranges',
                    href: '/yachtcharter/frankreich/camargue/portiranges',
                  },
                  {
                    name: 'St. Gilles',
                    href: '/yachtcharter/frankreich/camargue/st-gilles',
                  },
                ],
              },
              {
                name: 'Charente',
                href: '/yachtcharter/frankreich/charente',
                subList: [
                  {
                    name: 'Sireuil',
                    href: '/yachtcharter/frankreich/charente/sireuil',
                  },
                  {
                    name: 'Jarnac',
                    href: '/yachtcharter/frankreich/charente/jarnac',
                  },
                  {
                    name: 'La Rochelle',
                    href: '/yachtcharter/frankreich/charente/la-rochelle',
                  },
                  {
                    name: 'Cognac',
                    href: '/yachtcharter/frankreich/charente/cognac',
                  },
                ],
              },
              {
                name: 'Burgund Nivernais & Loire',
                href: '/yachtcharter/frankreich/burgund-nivernais-loire',
                subList: [
                  {
                    name: 'Joigny',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/joigny',
                  },
                  {
                    name: 'Corbigny',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/corbigny',
                  },
                  {
                    name: 'Dompierre-sur-Besbre',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/dompierre-sur-besbre',
                  },
                  {
                    name: 'Briare',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/briare',
                  },
                  {
                    name: 'Saint-L\u00e9ger-sur-Dheune',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/saint-leger-sur-dheune',
                  },
                  {
                    name: 'Chassey-l\u00e8s-Scey',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/chassey-les-scey',
                  },
                  {
                    name: 'Sermoise-Sur-Loire',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/sermoise-sur-loire',
                  },
                  {
                    name: 'Brienon',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/brienon',
                  },
                  {
                    name: 'Venarey-les-Laumes',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/venarey-les-laumes',
                  },
                  {
                    name: 'Dole',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/dole',
                  },
                  {
                    name: 'Port-sur-Saone',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/port-sur-saone',
                  },
                  {
                    name: 'Decize',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/decize',
                  },
                  {
                    name: 'Migennes',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/migennes',
                  },
                  {
                    name: 'Branges',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/branges',
                  },
                  {
                    name: 'Fontenoy-le-Ch\u00e2teau',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/fontenoy-le-chateau',
                  },
                  {
                    name: 'Auxerre',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/auxerre',
                  },
                  {
                    name: 'Saint-Jean-de-Losne',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/saint-jean-de-losne',
                  },
                  {
                    name: 'Ch\u00e2tillon-sur-Loire',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/chatillon-sur-loire',
                  },
                  {
                    name: 'Nevers - Plagny',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/nevers-plagny',
                  },
                  {
                    name: 'La Rochelle',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/la-rochelle',
                  },
                  {
                    name: 'Vermenton',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/vermenton',
                  },
                  {
                    name: 'Digoin',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/digoin',
                  },
                  {
                    name: 'Chatillon-En-Bazois',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/chatillon-en-bazois',
                  },
                  {
                    name: 'Louhans',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/louhans',
                  },
                  {
                    name: 'Mailly Le Chateau',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/mailly-le-chateau',
                  },
                  {
                    name: 'Pontailler sur Sa\u00f4ne',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/pontailler-sur-saone',
                  },
                  {
                    name: 'Tannay',
                    href: '/yachtcharter/frankreich/burgund-nivernais-loire/tannay',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Niederlande',
        icon: nlFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Niederlande',
            href: '/yachtcharter/niederlande',
            list: [
              {
                name: 'Nordholland',
                href: '/yachtcharter/niederlande/nordholland',
                subList: [
                  {
                    name: 'Loosdrecht',
                    href: '/yachtcharter/niederlande/nordholland/loosdrecht',
                  },
                  {
                    name: 'Vinkeveen',
                    href: '/yachtcharter/niederlande/nordholland/vinkeveen',
                  },
                ],
              },
              {
                name: 'Friesland',
                href: '/yachtcharter/niederlande/friesland',
                subList: [
                  {
                    name: 'Drachten',
                    href: '/yachtcharter/niederlande/friesland/drachten',
                  },
                  {
                    name: 'Lemmer',
                    href: '/yachtcharter/niederlande/friesland/lemmer',
                  },
                  {
                    name: 'Hindeloopen',
                    href: '/yachtcharter/niederlande/friesland/hindeloopen',
                  },
                  {
                    name: 'Woudsend',
                    href: '/yachtcharter/niederlande/friesland/woudsend',
                  },
                ],
              },
              {
                name: 'S\u00fcdholland',
                href: '/yachtcharter/niederlande/suedholland',
                subList: [
                  {
                    name: 'Alphen aan den Rijn',
                    href: '/yachtcharter/niederlande/suedholland/alphen-aan-den-rijn',
                  },
                ],
              },
              {
                name: 'Utrecht',
                href: '/yachtcharter/niederlande/utrecht',
                subList: [
                  {
                    name: 'Loosdrecht',
                    href: '/yachtcharter/niederlande/utrecht/loosdrecht',
                  },
                  {
                    name: 'Montfoort',
                    href: '/yachtcharter/niederlande/utrecht/montfoort',
                  },
                  {
                    name: 'Vinkeveen',
                    href: '/yachtcharter/niederlande/utrecht/vinkeveen',
                  },
                ],
              },
              {
                name: 'Seeland',
                href: '/yachtcharter/niederlande/seeland',
                subList: [
                  {
                    name: 'Kortgene',
                    href: '/yachtcharter/niederlande/seeland/kortgene',
                  },
                  {
                    name: 'Yerseke',
                    href: '/yachtcharter/niederlande/seeland/yerseke',
                  },
                  {
                    name: 'Breskens',
                    href: '/yachtcharter/niederlande/seeland/breskens',
                  },
                ],
              },
              {
                name: 'Ijsselmeer',
                href: '/yachtcharter/niederlande/ijsselmeer',
                subList: [
                  {
                    name: 'Lemmer',
                    href: '/yachtcharter/niederlande/ijsselmeer/lemmer',
                  },
                  {
                    name: 'Lelystad',
                    href: '/yachtcharter/niederlande/ijsselmeer/lelystad',
                  },
                  {
                    name: 'Hindeloopen',
                    href: '/yachtcharter/niederlande/ijsselmeer/hindeloopen',
                  },
                  {
                    name: 'Woudsend',
                    href: '/yachtcharter/niederlande/ijsselmeer/woudsend',
                  },
                ],
              },
              {
                name: 'Zeeland',
                href: '/yachtcharter/niederlande/zeeland',
                subList: [
                  {
                    name: 'Kortgene',
                    href: '/yachtcharter/niederlande/zeeland/kortgene',
                  },
                  {
                    name: 'Yerseke',
                    href: '/yachtcharter/niederlande/zeeland/yerseke',
                  },
                  {
                    name: 'Breskens',
                    href: '/yachtcharter/niederlande/zeeland/breskens',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Polen',
        icon: plFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Polen',
            href: '/yachtcharter/polen',
            list: [
              {
                name: 'Oder & Stettiner Haff',
                href: '/yachtcharter/polen/oder-stettiner-haff',
                subList: [
                  {
                    name: 'Kamie\u0144 Pomorski',
                    href: '/yachtcharter/polen/oder-stettiner-haff/kamien-pomorski',
                  },
                  {
                    name: 'Trzebie\u017c',
                    href: '/yachtcharter/polen/oder-stettiner-haff/trzebiez',
                  },
                  {
                    name: 'Eggesin',
                    href: '/yachtcharter/polen/oder-stettiner-haff/eggesin',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Portugal',
        icon: ptFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Portugal',
            href: '/yachtcharter/portugal',
            list: [
              {
                name: 'Alentejo - Grande Lago',
                href: '/yachtcharter/portugal/alentejo-grande-lago',
                subList: [
                  {
                    name: 'Amieira',
                    href: '/yachtcharter/portugal/alentejo-grande-lago/amieira',
                  },
                ],
              },
              {
                name: 'Algarve',
                href: '/yachtcharter/portugal/algarve',
                subList: [
                  {
                    name: 'Portimao',
                    href: '/yachtcharter/portugal/algarve/portimao',
                  },
                  {
                    name: 'Ayamonte',
                    href: '/yachtcharter/portugal/algarve/ayamonte',
                  },
                ],
              },
              {
                name: 'Lissabon',
                href: '/yachtcharter/portugal/lissabon',
                subList: [
                  {
                    name: 'Lissabon',
                    href: '/yachtcharter/portugal/lissabon/lissabon',
                  },
                  {
                    name: 'Cascais',
                    href: '/yachtcharter/portugal/lissabon/cascais',
                  },
                ],
              },
              {
                name: 'Nordportugal',
                href: '/yachtcharter/portugal/nordportugal',
                subList: [
                  {
                    name: 'Freixo',
                    href: '/yachtcharter/portugal/nordportugal/freixo',
                  },
                  {
                    name: 'Vila Nova Gaia',
                    href: '/yachtcharter/portugal/nordportugal/vila-nova-gaia',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Spanien',
        icon: esFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Spanien',
            href: '/yachtcharter/spanien',
            list: [
              {
                name: 'Balearische Inseln',
                href: '/yachtcharter/spanien/balearische-inseln',
                subList: [
                  {
                    name: 'Portocolom',
                    href: '/yachtcharter/spanien/balearische-inseln/portocolom',
                  },
                  {
                    name: 'Llucmajor',
                    href: '/yachtcharter/spanien/balearische-inseln/llucmajor',
                  },
                  {
                    name: "Cala D'or",
                    href: '/yachtcharter/spanien/balearische-inseln/cala-dor',
                  },
                  {
                    name: 'Palma de Mallorca',
                    href: '/yachtcharter/spanien/balearische-inseln/palma-de-mallorca',
                  },
                  {
                    name: 'Ibiza',
                    href: '/yachtcharter/spanien/balearische-inseln/ibiza',
                  },
                  {
                    name: 'Alcudia',
                    href: '/yachtcharter/spanien/balearische-inseln/alcudia',
                  },
                  {
                    name: 'Port de Pollen\u00e7a',
                    href: '/yachtcharter/spanien/balearische-inseln/port-de-pollenca',
                  },
                  {
                    name: 'Can Pastilla',
                    href: '/yachtcharter/spanien/balearische-inseln/can-pastilla',
                  },
                  {
                    name: 'Santa Pon\u00e7a',
                    href: '/yachtcharter/spanien/balearische-inseln/santa-ponca',
                  },
                  {
                    name: 'Mahon - Menorca',
                    href: '/yachtcharter/spanien/balearische-inseln/mahon-menorca',
                  },
                  {
                    name: 'Santany\u00ed',
                    href: '/yachtcharter/spanien/balearische-inseln/santanyi',
                  },
                  {
                    name: 'Eivissa',
                    href: '/yachtcharter/spanien/balearische-inseln/eivissa',
                  },
                  {
                    name: 'Ma\u00f3',
                    href: '/yachtcharter/spanien/balearische-inseln/mao',
                  },
                  {
                    name: 'Pollen\u00e7a',
                    href: '/yachtcharter/spanien/balearische-inseln/pollenca',
                  },
                  {
                    name: 'Portals Nous-Calvi\u00e0',
                    href: '/yachtcharter/spanien/balearische-inseln/portals-nous-calvia',
                  },
                  {
                    name: 'Sant Antoni',
                    href: '/yachtcharter/spanien/balearische-inseln/sant-antoni',
                  },
                  {
                    name: 'Palma',
                    href: '/yachtcharter/spanien/balearische-inseln/palma',
                  },
                  {
                    name: 'San Antonio Abad',
                    href: '/yachtcharter/spanien/balearische-inseln/san-antonio-abad',
                  },
                ],
              },
              {
                name: 'Mallorca',
                href: '/yachtcharter/spanien/mallorca',
                subList: [
                  {
                    name: 'Portocolom',
                    href: '/yachtcharter/spanien/mallorca/portocolom',
                  },
                  {
                    name: 'Llucmajor',
                    href: '/yachtcharter/spanien/mallorca/llucmajor',
                  },
                  {
                    name: "Cala D'or",
                    href: '/yachtcharter/spanien/mallorca/cala-dor',
                  },
                  {
                    name: 'Palma de Mallorca',
                    href: '/yachtcharter/spanien/mallorca/palma-de-mallorca',
                  },
                  {
                    name: 'Alcudia',
                    href: '/yachtcharter/spanien/mallorca/alcudia',
                  },
                  {
                    name: 'Port de Pollen\u00e7a',
                    href: '/yachtcharter/spanien/mallorca/port-de-pollenca',
                  },
                  {
                    name: 'Can Pastilla',
                    href: '/yachtcharter/spanien/mallorca/can-pastilla',
                  },
                  {
                    name: 'Santa Pon\u00e7a',
                    href: '/yachtcharter/spanien/mallorca/santa-ponca',
                  },
                  {
                    name: 'Santany\u00ed',
                    href: '/yachtcharter/spanien/mallorca/santanyi',
                  },
                  {
                    name: 'Pollen\u00e7a',
                    href: '/yachtcharter/spanien/mallorca/pollenca',
                  },
                  {
                    name: 'Portals Nous-Calvi\u00e0',
                    href: '/yachtcharter/spanien/mallorca/portals-nous-calvia',
                  },
                  {
                    name: 'Palma',
                    href: '/yachtcharter/spanien/mallorca/palma',
                  },
                ],
              },
              {
                name: 'Ibiza',
                href: '/yachtcharter/spanien/ibiza',
                subList: [
                  {
                    name: 'Ibiza',
                    href: '/yachtcharter/spanien/ibiza/ibiza',
                  },
                  {
                    name: 'Eivissa',
                    href: '/yachtcharter/spanien/ibiza/eivissa',
                  },
                  {
                    name: 'Sant Antoni',
                    href: '/yachtcharter/spanien/ibiza/sant-antoni',
                  },
                  {
                    name: 'San Antonio Abad',
                    href: '/yachtcharter/spanien/ibiza/san-antonio-abad',
                  },
                ],
              },
              {
                name: 'Menorca',
                href: '/yachtcharter/spanien/menorca',
                subList: [
                  {
                    name: 'Mahon - Menorca',
                    href: '/yachtcharter/spanien/menorca/mahon-menorca',
                  },
                  {
                    name: 'Ma\u00f3',
                    href: '/yachtcharter/spanien/menorca/mao',
                  },
                ],
              },
              {
                name: 'Kanarische Inseln',
                href: '/yachtcharter/spanien/kanarische-inseln',
                subList: [
                  {
                    name: 'Arrecife',
                    href: '/yachtcharter/spanien/kanarische-inseln/arrecife',
                  },
                  {
                    name: 'Lanzarote',
                    href: '/yachtcharter/spanien/kanarische-inseln/lanzarote',
                  },
                  {
                    name: 'Santa Cruz de Tenerife',
                    href: '/yachtcharter/spanien/kanarische-inseln/santa-cruz-de-tenerife',
                  },
                  {
                    name: 'Las Galletas',
                    href: '/yachtcharter/spanien/kanarische-inseln/las-galletas',
                  },
                  {
                    name: 'Arona',
                    href: '/yachtcharter/spanien/kanarische-inseln/arona',
                  },
                  {
                    name: 'San Bartolom\u00e9 de Tirajana',
                    href: '/yachtcharter/spanien/kanarische-inseln/san-bartolome-de-tirajana',
                  },
                  {
                    name: 'Lomo Quiebre',
                    href: '/yachtcharter/spanien/kanarische-inseln/lomo-quiebre',
                  },
                  {
                    name: 'Amarilla Golf',
                    href: '/yachtcharter/spanien/kanarische-inseln/amarilla-golf',
                  },
                  {
                    name: 'Gran Canaria',
                    href: '/yachtcharter/spanien/kanarische-inseln/gran-canaria',
                  },
                ],
              },
              {
                name: 'Gran Canaria',
                href: '/yachtcharter/spanien/gran-canaria',
                subList: [
                  {
                    name: 'San Bartolom\u00e9 de Tirajana',
                    href: '/yachtcharter/spanien/gran-canaria/san-bartolome-de-tirajana',
                  },
                  {
                    name: 'Lomo Quiebre',
                    href: '/yachtcharter/spanien/gran-canaria/lomo-quiebre',
                  },
                  {
                    name: 'Gran Canaria',
                    href: '/yachtcharter/spanien/gran-canaria/gran-canaria',
                  },
                ],
              },
              {
                name: 'Lanzarote',
                href: '/yachtcharter/spanien/lanzarote',
                subList: [
                  {
                    name: 'Arrecife',
                    href: '/yachtcharter/spanien/lanzarote/arrecife',
                  },
                  {
                    name: 'Lanzarote',
                    href: '/yachtcharter/spanien/lanzarote/lanzarote',
                  },
                ],
              },
              {
                name: 'Katalonien',
                href: '/yachtcharter/spanien/katalonien',
                subList: [
                  {
                    name: 'Barcelona',
                    href: '/yachtcharter/spanien/katalonien/barcelona',
                  },
                  {
                    name: 'Roses',
                    href: '/yachtcharter/spanien/katalonien/roses',
                  },
                  {
                    name: 'Girona',
                    href: '/yachtcharter/spanien/katalonien/girona',
                  },
                  {
                    name: 'El Masnou',
                    href: '/yachtcharter/spanien/katalonien/el-masnou',
                  },
                  {
                    name: 'Palamos',
                    href: '/yachtcharter/spanien/katalonien/palamos',
                  },
                  {
                    name: 'Mataro',
                    href: '/yachtcharter/spanien/katalonien/mataro',
                  },
                  {
                    name: 'Cambrils',
                    href: '/yachtcharter/spanien/katalonien/cambrils',
                  },
                  {
                    name: 'Premi\u00e0 de Mar, Barcelona',
                    href: '/yachtcharter/spanien/katalonien/premia-de-mar-barcelona',
                  },
                  {
                    name: 'Roda de Bar\u00e0',
                    href: '/yachtcharter/spanien/katalonien/roda-de-bara',
                  },
                  {
                    name: 'Sitges',
                    href: '/yachtcharter/spanien/katalonien/sitges',
                  },
                  {
                    name: 'Sitges, Barcelona',
                    href: '/yachtcharter/spanien/katalonien/sitges-barcelona',
                  },
                  {
                    name: 'Torredembarra',
                    href: '/yachtcharter/spanien/katalonien/torredembarra',
                  },
                  {
                    name: 'Roda de Ber\u00e0',
                    href: '/yachtcharter/spanien/katalonien/roda-de-bera',
                  },
                ],
              },
              {
                name: 'Valencia',
                href: '/yachtcharter/spanien/valencia',
                subList: [
                  {
                    name: 'Denia',
                    href: '/yachtcharter/spanien/valencia/denia',
                  },
                  {
                    name: 'Valencia',
                    href: '/yachtcharter/spanien/valencia/valencia',
                  },
                  {
                    name: 'La Vila Joiosa',
                    href: '/yachtcharter/spanien/valencia/la-vila-joiosa',
                  },
                ],
              },
              {
                name: 'Andalusien',
                href: '/yachtcharter/spanien/andalusien',
                subList: [
                  {
                    name: 'Ayamonte',
                    href: '/yachtcharter/spanien/andalusien/ayamonte',
                  },
                ],
              },
              {
                name: 'Galicien',
                href: '/yachtcharter/spanien/galicien',
                subList: [
                  {
                    name: 'Pontevedra',
                    href: '/yachtcharter/spanien/galicien/pontevedra',
                  },
                ],
              },
              {
                name: 'Teneriffa',
                href: '/yachtcharter/spanien/teneriffa',
                subList: [
                  {
                    name: 'Santa Cruz de Tenerife',
                    href: '/yachtcharter/spanien/teneriffa/santa-cruz-de-tenerife',
                  },
                  {
                    name: 'Las Galletas',
                    href: '/yachtcharter/spanien/teneriffa/las-galletas',
                  },
                  {
                    name: 'Arona',
                    href: '/yachtcharter/spanien/teneriffa/arona',
                  },
                  {
                    name: 'Amarilla Golf',
                    href: '/yachtcharter/spanien/teneriffa/amarilla-golf',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Belgien',
        icon: beFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Belgien',
            href: '/yachtcharter/belgien',
            list: [
              {
                name: 'Fl\u00e4mische Region',
                href: '/yachtcharter/belgien/flaemische-region',
                subList: [
                  {
                    name: 'Zeebrugge',
                    href: '/yachtcharter/belgien/flaemische-region/zeebrugge',
                  },
                  {
                    name: 'Kinrooi',
                    href: '/yachtcharter/belgien/flaemische-region/kinrooi',
                  },
                  {
                    name: 'Nieuwpoort',
                    href: '/yachtcharter/belgien/flaemische-region/nieuwpoort',
                  },
                  {
                    name: 'Brugge',
                    href: '/yachtcharter/belgien/flaemische-region/brugge',
                  },
                ],
              },
              {
                name: 'Flandern',
                href: '/yachtcharter/belgien/flandern',
                subList: [
                  {
                    name: 'Nieuwpoort',
                    href: '/yachtcharter/belgien/flandern/nieuwpoort',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'D\u00e4nemark',
        icon: dkFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'D\u00e4nemark',
            href: '/yachtcharter/daenemark',
            list: [
              {
                name: 'Kopenhagen',
                href: '/yachtcharter/daenemark/kopenhagen',
                subList: [
                  {
                    name: 'Ish\u00f8j',
                    href: '/yachtcharter/daenemark/kopenhagen/ishoj',
                  },
                ],
              },
              {
                name: 'S\u00fcd-D\u00e4nemark',
                href: '/yachtcharter/daenemark/sued-daenemark',
                subList: [
                  {
                    name: 'Kolding',
                    href: '/yachtcharter/daenemark/sued-daenemark/kolding',
                  },
                ],
              },
              {
                name: 'Sj\u00e6lland (Zeeland)',
                href: '/yachtcharter/daenemark/sjaelland-zeeland',
                subList: [
                  {
                    name: 'Ish\u00f8j',
                    href: '/yachtcharter/daenemark/sjaelland-zeeland/ishoj',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Norwegen',
        icon: noFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Norwegen',
            href: '/yachtcharter/norwegen',
            list: [
            ],
          },
        ],
      },
      {
        name: 'Slowenien',
        icon: slFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Slowenien',
            href: '/yachtcharter/slowenien',
            list: [
            ],
          },
        ],
      },
      {
        name: 'T\u00fcrkei',
        icon: trFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'T\u00fcrkei',
            href: '/yachtcharter/tuerkei',
            list: [
              {
                name: 'Mittelmeerregion',
                href: '/yachtcharter/tuerkei/mittelmeerregion',
                subList: [
                  {
                    name: 'G\u00f6cek',
                    href: '/yachtcharter/tuerkei/mittelmeerregion/goecek',
                  },
                  {
                    name: 'Fethiye',
                    href: '/yachtcharter/tuerkei/mittelmeerregion/fethiye',
                  },
                  {
                    name: 'Ece Saray Marina Resort, Fethiye',
                    href: '/yachtcharter/tuerkei/mittelmeerregion/ece-saray-marina-resort-fethiye',
                  },
                  {
                    name: 'G\u00f6cek/D-Marin',
                    href: '/yachtcharter/tuerkei/mittelmeerregion/goecek-d-marin',
                  },
                  {
                    name: 'Marin Turk G\u00f6cek Village Port',
                    href: '/yachtcharter/tuerkei/mittelmeerregion/marin-turk-goecek-village-port',
                  },
                  {
                    name: 'Kas Marina',
                    href: '/yachtcharter/tuerkei/mittelmeerregion/kas-marina',
                  },
                  {
                    name: 'Yacht Classic Hotel Marina, Fethiye',
                    href: '/yachtcharter/tuerkei/mittelmeerregion/yacht-classic-hotel-marina-fethiye',
                  },
                  {
                    name: 'Yes Marina',
                    href: '/yachtcharter/tuerkei/mittelmeerregion/yes-marina',
                  },
                  {
                    name: 'G\u00f6cek/Skopea Marina',
                    href: '/yachtcharter/tuerkei/mittelmeerregion/goecek-skopea-marina',
                  },
                ],
              },
              {
                name: '\u00c4g\u00e4is',
                href: '/yachtcharter/tuerkei/aegaeis',
                subList: [
                  {
                    name: 'Kos',
                    href: '/yachtcharter/tuerkei/aegaeis/kos',
                  },
                  {
                    name: 'Orhaniye',
                    href: '/yachtcharter/tuerkei/aegaeis/orhaniye',
                  },
                  {
                    name: 'Marmaris',
                    href: '/yachtcharter/tuerkei/aegaeis/marmaris',
                  },
                  {
                    name: 'Bodrum',
                    href: '/yachtcharter/tuerkei/aegaeis/bodrum',
                  },
                  {
                    name: 'Marmaris, Netsel Marina',
                    href: '/yachtcharter/tuerkei/aegaeis/marmaris-netsel-marina',
                  },
                  {
                    name: 'Bodrum/Turgutreis/D-Marin',
                    href: '/yachtcharter/tuerkei/aegaeis/bodrum-turgutreis-d-marin',
                  },
                  {
                    name: 'Milta Bodrum Marina',
                    href: '/yachtcharter/tuerkei/aegaeis/milta-bodrum-marina',
                  },
                  {
                    name: 'Marmaris Yacht Marina',
                    href: '/yachtcharter/tuerkei/aegaeis/marmaris-yacht-marina',
                  },
                  {
                    name: 'G\u00f6kova \u00d6ren Marina',
                    href: '/yachtcharter/tuerkei/aegaeis/goekova-oeren-marina',
                  },
                  {
                    name: 'Marmaris Adakoy Marina',
                    href: '/yachtcharter/tuerkei/aegaeis/marmaris-adakoy-marina',
                  },
                  {
                    name: 'Palmarina - Yalikavak, Bodrum',
                    href: '/yachtcharter/tuerkei/aegaeis/palmarina-yalikavak-bodrum',
                  },
                  {
                    name: 'Bodrum, G\u00fcmbet Marina',
                    href: '/yachtcharter/tuerkei/aegaeis/bodrum-guembet-marina',
                  },
                  {
                    name: 'Marmaris, Joya Del Mar Marina',
                    href: '/yachtcharter/tuerkei/aegaeis/marmaris-joya-del-mar-marina',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Schweden',
        icon: seFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Schweden',
            href: '/yachtcharter/schweden',
            list: [
              {
                name: 'V\u00e4stra G\u00f6talands l\u00e4n',
                href: '/yachtcharter/schweden/vaestra-goetalands-laen',
                subList: [
                  {
                    name: 'G\u00f6teborg',
                    href: '/yachtcharter/schweden/vaestra-goetalands-laen/goeteborg',
                  },
                  {
                    name: 'Marstrand',
                    href: '/yachtcharter/schweden/vaestra-goetalands-laen/marstrand',
                  },
                  {
                    name: 'Wallhamn',
                    href: '/yachtcharter/schweden/vaestra-goetalands-laen/wallhamn',
                  },
                  {
                    name: 'Gothenburg',
                    href: '/yachtcharter/schweden/vaestra-goetalands-laen/gothenburg',
                  },
                ],
              },
              {
                name: 'Stockholm',
                href: '/yachtcharter/schweden/stockholm',
                subList: [
                  {
                    name: 'Stockholm',
                    href: '/yachtcharter/schweden/stockholm/stockholm',
                  },
                  {
                    name: 'Liding\u00f6-G\u0227shaga',
                    href: '/yachtcharter/schweden/stockholm/lidingoe-gashaga',
                  },
                  {
                    name: 'Sune Carlsson Boatyard',
                    href: '/yachtcharter/schweden/stockholm/sune-carlsson-boatyard',
                  },
                  {
                    name: 'Bulland\u00f6 Marina',
                    href: '/yachtcharter/schweden/stockholm/bullandoe-marina',
                  },
                  {
                    name: 'Stockholm, Saltsj\u00f6 Pir Marina',
                    href: '/yachtcharter/schweden/stockholm/stockholm-saltsjoe-pir-marina',
                  },
                  {
                    name: 'V\u00e4rmd\u00f6',
                    href: '/yachtcharter/schweden/stockholm/vaermdoe',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Russland',
        icon: ruFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Russland',
            href: '/yachtcharter/russland',
            list: [
              {
                name: 'Moskau',
                href: '/yachtcharter/russland/moskau',
                subList: [
                  {
                    name: 'Novoaleksandrovo',
                    href: '/yachtcharter/russland/moskau/novoaleksandrovo',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Malediven',
        icon: mvFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Malediven',
            href: '/yachtcharter/malediven',
            list: [
              {
                name: 'Male',
                href: '/yachtcharter/malediven/male',
                subList: [
                  {
                    name: 'Mal\u00e9',
                    href: '/yachtcharter/malediven/male/male',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Rum\u00e4nien',
        icon: roFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Rum\u00e4nien',
            href: '/yachtcharter/rumaenien',
            list: [
              {
                name: 'Dobrudscha',
                href: '/yachtcharter/rumaenien/dobrudscha',
                subList: [
                  {
                    name: 'Limanu, Judetul Constanta',
                    href: '/yachtcharter/rumaenien/dobrudscha/limanu-judetul-constanta',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Vereinigtes K\u00f6nigreich',
        icon: gbFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Vereinigtes K\u00f6nigreich',
            href: '/yachtcharter/vereinigtes-koenigreich',
            list: [
              {
                name: 'England',
                href: '/yachtcharter/vereinigtes-koenigreich/england',
                subList: [
                  {
                    name: 'Hamble-le-Rice',
                    href: '/yachtcharter/vereinigtes-koenigreich/england/hamble-le-rice',
                  },
                  {
                    name: 'Staffordshire',
                    href: '/yachtcharter/vereinigtes-koenigreich/england/staffordshire',
                  },
                  {
                    name: 'Chertsey',
                    href: '/yachtcharter/vereinigtes-koenigreich/england/chertsey',
                  },
                  {
                    name: 'Benson',
                    href: '/yachtcharter/vereinigtes-koenigreich/england/benson',
                  },
                  {
                    name: 'Poole',
                    href: '/yachtcharter/vereinigtes-koenigreich/england/poole',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Island',
        icon: isFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Island',
            href: '/yachtcharter/island',
            list: [
              {
                name: 'Su\u00f0urnes',
                href: '/yachtcharter/island/sudurnes',
                subList: [
                  {
                    name: 'Reykjavik',
                    href: '/yachtcharter/island/sudurnes/reykjavik',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Mexiko',
        icon: mxFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Mexiko',
            href: '/yachtcharter/mexiko',
            list: [
              {
                name: 'Baja California Sur',
                href: '/yachtcharter/mexiko/baja-california-sur',
                subList: [
                  {
                    name: 'La Paz, Costa Baja Marina',
                    href: '/yachtcharter/mexiko/baja-california-sur/la-paz-costa-baja-marina',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Franz\u00f6sisch-Polynesien',
        icon: frFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Franz\u00f6sisch-Polynesien',
            href: '/yachtcharter/franzoesisch-polynesien',
            list: [
              {
                name: 'Tahiti',
                href: '/yachtcharter/franzoesisch-polynesien/tahiti',
                subList: [
                  {
                    name: "Puna'auia",
                    href: '/yachtcharter/franzoesisch-polynesien/tahiti/punaauia',
                  },
                  {
                    name: 'Moorea',
                    href: '/yachtcharter/franzoesisch-polynesien/tahiti/moorea',
                  },
                ],
              },
              {
                name: 'Bora Bora',
                href: '/yachtcharter/franzoesisch-polynesien/bora-bora',
                subList: [
                  {
                    name: 'Bora-Bora',
                    href: '/yachtcharter/franzoesisch-polynesien/bora-bora/bora-bora',
                  },
                ],
              },
              {
                name: 'Raiatea',
                href: '/yachtcharter/franzoesisch-polynesien/raiatea',
                subList: [
                  {
                    name: 'Raiatea',
                    href: '/yachtcharter/franzoesisch-polynesien/raiatea/raiatea',
                  },
                  {
                    name: 'Uturoa',
                    href: '/yachtcharter/franzoesisch-polynesien/raiatea/uturoa',
                  },
                ],
              },
              {
                name: 'Rangiroa',
                href: '/yachtcharter/franzoesisch-polynesien/rangiroa',
                subList: [
                  {
                    name: 'Rangiroa',
                    href: '/yachtcharter/franzoesisch-polynesien/rangiroa/rangiroa',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Mauritius',
        icon: muFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Mauritius',
            href: '/yachtcharter/mauritius',
            list: [
            ],
          },
        ],
      },
      {
        name: 'Azoren',
        icon: ptFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Azoren',
            href: '/yachtcharter/azoren',
            list: [
              {
                name: 'Faial',
                href: '/yachtcharter/azoren/faial',
                subList: [
                  {
                    name: 'Horta',
                    href: '/yachtcharter/azoren/faial/horta',
                  },
                ],
              },
              {
                name: 'Terceira',
                href: '/yachtcharter/azoren/terceira',
                subList: [
                  {
                    name: 'Angra do Heriosmo',
                    href: '/yachtcharter/azoren/terceira/angra-do-heriosmo',
                  },
                ],
              },
              {
                name: 'Sao Miguel',
                href: '/yachtcharter/azoren/sao-miguel',
                subList: [
                  {
                    name: 'Ponta Delgada - Azores',
                    href: '/yachtcharter/azoren/sao-miguel/ponta-delgada-azores',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Estland',
        icon: eeFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Estland',
            href: '/yachtcharter/estland',
            list: [
            ],
          },
        ],
      },
      {
        name: 'Kanada',
        icon: caFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Kanada',
            href: '/yachtcharter/kanada',
            list: [
              {
                name: 'Smith Falls',
                href: '/yachtcharter/kanada/smith-falls',
                subList: [
                  {
                    name: 'Ontario',
                    href: '/yachtcharter/kanada/smith-falls/ontario',
                  },
                ],
              },
              {
                name: 'Rideau Kanal',
                href: '/yachtcharter/kanada/rideau-kanal',
                subList: [
                  {
                    name: 'Ontario',
                    href: '/yachtcharter/kanada/rideau-kanal/ontario',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Montenegro',
        icon: meFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Montenegro',
            href: '/yachtcharter/montenegro',
            list: [
            ],
          },
        ],
      },
      {
        name: 'Zypern',
        icon: cyFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Zypern',
            href: '/yachtcharter/zypern',
            list: [
              {
                name: 'Limassol',
                href: '/yachtcharter/zypern/limassol',
                subList: [
                  {
                    name: 'Limassol',
                    href: '/yachtcharter/zypern/limassol/limassol',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Brasilien',
        icon: brFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Brasilien',
            href: '/yachtcharter/brasilien',
            list: [
              {
                name: 'Rio de Janero',
                href: '/yachtcharter/brasilien/rio-de-janero',
                subList: [
                  {
                    name: 'Verolme',
                    href: '/yachtcharter/brasilien/rio-de-janero/verolme',
                  },
                  {
                    name: 'Jacuecanga, Angra dos Reis (RJ)',
                    href: '/yachtcharter/brasilien/rio-de-janero/jacuecanga-angra-dos-reis-rj',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Panama',
        icon: paFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Panama',
            href: '/yachtcharter/panama',
            list: [
            ],
          },
        ],
      },
      {
        name: 'Balearische Inseln',
        icon: esFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Balearische Inseln',
            href: '/yachtcharter/balearische-inseln',
            list: [
              {
                name: 'Mallorca',
                href: '/yachtcharter/balearische-inseln/mallorca',
                // subList: [
                //  {
                //    name: 'Portocolom',
                //    href: '/yachtcharter/balearische-inseln/mallorca/portocolom',
                //  },
                //  {
                //    name: 'Llucmajor',
                //    href: '/yachtcharter/balearische-inseln/mallorca/llucmajor',
                //  },
                //  {
                //    name: "Cala D'or",
                //    href: '/yachtcharter/balearische-inseln/mallorca/cala-dor',
                // },
                // {
                //  name: 'Palma de Mallorca',
                //  href: '/yachtcharter/balearische-inseln/mallorca/palma-de-mallorca',
                // },
                // {
                //  name: 'Alcudia',
                //   href: '/yachtcharter/balearische-inseln/mallorca/alcudia',
                // },
                // {
                //   name: 'Port de Pollen\u00e7a',
                //   href: '/yachtcharter/balearische-inseln/mallorca/port-de-pollenca',
                // },
                // {
                //  name: 'Can Pastilla',
                //  href: '/yachtcharter/balearische-inseln/mallorca/can-pastilla',
                // },
                // {
                //  name: 'Santa Pon\u00e7a',
                //  href: '/yachtcharter/balearische-inseln/mallorca/santa-ponca',
                // },
                // {
                //  name: 'Santany\u00ed',
                //  href: '/yachtcharter/balearische-inseln/mallorca/santanyi',
                // },
                // {
                //  name: 'Pollen\u00e7a',
                //  href: '/yachtcharter/balearische-inseln/mallorca/pollenca',
                // },
                // {
                // name: 'Portals Nous-Calvi\u00e0',
                // href: '/yachtcharter/balearische-inseln/mallorca/portals-nous-calvia',
                // },
                // {
                //  name: 'Palma',
                // href: '/yachtcharter/balearische-inseln/mallorca/palma',
                // },
                // ],
              },
              {
                name: 'Ibiza',
                href: '/yachtcharter/balearische-inseln/ibiza',
                subList: [
                  {
                    name: 'Ibiza',
                    href: '/yachtcharter/balearische-inseln/ibiza/ibiza',
                  },
                  {
                    name: 'Eivissa',
                    href: '/yachtcharter/balearische-inseln/ibiza/eivissa',
                  },
                  {
                    name: 'Sant Antoni',
                    href: '/yachtcharter/balearische-inseln/ibiza/sant-antoni',
                  },
                  {
                    name: 'San Antonio Abad',
                    href: '/yachtcharter/balearische-inseln/ibiza/san-antonio-abad',
                  },
                ],
              },
              {
                name: 'Menorca',
                href: '/yachtcharter/balearische-inseln/menorca',
                subList: [
                  {
                    name: 'Mahon - Menorca',
                    href: '/yachtcharter/balearische-inseln/menorca/mahon-menorca',
                  },
                  {
                    name: 'Ma\u00f3',
                    href: '/yachtcharter/balearische-inseln/menorca/mao',
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        name: 'Kontinente',
        header: true,
      },

      {
        name: 'Afrika',
        icon: GlobeIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Afrika',
            href: '/yachtcharter/afrika',
            list: [
              {
                name: 'Seychellen',
                href: '/yachtcharter/afrika/seychellen',
                subList: [
                  {
                    name: 'Baie Ste Anne',
                    href: '/yachtcharter/afrika/seychellen/baie-ste-anne',
                  },
                  {
                    name: 'Mahe, Victoria',
                    href: '/yachtcharter/afrika/seychellen/mahe-victoria',
                  },
                  {
                    name: 'Grand Anse Village',
                    href: '/yachtcharter/afrika/seychellen/grand-anse-village',
                  },
                  {
                    name: 'Mahe',
                    href: '/yachtcharter/afrika/seychellen/mahe',
                  },
                  {
                    name: 'Praslin',
                    href: '/yachtcharter/afrika/seychellen/praslin',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'S\u00fcdamerika',
        icon: GlobeIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'S\u00fcdamerika',
            href: '/yachtcharter/suedamerika',
            list: [
            ],
          },
        ],
      },
      {
        name: 'Mittelamerika',
        icon: GlobeIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Mittelamerika',
            href: '/yachtcharter/mittelamerika',
            list: [
              {
                name: 'Panama',
                href: '/yachtcharter/mittelamerika/panama',
                subList: [
                  {
                    name: 'Corazon De Jesus',
                    href: '/yachtcharter/mittelamerika/panama/corazon-de-jesus',
                  },
                  {
                    name: 'Carti',
                    href: '/yachtcharter/mittelamerika/panama/carti',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Australien',
        icon: auFlag,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Australien',
            href: '/yachtcharter/australien',
            list: [
              {
                name: 'Queensland',
                href: '/yachtcharter/australien/queensland',
                subList: [
                  {
                    name: 'Airlie Beach',
                    href: '/yachtcharter/australien/queensland/airlie-beach',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'S\u00fcdostasien',
        icon: GlobeIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'S\u00fcdostasien',
            href: '/yachtcharter/suedostasien',
            list: [
              {
                name: 'Thailand',
                href: '/yachtcharter/suedostasien/thailand',
                subList: [
                  {
                    name: 'Tambon Mai Khao',
                    href: '/yachtcharter/suedostasien/thailand/tambon-mai-khao',
                  },
                  {
                    name: 'Tambon Koh Chang Tai',
                    href: '/yachtcharter/suedostasien/thailand/tambon-koh-chang-tai',
                  },
                  {
                    name: 'Koh Chang',
                    href: '/yachtcharter/suedostasien/thailand/koh-chang',
                  },
                  {
                    name: 'Phuket',
                    href: '/yachtcharter/suedostasien/thailand/phuket',
                  },
                  {
                    name: 'Ko Samui',
                    href: '/yachtcharter/suedostasien/thailand/ko-samui',
                  },
                  {
                    name: 'Koh Samui',
                    href: '/yachtcharter/suedostasien/thailand/koh-samui',
                  },
                ],
              },
              {
                name: 'Phuket',
                href: '/yachtcharter/suedostasien/phuket',
                subList: [
                  {
                    name: 'Tambon Mai Khao',
                    href: '/yachtcharter/suedostasien/phuket/tambon-mai-khao',
                  },
                  {
                    name: 'Phuket',
                    href: '/yachtcharter/suedostasien/phuket/phuket',
                  },
                ],
              },
              {
                name: 'Ko Samui',
                href: '/yachtcharter/suedostasien/ko-samui',
                subList: [
                  {
                    name: 'Ko Samui',
                    href: '/yachtcharter/suedostasien/ko-samui/ko-samui',
                  },
                  {
                    name: 'Koh Samui',
                    href: '/yachtcharter/suedostasien/ko-samui/koh-samui',
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        name: 'Gewässer',
        header: true,
      },

      {
        name: 'Nordsee',
        icon: GlobeAltIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Nordsee',
            href: '/yachtcharter/nordsee',
            list: [
              {
                name: 'Ijsselmeer',
                href: '/yachtcharter/nordsee/ijsselmeer',
                subList: [
                  {
                    name: 'Lemmer',
                    href: '/yachtcharter/nordsee/ijsselmeer/lemmer',
                  },
                  {
                    name: 'Lelystad',
                    href: '/yachtcharter/nordsee/ijsselmeer/lelystad',
                  },
                  {
                    name: 'Hindeloopen',
                    href: '/yachtcharter/nordsee/ijsselmeer/hindeloopen',
                  },
                ],
              },
              {
                name: 'S\u00fcdholland',
                href: '/yachtcharter/nordsee/suedholland',
                subList: [
                  {
                    name: 'Alphen aan den Rijn',
                    href: '/yachtcharter/nordsee/suedholland/alphen-aan-den-rijn',
                  },
                ],
              },
              {
                name: 'Fl\u00e4mische Region',
                href: '/yachtcharter/nordsee/flaemische-region',
                subList: [
                  {
                    name: 'Zeebrugge',
                    href: '/yachtcharter/nordsee/flaemische-region/zeebrugge',
                  },
                  {
                    name: 'Kinrooi',
                    href: '/yachtcharter/nordsee/flaemische-region/kinrooi',
                  },
                  {
                    name: 'Nieuwpoort',
                    href: '/yachtcharter/nordsee/flaemische-region/nieuwpoort',
                  },
                  {
                    name: 'Brugge',
                    href: '/yachtcharter/nordsee/flaemische-region/brugge',
                  },
                ],
              },
              {
                name: 'Friesland',
                href: '/yachtcharter/nordsee/friesland',
                subList: [
                  {
                    name: 'Drachten',
                    href: '/yachtcharter/nordsee/friesland/drachten',
                  },
                  {
                    name: 'Lemmer',
                    href: '/yachtcharter/nordsee/friesland/lemmer',
                  },
                  {
                    name: 'Hindeloopen',
                    href: '/yachtcharter/nordsee/friesland/hindeloopen',
                  },
                  {
                    name: 'Woudsend',
                    href: '/yachtcharter/nordsee/friesland/woudsend',
                  },
                ],
              },
              {
                name: 'England',
                href: '/yachtcharter/nordsee/england',
                subList: [
                  {
                    name: 'Hamble-le-Rice',
                    href: '/yachtcharter/nordsee/england/hamble-le-rice',
                  },
                  {
                    name: 'Staffordshire',
                    href: '/yachtcharter/nordsee/england/staffordshire',
                  },
                  {
                    name: 'Chertsey',
                    href: '/yachtcharter/nordsee/england/chertsey',
                  },
                  {
                    name: 'Benson',
                    href: '/yachtcharter/nordsee/england/benson',
                  },
                  {
                    name: 'Poole',
                    href: '/yachtcharter/nordsee/england/poole',
                  },
                ],
              },
              {
                name: 'Island',
                href: '/yachtcharter/nordsee/island',
                subList: [
                  {
                    name: 'Reykjavik',
                    href: '/yachtcharter/nordsee/island/reykjavik',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Ostsee',
        icon: GlobeAltIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Ostsee',
            href: '/yachtcharter/ostsee',
            list: [
              {
                name: 'R\u00fcgen',
                href: '/yachtcharter/ostsee/ruegen',
                subList: [
                  {
                    name: 'Putbus',
                    href: '/yachtcharter/ostsee/ruegen/putbus',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Adria',
        icon: GlobeAltIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Adria',
            href: '/yachtcharter/adria',
            list: [
              {
                name: 'Apulien',
                href: '/yachtcharter/adria/apulien',
                subList: [
                  {
                    name: 'Bari',
                    href: '/yachtcharter/adria/apulien/bari',
                  },
                  {
                    name: 'Rodi',
                    href: '/yachtcharter/adria/apulien/rodi',
                  },
                  {
                    name: 'San Foca',
                    href: '/yachtcharter/adria/apulien/san-foca',
                  },
                  {
                    name: 'Leuca',
                    href: '/yachtcharter/adria/apulien/leuca',
                  },
                  {
                    name: 'Taranto',
                    href: '/yachtcharter/adria/apulien/taranto',
                  },
                ],
              },
              {
                name: 'Friaul-Julisch Venetien',
                href: '/yachtcharter/adria/friaul-julisch-venetien',
                subList: [
                  {
                    name: 'Izola',
                    href: '/yachtcharter/adria/friaul-julisch-venetien/izola',
                  },
                  {
                    name: 'Muggia',
                    href: '/yachtcharter/adria/friaul-julisch-venetien/muggia',
                  },
                  {
                    name: 'Lignano Sabbiadoro',
                    href: '/yachtcharter/adria/friaul-julisch-venetien/lignano-sabbiadoro',
                  },
                  {
                    name: 'Marano',
                    href: '/yachtcharter/adria/friaul-julisch-venetien/marano',
                  },
                  {
                    name: 'Marina Portoro\u017e',
                    href: '/yachtcharter/adria/friaul-julisch-venetien/marina-portoroz',
                  },
                  {
                    name: 'Precenicco',
                    href: '/yachtcharter/adria/friaul-julisch-venetien/precenicco',
                  },
                  {
                    name: 'Precenicco',
                    href: '/yachtcharter/adria/friaul-julisch-venetien/precenicco',
                  },
                ],
              },
              {
                name: 'Marken',
                href: '/yachtcharter/adria/marken',
                subList: [
                  {
                    name: 'San Benedetto del Tronto',
                    href: '/yachtcharter/adria/marken/san-benedetto-del-tronto',
                  },
                ],
              },
              {
                name: 'Istrien',
                href: '/yachtcharter/adria/istrien',
                subList: [
                  {
                    name: 'Rijeka',
                    href: '/yachtcharter/adria/istrien/rijeka',
                  },
                  {
                    name: 'Pula',
                    href: '/yachtcharter/adria/istrien/pula',
                  },
                  {
                    name: 'Pomer',
                    href: '/yachtcharter/adria/istrien/pomer',
                  },
                  {
                    name: 'I\u010di\u0107i',
                    href: '/yachtcharter/adria/istrien/icici',
                  },
                  {
                    name: 'Rabac',
                    href: '/yachtcharter/adria/istrien/rabac',
                  },
                  {
                    name: 'Medulin',
                    href: '/yachtcharter/adria/istrien/medulin',
                  },
                  {
                    name: 'Rovinj',
                    href: '/yachtcharter/adria/istrien/rovinj',
                  },
                  {
                    name: 'Vrsar',
                    href: '/yachtcharter/adria/istrien/vrsar',
                  },
                  {
                    name: 'Vinkuran',
                    href: '/yachtcharter/adria/istrien/vinkuran',
                  },
                  {
                    name: 'Trget',
                    href: '/yachtcharter/adria/istrien/trget',
                  },
                  {
                    name: 'Marina Tehnomont Veruda, Pula',
                    href: '/yachtcharter/adria/istrien/marina-tehnomont-veruda-pula',
                  },
                ],
              },
              {
                name: 'Kvarner Bucht',
                href: '/yachtcharter/adria/kvarner-bucht',
                subList: [
                  {
                    name: 'Punat',
                    href: '/yachtcharter/adria/kvarner-bucht/punat',
                  },
                  {
                    name: 'Rijeka',
                    href: '/yachtcharter/adria/kvarner-bucht/rijeka',
                  },
                  {
                    name: 'Mali Lo\u0161inj',
                    href: '/yachtcharter/adria/kvarner-bucht/mali-losinj',
                  },
                  {
                    name: 'I\u010di\u0107i',
                    href: '/yachtcharter/adria/kvarner-bucht/icici',
                  },
                  {
                    name: 'Kraljevica',
                    href: '/yachtcharter/adria/kvarner-bucht/kraljevica',
                  },
                  {
                    name: 'Crikvenica',
                    href: '/yachtcharter/adria/kvarner-bucht/crikvenica',
                  },
                  {
                    name: 'Rab',
                    href: '/yachtcharter/adria/kvarner-bucht/rab',
                  },
                  {
                    name: 'Punat',
                    href: '/yachtcharter/adria/kvarner-bucht/punat',
                  },
                  {
                    name: 'Malinska',
                    href: '/yachtcharter/adria/kvarner-bucht/malinska',
                  },
                ],
              },
              {
                name: 'S\u00fcddalmatien',
                href: '/yachtcharter/adria/sueddalmatien',
                subList: [
                  {
                    name: 'Slano',
                    href: '/yachtcharter/adria/sueddalmatien/slano',
                  },
                  {
                    name: 'Komolac',
                    href: '/yachtcharter/adria/sueddalmatien/komolac',
                  },
                  {
                    name: 'Zaton',
                    href: '/yachtcharter/adria/sueddalmatien/zaton',
                  },
                  {
                    name: 'Dubrovnik',
                    href: '/yachtcharter/adria/sueddalmatien/dubrovnik',
                  },
                  {
                    name: 'Plo\u010de',
                    href: '/yachtcharter/adria/sueddalmatien/ploce',
                  },
                  {
                    name: 'Zaton',
                    href: '/yachtcharter/adria/sueddalmatien/zaton',
                  },
                ],
              },
              {
                name: 'Mitteldalmatien',
                href: '/yachtcharter/adria/mitteldalmatien',
                subList: [
                  {
                    name: 'Trogir',
                    href: '/yachtcharter/adria/mitteldalmatien/trogir',
                  },
                  {
                    name: 'Ba\u0161ka Voda',
                    href: '/yachtcharter/adria/mitteldalmatien/baska-voda',
                  },
                  {
                    name: 'Ka\u0161tel Gomilica',
                    href: '/yachtcharter/adria/mitteldalmatien/kastel-gomilica',
                  },
                  {
                    name: 'Split',
                    href: '/yachtcharter/adria/mitteldalmatien/split',
                  },
                  {
                    name: 'Seget Donji',
                    href: '/yachtcharter/adria/mitteldalmatien/seget-donji',
                  },
                  {
                    name: 'Podstrana',
                    href: '/yachtcharter/adria/mitteldalmatien/podstrana',
                  },
                  {
                    name: 'Tu\u010depi',
                    href: '/yachtcharter/adria/mitteldalmatien/tucepi',
                  },
                  {
                    name: '\u0160olta',
                    href: '/yachtcharter/adria/mitteldalmatien/solta',
                  },
                  {
                    name: 'Stobre\u010d',
                    href: '/yachtcharter/adria/mitteldalmatien/stobrec',
                  },
                  {
                    name: 'Vini\u0161\u0107e',
                    href: '/yachtcharter/adria/mitteldalmatien/vinisce',
                  },
                  {
                    name: 'Roga\u010d',
                    href: '/yachtcharter/adria/mitteldalmatien/rogac',
                  },
                  {
                    name: 'Milna',
                    href: '/yachtcharter/adria/mitteldalmatien/milna',
                  },
                  {
                    name: 'Trogir',
                    href: '/yachtcharter/adria/mitteldalmatien/trogir',
                  },
                  {
                    name: 'Ka\u0161tel Luk\u0161i\u0107',
                    href: '/yachtcharter/adria/mitteldalmatien/kastel-luksic',
                  },
                ],
              },
              {
                name: 'Norddalmatien',
                href: '/yachtcharter/adria/norddalmatien',
                subList: [
                  {
                    name: 'Rogoznica',
                    href: '/yachtcharter/adria/norddalmatien/rogoznica',
                  },
                  {
                    name: '\u0160ibenik',
                    href: '/yachtcharter/adria/norddalmatien/sibenik',
                  },
                  {
                    name: 'Primo\u0161ten',
                    href: '/yachtcharter/adria/norddalmatien/primosten',
                  },
                  {
                    name: 'Jezera',
                    href: '/yachtcharter/adria/norddalmatien/jezera',
                  },
                  {
                    name: 'Betina',
                    href: '/yachtcharter/adria/norddalmatien/betina',
                  },
                  {
                    name: 'Bibinje',
                    href: '/yachtcharter/adria/norddalmatien/bibinje',
                  },
                  {
                    name: 'Murter',
                    href: '/yachtcharter/adria/norddalmatien/murter',
                  },
                  {
                    name: 'Biograd',
                    href: '/yachtcharter/adria/norddalmatien/biograd',
                  },
                  {
                    name: 'Pirovac',
                    href: '/yachtcharter/adria/norddalmatien/pirovac',
                  },
                  {
                    name: 'Zadar',
                    href: '/yachtcharter/adria/norddalmatien/zadar',
                  },
                  {
                    name: 'Tribunj',
                    href: '/yachtcharter/adria/norddalmatien/tribunj',
                  },
                  {
                    name: 'Petr\u010dane',
                    href: '/yachtcharter/adria/norddalmatien/petrcane',
                  },
                  {
                    name: 'Suko\u0161an',
                    href: '/yachtcharter/adria/norddalmatien/sukosan',
                  },
                  {
                    name: 'Kukljica',
                    href: '/yachtcharter/adria/norddalmatien/kukljica',
                  },
                  {
                    name: 'Zaton',
                    href: '/yachtcharter/adria/norddalmatien/zaton',
                  },
                  {
                    name: 'Vodice',
                    href: '/yachtcharter/adria/norddalmatien/vodice',
                  },
                  {
                    name: 'Biograd',
                    href: '/yachtcharter/adria/norddalmatien/biograd',
                  },
                  {
                    name: 'Skradin',
                    href: '/yachtcharter/adria/norddalmatien/skradin',
                  },
                  {
                    name: '\u0160ibenik',
                    href: '/yachtcharter/adria/norddalmatien/sibenik',
                  },
                  {
                    name: 'Drage',
                    href: '/yachtcharter/adria/norddalmatien/drage',
                  },
                  {
                    name: 'Biograd na Moru',
                    href: '/yachtcharter/adria/norddalmatien/biograd-na-moru',
                  },
                ],
              },
              {
                name: 'Montenegro',
                href: '/yachtcharter/adria/montenegro',
                subList: [
                  {
                    name: 'Tivat',
                    href: '/yachtcharter/adria/montenegro/tivat',
                  },
                  {
                    name: 'Kotor',
                    href: '/yachtcharter/adria/montenegro/kotor',
                  },
                  {
                    name: 'Herceg Novi',
                    href: '/yachtcharter/adria/montenegro/herceg-novi',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Ozeanien',
        icon: GlobeAltIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Ozeanien',
            href: '/yachtcharter/ozeanien',
            list: [
              {
                name: 'Neukaledonien',
                href: '/yachtcharter/ozeanien/neukaledonien',
                subList: [
                  {
                    name: 'Noumea',
                    href: '/yachtcharter/ozeanien/neukaledonien/noumea',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Mittelmeer',
        icon: GlobeAltIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Mittelmeer',
            href: '/yachtcharter/mittelmeer',
            list: [
              {
                name: 'Zypern',
                href: '/yachtcharter/mittelmeer/zypern',
                subList: [
                  {
                    name: 'Limassol',
                    href: '/yachtcharter/mittelmeer/zypern/limassol',
                  },
                ],
              },
              {
                name: 'C\u00f4te d\u2019Azur',
                href: '/yachtcharter/mittelmeer/cote-d-azur',
                subList: [
                  {
                    name: 'Saint-Mandrier-sur-Mer',
                    href: '/yachtcharter/mittelmeer/cote-d-azur/saint-mandrier-sur-mer',
                  },
                  {
                    name: 'Bormes-les-Mimosas',
                    href: '/yachtcharter/mittelmeer/cote-d-azur/bormes-les-mimosas',
                  },
                  {
                    name: 'Cogolin',
                    href: '/yachtcharter/mittelmeer/cote-d-azur/cogolin',
                  },
                  {
                    name: 'Hyeres',
                    href: '/yachtcharter/mittelmeer/cote-d-azur/hyeres',
                  },
                  {
                    name: 'Nice',
                    href: '/yachtcharter/mittelmeer/cote-d-azur/nice',
                  },
                  {
                    name: 'Toulon',
                    href: '/yachtcharter/mittelmeer/cote-d-azur/toulon',
                  },
                  {
                    name: 'Marseille',
                    href: '/yachtcharter/mittelmeer/cote-d-azur/marseille',
                  },
                  {
                    name: 'Grimaud',
                    href: '/yachtcharter/mittelmeer/cote-d-azur/grimaud',
                  },
                  {
                    name: 'Vallauris',
                    href: '/yachtcharter/mittelmeer/cote-d-azur/vallauris',
                  },
                  {
                    name: 'Saint-Rapha\u00ebl',
                    href: '/yachtcharter/mittelmeer/cote-d-azur/saint-raphael',
                  },
                ],
              },
              {
                name: 'Franz\u00f6sische Mittelmeerk\u00fcste',
                href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste',
                subList: [
                  {
                    name: 'Lattes',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/lattes',
                  },
                  {
                    name: 'Bellegarde',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/bellegarde',
                  },
                  {
                    name: 'Saint-Mandrier-sur-Mer',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/saint-mandrier-sur-mer',
                  },
                  {
                    name: 'Capestang',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/capestang',
                  },
                  {
                    name: 'Bormes-les-Mimosas',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/bormes-les-mimosas',
                  },
                  {
                    name: 'Cogolin',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/cogolin',
                  },
                  {
                    name: 'Hyeres',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/hyeres',
                  },
                  {
                    name: 'Nice',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/nice',
                  },
                  {
                    name: 'Toulon',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/toulon',
                  },
                  {
                    name: 'Marseille',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/marseille',
                  },
                  {
                    name: 'Grimaud',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/grimaud',
                  },
                  {
                    name: 'Vallauris',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/vallauris',
                  },
                  {
                    name: 'Saint-Rapha\u00ebl',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/saint-raphael',
                  },
                  {
                    name: 'Agde',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/agde',
                  },
                  {
                    name: 'Carnon',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/carnon',
                  },
                  {
                    name: 'Colombiers',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/colombiers',
                  },
                  {
                    name: 'Portiranges',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/portiranges',
                  },
                  {
                    name: 'St. Gilles',
                    href: '/yachtcharter/mittelmeer/franzoesische-mittelmeerkueste/st-gilles',
                  },
                ],
              },
              {
                name: 'Westliches Mittelmeer',
                href: '/yachtcharter/mittelmeer/westliches-mittelmeer',
                subList: [
                  {
                    name: 'Lattes',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/lattes',
                  },
                  {
                    name: 'Bellegarde',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/bellegarde',
                  },
                  {
                    name: 'Tonnarella',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/tonnarella',
                  },
                  {
                    name: 'Marina',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/marina',
                  },
                  {
                    name: 'Mergellina',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/mergellina',
                  },
                  {
                    name: 'Marsala',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/marsala',
                  },
                  {
                    name: 'Pozzuoli',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/pozzuoli',
                  },
                  {
                    name: 'Cagliari',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/cagliari',
                  },
                  {
                    name: 'La Maddalena',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/la-maddalena',
                  },
                  {
                    name: 'Olbia',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/olbia',
                  },
                  {
                    name: 'Portocolom',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/portocolom',
                  },
                  {
                    name: 'Barcelona',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/barcelona',
                  },
                  {
                    name: 'Roses',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/roses',
                  },
                  {
                    name: 'Llucmajor',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/llucmajor',
                  },
                  {
                    name: "Cala D'or",
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/cala-dor',
                  },
                  {
                    name: 'Genova',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/genova',
                  },
                  {
                    name: 'Girona',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/girona',
                  },
                  {
                    name: 'Palermo',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/palermo',
                  },
                  {
                    name: 'Palma de Mallorca',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/palma-de-mallorca',
                  },
                  {
                    name: 'Denia',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/denia',
                  },
                  {
                    name: 'Golfo Aranci',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/golfo-aranci',
                  },
                  {
                    name: 'Saint-Mandrier-sur-Mer',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/saint-mandrier-sur-mer',
                  },
                  {
                    name: 'Rome',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/rome',
                  },
                  {
                    name: 'El Masnou',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/el-masnou',
                  },
                  {
                    name: 'Palamos',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/palamos',
                  },
                  {
                    name: 'Cecina',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/cecina',
                  },
                  {
                    name: 'Puntone',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/puntone',
                  },
                  {
                    name: 'Furnari',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/furnari',
                  },
                  {
                    name: 'Capestang',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/capestang',
                  },
                  {
                    name: 'Ragusa',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/ragusa',
                  },
                  {
                    name: 'San Vincenzo',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/san-vincenzo',
                  },
                  {
                    name: 'Ibiza',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/ibiza',
                  },
                  {
                    name: 'La Spezia',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/la-spezia',
                  },
                  {
                    name: 'Tropea',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/tropea',
                  },
                  {
                    name: 'Mataro',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/mataro',
                  },
                  {
                    name: 'Propriano',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/propriano',
                  },
                  {
                    name: 'Alcudia',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/alcudia',
                  },
                  {
                    name: 'Milazzo',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/milazzo',
                  },
                  {
                    name: 'S. Agata di Militello',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/s-agata-di-militello',
                  },
                  {
                    name: 'Port de Pollen\u00e7a',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/port-de-pollenca',
                  },
                  {
                    name: 'Santa Maria Navarrese',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/santa-maria-navarrese',
                  },
                  {
                    name: "Capo d'Orlando",
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/capo-dorlando',
                  },
                  {
                    name: 'Can Pastilla',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/can-pastilla',
                  },
                  {
                    name: 'Santa Pon\u00e7a',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/santa-ponca',
                  },
                  {
                    name: 'Trapani',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/trapani',
                  },
                  {
                    name: 'Vibo Marina',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/vibo-marina',
                  },
                  {
                    name: 'Viareggio',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/viareggio',
                  },
                  {
                    name: 'San Vito Lo Capo',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/san-vito-lo-capo',
                  },
                  {
                    name: 'Punta Ala',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/punta-ala',
                  },
                  {
                    name: 'Procida',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/procida',
                  },
                  {
                    name: 'Portoferraio',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/portoferraio',
                  },
                  {
                    name: 'Portovenere',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/portovenere',
                  },
                  {
                    name: 'Porto Rotondo',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/porto-rotondo',
                  },
                  {
                    name: 'Portisco',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/portisco',
                  },
                  {
                    name: 'Bormes-les-Mimosas',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/bormes-les-mimosas',
                  },
                  {
                    name: 'Lipari',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/lipari',
                  },
                  {
                    name: 'Cogolin',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/cogolin',
                  },
                  {
                    name: 'Castellammare di Stabia',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/castellammare-di-stabia',
                  },
                  {
                    name: 'Carloforte',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/carloforte',
                  },
                  {
                    name: 'Cannigione',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/cannigione',
                  },
                  {
                    name: 'Bocca di Magra',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/bocca-di-magra',
                  },
                  {
                    name: 'Anzio',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/anzio',
                  },
                  {
                    name: 'Alghero',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/alghero',
                  },
                  {
                    name: 'Agropoli',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/agropoli',
                  },
                  {
                    name: 'Valencia',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/valencia',
                  },
                  {
                    name: 'Reggio Calabria',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/reggio-calabria',
                  },
                  {
                    name: 'Cambrils',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/cambrils',
                  },
                  {
                    name: 'Castiglioncello',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/castiglioncello',
                  },
                  {
                    name: 'Mahon - Menorca',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/mahon-menorca',
                  },
                  {
                    name: 'Ajaccio',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/ajaccio',
                  },
                  {
                    name: 'Arzachena',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/arzachena',
                  },
                  {
                    name: 'Hyeres',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/hyeres',
                  },
                  {
                    name: 'Kalkara',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/kalkara',
                  },
                  {
                    name: 'Nice',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/nice',
                  },
                  {
                    name: 'Toulon',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/toulon',
                  },
                  {
                    name: 'Marseille',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/marseille',
                  },
                  {
                    name: 'Grimaud',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/grimaud',
                  },
                  {
                    name: 'Premi\u00e0 de Mar, Barcelona',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/premia-de-mar-barcelona',
                  },
                  {
                    name: 'Roda de Bar\u00e0',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/roda-de-bara',
                  },
                  {
                    name: 'Rosignano Solvay',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/rosignano-solvay',
                  },
                  {
                    name: 'Santany\u00ed',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/santanyi',
                  },
                  {
                    name: 'Eivissa',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/eivissa',
                  },
                  {
                    name: 'Ma\u00f3',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/mao',
                  },
                  {
                    name: 'San Gregorio-bagnoli',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/san-gregorio-bagnoli',
                  },
                  {
                    name: 'Pollen\u00e7a',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/pollenca',
                  },
                  {
                    name: 'Portals Nous-Calvi\u00e0',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/portals-nous-calvia',
                  },
                  {
                    name: 'La Vila Joiosa',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/la-vila-joiosa',
                  },
                  {
                    name: 'Vallauris',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/vallauris',
                  },
                  {
                    name: 'Saint-Rapha\u00ebl',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/saint-raphael',
                  },
                  {
                    name: 'Amalfi',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/amalfi',
                  },
                  {
                    name: 'Sitges',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/sitges',
                  },
                  {
                    name: 'Castellammare del Golfo',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/castellammare-del-golfo',
                  },
                  {
                    name: 'Agde',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/agde',
                  },
                  {
                    name: 'Carnon',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/carnon',
                  },
                  {
                    name: 'Colombiers',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/colombiers',
                  },
                  {
                    name: 'Porto di Castellammare di Stabia',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/porto-di-castellammare-di-stabia',
                  },
                  {
                    name: 'Salivoli',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/salivoli',
                  },
                  {
                    name: 'Sant Antoni',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/sant-antoni',
                  },
                  {
                    name: 'Aranci',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/aranci',
                  },
                  {
                    name: 'Nettuno',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/nettuno',
                  },
                  {
                    name: 'Palma',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/palma',
                  },
                  {
                    name: 'Salerno',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/salerno',
                  },
                  {
                    name: 'Napoli',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/napoli',
                  },
                  {
                    name: 'Il-Kalkara',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/il-kalkara',
                  },
                  {
                    name: 'Cannigione Arzachena',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/cannigione-arzachena',
                  },
                  {
                    name: 'Naples',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/naples',
                  },
                  {
                    name: 'Sitges, Barcelona',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/sitges-barcelona',
                  },
                  {
                    name: 'Balestrate',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/balestrate',
                  },
                  {
                    name: 'Portiranges',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/portiranges',
                  },
                  {
                    name: 'St. Gilles',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/st-gilles',
                  },
                  {
                    name: 'Gandia',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/gandia',
                  },
                  {
                    name: 'Torredembarra',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/torredembarra',
                  },
                  {
                    name: "Capo D'orlando Marina",
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/capo-dorlando-marina',
                  },
                  {
                    name: 'Marina di Scarlino - Follonica',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/marina-di-scarlino-follonica',
                  },
                  {
                    name: 'San Antonio Abad',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/san-antonio-abad',
                  },
                  {
                    name: 'Marina Villa Igea, Palermo',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/marina-villa-igea-palermo',
                  },
                  {
                    name: 'Rosignano Solvay-Castiglioncello',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/rosignano-solvay-castiglioncello',
                  },
                  {
                    name: 'Roda de Ber\u00e0',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/roda-de-bera',
                  },
                  {
                    name: 'Neapel',
                    href: '/yachtcharter/mittelmeer/westliches-mittelmeer/neapel',
                  },
                ],
              },
              {
                name: '\u00d6stliches Mittelmeer',
                href: '/yachtcharter/mittelmeer/oestliches-mittelmeer',
                subList: [
                  {
                    name: 'Chioggia',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/chioggia',
                  },
                  {
                    name: 'Rogoznica',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/rogoznica',
                  },
                  {
                    name: '\u0160ibenik',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/sibenik',
                  },
                  {
                    name: 'Primo\u0161ten',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/primosten',
                  },
                  {
                    name: 'Trogir',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/trogir',
                  },
                  {
                    name: 'Slano',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/slano',
                  },
                  {
                    name: 'Komolac',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/komolac',
                  },
                  {
                    name: 'Ba\u0161ka Voda',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/baska-voda',
                  },
                  {
                    name: 'Ka\u0161tel Gomilica',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kastel-gomilica',
                  },
                  {
                    name: 'Split',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/split',
                  },
                  {
                    name: 'Jezera',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/jezera',
                  },
                  {
                    name: 'Alimos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/alimos',
                  },
                  {
                    name: 'Kos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kos',
                  },
                  {
                    name: 'Lavrio',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/lavrio',
                  },
                  {
                    name: 'Lefkada',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/lefkada',
                  },
                  {
                    name: 'Betina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/betina',
                  },
                  {
                    name: 'Bibinje',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/bibinje',
                  },
                  {
                    name: 'Murter',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/murter',
                  },
                  {
                    name: 'Biograd',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/biograd',
                  },
                  {
                    name: 'Pirovac',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/pirovac',
                  },
                  {
                    name: 'Punat',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/punat',
                  },
                  {
                    name: 'Zadar',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/zadar',
                  },
                  {
                    name: 'Tourlos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/tourlos',
                  },
                  {
                    name: 'Rijeka',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/rijeka',
                  },
                  {
                    name: 'Skiathos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/skiathos',
                  },
                  {
                    name: 'Volos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/volos',
                  },
                  {
                    name: 'Kalamata',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kalamata',
                  },
                  {
                    name: 'Sami',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/sami',
                  },
                  {
                    name: 'Ermoupoli',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/ermoupoli',
                  },
                  {
                    name: 'Seget Donji',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/seget-donji',
                  },
                  {
                    name: 'Pula',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/pula',
                  },
                  {
                    name: 'Elliniko',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/elliniko',
                  },
                  {
                    name: 'Pomer',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/pomer',
                  },
                  {
                    name: 'Mali Lo\u0161inj',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/mali-losinj',
                  },
                  {
                    name: 'Podstrana',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/podstrana',
                  },
                  {
                    name: 'Tribunj',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/tribunj',
                  },
                  {
                    name: 'Tivat',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/tivat',
                  },
                  {
                    name: 'Paleros',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/paleros',
                  },
                  {
                    name: 'Preveza',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/preveza',
                  },
                  {
                    name: 'I\u010di\u0107i',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/icici',
                  },
                  {
                    name: 'Rabac',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/rabac',
                  },
                  {
                    name: 'Marina di Montenero di Bisaccia',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/marina-di-montenero-di-bisaccia',
                  },
                  {
                    name: 'San Benedetto del Tronto',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/san-benedetto-del-tronto',
                  },
                  {
                    name: 'Petr\u010dane',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/petrcane',
                  },
                  {
                    name: 'Izola',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/izola',
                  },
                  {
                    name: 'Orhaniye',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/orhaniye',
                  },
                  {
                    name: 'Neos Marmaras',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/neos-marmaras',
                  },
                  {
                    name: 'Piraeus',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/piraeus',
                  },
                  {
                    name: 'Medulin',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/medulin',
                  },
                  {
                    name: 'Muggia',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/muggia',
                  },
                  {
                    name: 'Athen',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/athen',
                  },
                  {
                    name: 'Syros',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/syros',
                  },
                  {
                    name: 'Marmaris',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/marmaris',
                  },
                  {
                    name: 'Rovinj',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/rovinj',
                  },
                  {
                    name: 'Bari',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/bari',
                  },
                  {
                    name: 'Tu\u010depi',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/tucepi',
                  },
                  {
                    name: '\u0160olta',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/solta',
                  },
                  {
                    name: 'Heraklion',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/heraklion',
                  },
                  {
                    name: 'Zaton',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/zaton',
                  },
                  {
                    name: 'Rodi',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/rodi',
                  },
                  {
                    name: 'Suko\u0161an',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/sukosan',
                  },
                  {
                    name: 'Skopelos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/skopelos',
                  },
                  {
                    name: 'Paros',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/paros',
                  },
                  {
                    name: 'Santorini',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/santorini',
                  },
                  {
                    name: 'Thessaloniki',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/thessaloniki',
                  },
                  {
                    name: 'Dubrovnik',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/dubrovnik',
                  },
                  {
                    name: 'Stobre\u010d',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/stobrec',
                  },
                  {
                    name: 'Palairos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/palairos',
                  },
                  {
                    name: 'Kraljevica',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kraljevica',
                  },
                  {
                    name: 'Caorle ',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/caorle',
                  },
                  {
                    name: 'Agia Eufimia',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/agia-eufimia',
                  },
                  {
                    name: 'Kotor',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kotor',
                  },
                  {
                    name: 'Kukljica',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kukljica',
                  },
                  {
                    name: 'Vini\u0161\u0107e',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/vinisce',
                  },
                  {
                    name: 'San Foca',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/san-foca',
                  },
                  {
                    name: 'Vrsar',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/vrsar',
                  },
                  {
                    name: 'Leuca',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/leuca',
                  },
                  {
                    name: 'Kavala',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kavala',
                  },
                  {
                    name: 'Lignano Sabbiadoro',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/lignano-sabbiadoro',
                  },
                  {
                    name: 'Zakynthos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/zakynthos',
                  },
                  {
                    name: 'Taranto',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/taranto',
                  },
                  {
                    name: 'Mykonos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/mykonos',
                  },
                  {
                    name: 'Ravenna',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/ravenna',
                  },
                  {
                    name: 'Zaton',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/zaton',
                  },
                  {
                    name: 'Vodice',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/vodice',
                  },
                  {
                    name: 'Biograd',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/biograd',
                  },
                  {
                    name: 'Bodrum',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/bodrum',
                  },
                  {
                    name: 'Crikvenica',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/crikvenica',
                  },
                  {
                    name: 'Limassol',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/limassol',
                  },
                  {
                    name: 'G\u00f6cek',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/goecek',
                  },
                  {
                    name: 'Herceg Novi',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/herceg-novi',
                  },
                  {
                    name: 'Kerkira',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kerkira',
                  },
                  {
                    name: 'Skradin',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/skradin',
                  },
                  {
                    name: 'Ormos Panagias',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/ormos-panagias',
                  },
                  {
                    name: 'Palaio Faliro',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/palaio-faliro',
                  },
                  {
                    name: 'Fethiye',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/fethiye',
                  },
                  {
                    name: 'Plo\u010de',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/ploce',
                  },
                  {
                    name: 'Sivota',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/sivota',
                  },
                  {
                    name: 'Keramoti',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/keramoti',
                  },
                  {
                    name: 'Corfu',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/corfu',
                  },
                  {
                    name: 'Achillio',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/achillio',
                  },
                  {
                    name: 'Pireas',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/pireas',
                  },
                  {
                    name: 'Mikonos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/mikonos',
                  },
                  {
                    name: 'Piso Livadi',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/piso-livadi',
                  },
                  {
                    name: 'Rhodos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/rhodos',
                  },
                  {
                    name: 'Kontokali',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kontokali',
                  },
                  {
                    name: 'Nikiana',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/nikiana',
                  },
                  {
                    name: 'Nidri',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/nidri',
                  },
                  {
                    name: 'Roga\u010d',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/rogac',
                  },
                  {
                    name: 'Glifada',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/glifada',
                  },
                  {
                    name: 'Milna',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/milna',
                  },
                  {
                    name: 'Marano',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/marano',
                  },
                  {
                    name: 'Kefalonia',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kefalonia',
                  },
                  {
                    name: 'Gouvia',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/gouvia',
                  },
                  {
                    name: 'Nikiti',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/nikiti',
                  },
                  {
                    name: 'Nea Peramos - Attica',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/nea-peramos-attica',
                  },
                  {
                    name: 'Halkidiki',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/halkidiki',
                  },
                  {
                    name: 'Marina Portoro\u017e',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/marina-portoroz',
                  },
                  {
                    name: 'Trogir',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/trogir',
                  },
                  {
                    name: '\u0160ibenik',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/sibenik',
                  },
                  {
                    name: 'Thasos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/thasos',
                  },
                  {
                    name: 'Athens',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/athens',
                  },
                  {
                    name: 'Ialisos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/ialisos',
                  },
                  {
                    name: 'Rab',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/rab',
                  },
                  {
                    name: 'Ece Saray Marina Resort, Fethiye',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/ece-saray-marina-resort-fethiye',
                  },
                  {
                    name: 'Marmaris, Netsel Marina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/marmaris-netsel-marina',
                  },
                  {
                    name: 'Casale sul Sile',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/casale-sul-sile',
                  },
                  {
                    name: 'Precenicco',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/precenicco',
                  },
                  {
                    name: 'Aegina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/aegina',
                  },
                  {
                    name: 'Kallithea',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kallithea',
                  },
                  {
                    name: 'Lughignano',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/lughignano',
                  },
                  {
                    name: 'Precenicco',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/precenicco',
                  },
                  {
                    name: 'Punat',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/punat',
                  },
                  {
                    name: 'Elefsina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/elefsina',
                  },
                  {
                    name: 'Patra ',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/patra',
                  },
                  {
                    name: 'Salamina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/salamina',
                  },
                  {
                    name: 'Malinska',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/malinska',
                  },
                  {
                    name: 'Naoussa',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/naoussa',
                  },
                  {
                    name: 'Nea Peramos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/nea-peramos',
                  },
                  {
                    name: 'Vinkuran',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/vinkuran',
                  },
                  {
                    name: 'Zaton',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/zaton',
                  },
                  {
                    name: 'Cleopatra Marina (Preveza)',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/cleopatra-marina-preveza',
                  },
                  {
                    name: 'Bodrum/Turgutreis/D-Marin',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/bodrum-turgutreis-d-marin',
                  },
                  {
                    name: 'G\u00f6cek/D-Marin',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/goecek-d-marin',
                  },
                  {
                    name: 'Vlicho',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/vlicho',
                  },
                  {
                    name: 'Rhodos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/rhodos',
                  },
                  {
                    name: 'Marin Turk G\u00f6cek Village Port',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/marin-turk-goecek-village-port',
                  },
                  {
                    name: 'Milta Bodrum Marina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/milta-bodrum-marina',
                  },
                  {
                    name: 'Kas Marina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kas-marina',
                  },
                  {
                    name: 'Trget',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/trget',
                  },
                  {
                    name: 'Marina Tehnomont Veruda, Pula',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/marina-tehnomont-veruda-pula',
                  },
                  {
                    name: 'Yacht Classic Hotel Marina, Fethiye',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/yacht-classic-hotel-marina-fethiye',
                  },
                  {
                    name: 'Marmaris Yacht Marina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/marmaris-yacht-marina',
                  },
                  {
                    name: 'Limenas Thassos',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/limenas-thassos',
                  },
                  {
                    name: 'Yes Marina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/yes-marina',
                  },
                  {
                    name: 'G\u00f6kova \u00d6ren Marina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/goekova-oeren-marina',
                  },
                  {
                    name: 'Drage',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/drage',
                  },
                  {
                    name: 'Ermioni',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/ermioni',
                  },
                  {
                    name: 'Egina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/egina',
                  },
                  {
                    name: 'G\u00f6cek/Skopea Marina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/goecek-skopea-marina',
                  },
                  {
                    name: 'Yacht Club Marina Salamina, Athens area',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/yacht-club-marina-salamina-athens-area',
                  },
                  {
                    name: 'Limenas Avdiron',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/limenas-avdiron',
                  },
                  {
                    name: 'Corfu, Mandraki',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/corfu-mandraki',
                  },
                  {
                    name: 'Marmaris Adakoy Marina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/marmaris-adakoy-marina',
                  },
                  {
                    name: 'Ka\u0161tel Luk\u0161i\u0107',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/kastel-luksic',
                  },
                  {
                    name: 'Palmarina - Yalikavak, Bodrum',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/palmarina-yalikavak-bodrum',
                  },
                  {
                    name: 'Biograd na Moru',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/biograd-na-moru',
                  },
                  {
                    name: 'Benitses, Corfu',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/benitses-corfu',
                  },
                  {
                    name: 'Bodrum, G\u00fcmbet Marina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/bodrum-guembet-marina',
                  },
                  {
                    name: 'Marmaris, Joya Del Mar Marina',
                    href: '/yachtcharter/mittelmeer/oestliches-mittelmeer/marmaris-joya-del-mar-marina',
                  },
                ],
              },
              {
                name: 'Balearische Inseln',
                href: '/yachtcharter/mittelmeer/balearische-inseln',
                subList: [
                  {
                    name: 'Portocolom',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/portocolom',
                  },
                  {
                    name: 'Llucmajor',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/llucmajor',
                  },
                  {
                    name: "Cala D'or",
                    href: '/yachtcharter/mittelmeer/balearische-inseln/cala-dor',
                  },
                  {
                    name: 'Palma de Mallorca',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/palma-de-mallorca',
                  },
                  {
                    name: 'Ibiza',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/ibiza',
                  },
                  {
                    name: 'Alcudia',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/alcudia',
                  },
                  {
                    name: 'Port de Pollen\u00e7a',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/port-de-pollenca',
                  },
                  {
                    name: 'Can Pastilla',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/can-pastilla',
                  },
                  {
                    name: 'Santa Pon\u00e7a',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/santa-ponca',
                  },
                  {
                    name: 'Mahon - Menorca',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/mahon-menorca',
                  },
                  {
                    name: 'Santany\u00ed',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/santanyi',
                  },
                  {
                    name: 'Eivissa',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/eivissa',
                  },
                  {
                    name: 'Ma\u00f3',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/mao',
                  },
                  {
                    name: 'Pollen\u00e7a',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/pollenca',
                  },
                  {
                    name: 'Portals Nous-Calvi\u00e0',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/portals-nous-calvia',
                  },
                  {
                    name: 'Sant Antoni',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/sant-antoni',
                  },
                  {
                    name: 'Palma',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/palma',
                  },
                  {
                    name: 'San Antonio Abad',
                    href: '/yachtcharter/mittelmeer/balearische-inseln/san-antonio-abad',
                  },
                ],
              },
              {
                name: 'Mallorca',
                href: '/yachtcharter/mittelmeer/mallorca',
                subList: [
                  {
                    name: 'Portocolom',
                    href: '/yachtcharter/mittelmeer/mallorca/portocolom',
                  },
                  {
                    name: 'Llucmajor',
                    href: '/yachtcharter/mittelmeer/mallorca/llucmajor',
                  },
                  {
                    name: "Cala D'or",
                    href: '/yachtcharter/mittelmeer/mallorca/cala-dor',
                  },
                  {
                    name: 'Palma de Mallorca',
                    href: '/yachtcharter/mittelmeer/mallorca/palma-de-mallorca',
                  },
                  {
                    name: 'Alcudia',
                    href: '/yachtcharter/mittelmeer/mallorca/alcudia',
                  },
                  {
                    name: 'Port de Pollen\u00e7a',
                    href: '/yachtcharter/mittelmeer/mallorca/port-de-pollenca',
                  },
                  {
                    name: 'Can Pastilla',
                    href: '/yachtcharter/mittelmeer/mallorca/can-pastilla',
                  },
                  {
                    name: 'Santa Pon\u00e7a',
                    href: '/yachtcharter/mittelmeer/mallorca/santa-ponca',
                  },
                  {
                    name: 'Santany\u00ed',
                    href: '/yachtcharter/mittelmeer/mallorca/santanyi',
                  },
                  {
                    name: 'Pollen\u00e7a',
                    href: '/yachtcharter/mittelmeer/mallorca/pollenca',
                  },
                  {
                    name: 'Portals Nous-Calvi\u00e0',
                    href: '/yachtcharter/mittelmeer/mallorca/portals-nous-calvia',
                  },
                  {
                    name: 'Palma',
                    href: '/yachtcharter/mittelmeer/mallorca/palma',
                  },
                ],
              },
              {
                name: 'Ibiza',
                href: '/yachtcharter/mittelmeer/ibiza',
                subList: [
                  {
                    name: 'Ibiza',
                    href: '/yachtcharter/mittelmeer/ibiza/ibiza',
                  },
                  {
                    name: 'Eivissa',
                    href: '/yachtcharter/mittelmeer/ibiza/eivissa',
                  },
                  {
                    name: 'Sant Antoni',
                    href: '/yachtcharter/mittelmeer/ibiza/sant-antoni',
                  },
                  {
                    name: 'San Antonio Abad',
                    href: '/yachtcharter/mittelmeer/ibiza/san-antonio-abad',
                  },
                ],
              },
              {
                name: 'Katalonien',
                href: '/yachtcharter/mittelmeer/katalonien',
                subList: [
                  {
                    name: 'Barcelona',
                    href: '/yachtcharter/mittelmeer/katalonien/barcelona',
                  },
                  {
                    name: 'Roses',
                    href: '/yachtcharter/mittelmeer/katalonien/roses',
                  },
                  {
                    name: 'Girona',
                    href: '/yachtcharter/mittelmeer/katalonien/girona',
                  },
                  {
                    name: 'El Masnou',
                    href: '/yachtcharter/mittelmeer/katalonien/el-masnou',
                  },
                  {
                    name: 'Palamos',
                    href: '/yachtcharter/mittelmeer/katalonien/palamos',
                  },
                  {
                    name: 'Mataro',
                    href: '/yachtcharter/mittelmeer/katalonien/mataro',
                  },
                  {
                    name: 'Cambrils',
                    href: '/yachtcharter/mittelmeer/katalonien/cambrils',
                  },
                  {
                    name: 'Premi\u00e0 de Mar, Barcelona',
                    href: '/yachtcharter/mittelmeer/katalonien/premia-de-mar-barcelona',
                  },
                  {
                    name: 'Roda de Bar\u00e0',
                    href: '/yachtcharter/mittelmeer/katalonien/roda-de-bara',
                  },
                  {
                    name: 'Sitges',
                    href: '/yachtcharter/mittelmeer/katalonien/sitges',
                  },
                  {
                    name: 'Sitges, Barcelona',
                    href: '/yachtcharter/mittelmeer/katalonien/sitges-barcelona',
                  },
                  {
                    name: 'Torredembarra',
                    href: '/yachtcharter/mittelmeer/katalonien/torredembarra',
                  },
                  {
                    name: 'Roda de Ber\u00e0',
                    href: '/yachtcharter/mittelmeer/katalonien/roda-de-bera',
                  },
                ],
              },
              {
                name: 'Valencia',
                href: '/yachtcharter/mittelmeer/valencia',
                subList: [
                  {
                    name: 'Denia',
                    href: '/yachtcharter/mittelmeer/valencia/denia',
                  },
                  {
                    name: 'Valencia',
                    href: '/yachtcharter/mittelmeer/valencia/valencia',
                  },
                  {
                    name: 'La Vila Joiosa',
                    href: '/yachtcharter/mittelmeer/valencia/la-vila-joiosa',
                  },
                ],
              },
              {
                name: '\u00c4g\u00e4is',
                href: '/yachtcharter/mittelmeer/aegaeis',
                subList: [
                  {
                    name: 'Kos',
                    href: '/yachtcharter/mittelmeer/aegaeis/kos',
                  },
                  {
                    name: 'Orhaniye',
                    href: '/yachtcharter/mittelmeer/aegaeis/orhaniye',
                  },
                  {
                    name: 'Marmaris',
                    href: '/yachtcharter/mittelmeer/aegaeis/marmaris',
                  },
                  {
                    name: 'Bodrum',
                    href: '/yachtcharter/mittelmeer/aegaeis/bodrum',
                  },
                  {
                    name: 'Marmaris, Netsel Marina',
                    href: '/yachtcharter/mittelmeer/aegaeis/marmaris-netsel-marina',
                  },
                  {
                    name: 'Bodrum/Turgutreis/D-Marin',
                    href: '/yachtcharter/mittelmeer/aegaeis/bodrum-turgutreis-d-marin',
                  },
                  {
                    name: 'Milta Bodrum Marina',
                    href: '/yachtcharter/mittelmeer/aegaeis/milta-bodrum-marina',
                  },
                  {
                    name: 'Marmaris Yacht Marina',
                    href: '/yachtcharter/mittelmeer/aegaeis/marmaris-yacht-marina',
                  },
                  {
                    name: 'G\u00f6kova \u00d6ren Marina',
                    href: '/yachtcharter/mittelmeer/aegaeis/goekova-oeren-marina',
                  },
                  {
                    name: 'Marmaris Adakoy Marina',
                    href: '/yachtcharter/mittelmeer/aegaeis/marmaris-adakoy-marina',
                  },
                  {
                    name: 'Palmarina - Yalikavak, Bodrum',
                    href: '/yachtcharter/mittelmeer/aegaeis/palmarina-yalikavak-bodrum',
                  },
                  {
                    name: 'Bodrum, G\u00fcmbet Marina',
                    href: '/yachtcharter/mittelmeer/aegaeis/bodrum-guembet-marina',
                  },
                  {
                    name: 'Marmaris, Joya Del Mar Marina',
                    href: '/yachtcharter/mittelmeer/aegaeis/marmaris-joya-del-mar-marina',
                  },
                ],
              },
              {
                name: 'Korsika',
                href: '/yachtcharter/mittelmeer/korsika',
                subList: [
                  {
                    name: 'Propriano',
                    href: '/yachtcharter/mittelmeer/korsika/propriano',
                  },
                  {
                    name: 'Ajaccio',
                    href: '/yachtcharter/mittelmeer/korsika/ajaccio',
                  },
                ],
              },
              {
                name: 'Okzitanien',
                href: '/yachtcharter/mittelmeer/okzitanien',
                subList: [
                  {
                    name: 'Lattes',
                    href: '/yachtcharter/mittelmeer/okzitanien/lattes',
                  },
                  {
                    name: 'Argens-Minervois',
                    href: '/yachtcharter/mittelmeer/okzitanien/argens-minervois',
                  },
                  {
                    name: 'Lauragais',
                    href: '/yachtcharter/mittelmeer/okzitanien/lauragais',
                  },
                  {
                    name: 'Valence-sur-Ba\u00efse',
                    href: '/yachtcharter/mittelmeer/okzitanien/valence-sur-baise',
                  },
                  {
                    name: 'Cahors',
                    href: '/yachtcharter/mittelmeer/okzitanien/cahors',
                  },
                  {
                    name: 'Bellegarde',
                    href: '/yachtcharter/mittelmeer/okzitanien/bellegarde',
                  },
                  {
                    name: "Saint-Nazaire-d'Aude",
                    href: '/yachtcharter/mittelmeer/okzitanien/saint-nazaire-daude',
                  },
                  {
                    name: 'Lauragais',
                    href: '/yachtcharter/mittelmeer/okzitanien/lauragais',
                  },
                  {
                    name: 'Bram',
                    href: '/yachtcharter/mittelmeer/okzitanien/bram',
                  },
                  {
                    name: 'Douelle',
                    href: '/yachtcharter/mittelmeer/okzitanien/douelle',
                  },
                  {
                    name: 'Tr\u00e8bes',
                    href: '/yachtcharter/mittelmeer/okzitanien/trebes',
                  },
                  {
                    name: 'Capestang',
                    href: '/yachtcharter/mittelmeer/okzitanien/capestang',
                  },
                  {
                    name: 'Castelnaudary ',
                    href: '/yachtcharter/mittelmeer/okzitanien/castelnaudary',
                  },
                ],
              },
              {
                name: 'Athen',
                href: '/yachtcharter/mittelmeer/athen',
                subList: [
                  {
                    name: 'Alimos',
                    href: '/yachtcharter/mittelmeer/athen/alimos',
                  },
                  {
                    name: 'Lavrio',
                    href: '/yachtcharter/mittelmeer/athen/lavrio',
                  },
                  {
                    name: 'Elliniko',
                    href: '/yachtcharter/mittelmeer/athen/elliniko',
                  },
                  {
                    name: 'Piraeus',
                    href: '/yachtcharter/mittelmeer/athen/piraeus',
                  },
                  {
                    name: 'Athen',
                    href: '/yachtcharter/mittelmeer/athen/athen',
                  },
                  {
                    name: 'Palaio Faliro',
                    href: '/yachtcharter/mittelmeer/athen/palaio-faliro',
                  },
                  {
                    name: 'Pireas',
                    href: '/yachtcharter/mittelmeer/athen/pireas',
                  },
                  {
                    name: 'Glifada',
                    href: '/yachtcharter/mittelmeer/athen/glifada',
                  },
                  {
                    name: 'Nea Peramos - Attica',
                    href: '/yachtcharter/mittelmeer/athen/nea-peramos-attica',
                  },
                  {
                    name: 'Athens',
                    href: '/yachtcharter/mittelmeer/athen/athens',
                  },
                  {
                    name: 'Aegina',
                    href: '/yachtcharter/mittelmeer/athen/aegina',
                  },
                  {
                    name: 'Kallithea',
                    href: '/yachtcharter/mittelmeer/athen/kallithea',
                  },
                  {
                    name: 'Elefsina',
                    href: '/yachtcharter/mittelmeer/athen/elefsina',
                  },
                  {
                    name: 'Salamina',
                    href: '/yachtcharter/mittelmeer/athen/salamina',
                  },
                  {
                    name: 'Nea Peramos',
                    href: '/yachtcharter/mittelmeer/athen/nea-peramos',
                  },
                  {
                    name: 'Egina',
                    href: '/yachtcharter/mittelmeer/athen/egina',
                  },
                  {
                    name: 'Yacht Club Marina Salamina, Athens area',
                    href: '/yachtcharter/mittelmeer/athen/yacht-club-marina-salamina-athens-area',
                  },
                ],
              },
              {
                name: 'Ionische Inseln',
                href: '/yachtcharter/mittelmeer/ionische-inseln',
                subList: [
                  {
                    name: 'Lefkada',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/lefkada',
                  },
                  {
                    name: 'Sami',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/sami',
                  },
                  {
                    name: 'Agia Eufimia',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/agia-eufimia',
                  },
                  {
                    name: 'Zakynthos',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/zakynthos',
                  },
                  {
                    name: 'Kerkira',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/kerkira',
                  },
                  {
                    name: 'Sivota',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/sivota',
                  },
                  {
                    name: 'Corfu',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/corfu',
                  },
                  {
                    name: 'Kontokali',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/kontokali',
                  },
                  {
                    name: 'Nikiana',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/nikiana',
                  },
                  {
                    name: 'Nidri',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/nidri',
                  },
                  {
                    name: 'Kefalonia',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/kefalonia',
                  },
                  {
                    name: 'Gouvia',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/gouvia',
                  },
                  {
                    name: 'Vlicho',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/vlicho',
                  },
                  {
                    name: 'Corfu, Mandraki',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/corfu-mandraki',
                  },
                  {
                    name: 'Benitses, Corfu',
                    href: '/yachtcharter/mittelmeer/ionische-inseln/benitses-corfu',
                  },
                ],
              },
              {
                name: 'Kreta',
                href: '/yachtcharter/mittelmeer/kreta',
                subList: [
                  {
                    name: 'Heraklion',
                    href: '/yachtcharter/mittelmeer/kreta/heraklion',
                  },
                ],
              },
              {
                name: 'Ost Makedonien',
                href: '/yachtcharter/mittelmeer/ost-makedonien',
                subList: [
                  {
                    name: 'Kavala',
                    href: '/yachtcharter/mittelmeer/ost-makedonien/kavala',
                  },
                  {
                    name: 'Keramoti',
                    href: '/yachtcharter/mittelmeer/ost-makedonien/keramoti',
                  },
                  {
                    name: 'Thasos',
                    href: '/yachtcharter/mittelmeer/ost-makedonien/thasos',
                  },
                  {
                    name: 'Limenas Thassos',
                    href: '/yachtcharter/mittelmeer/ost-makedonien/limenas-thassos',
                  },
                  {
                    name: 'Limenas Avdiron',
                    href: '/yachtcharter/mittelmeer/ost-makedonien/limenas-avdiron',
                  },
                ],
              },
              {
                name: 'Makedonien',
                href: '/yachtcharter/mittelmeer/makedonien',
                subList: [
                  {
                    name: 'Neos Marmaras',
                    href: '/yachtcharter/mittelmeer/makedonien/neos-marmaras',
                  },
                  {
                    name: 'Thessaloniki',
                    href: '/yachtcharter/mittelmeer/makedonien/thessaloniki',
                  },
                  {
                    name: 'Ormos Panagias',
                    href: '/yachtcharter/mittelmeer/makedonien/ormos-panagias',
                  },
                  {
                    name: 'Nikiti',
                    href: '/yachtcharter/mittelmeer/makedonien/nikiti',
                  },
                  {
                    name: 'Halkidiki',
                    href: '/yachtcharter/mittelmeer/makedonien/halkidiki',
                  },
                ],
              },
              {
                name: 'Peloponnese',
                href: '/yachtcharter/mittelmeer/peloponnese',
                subList: [
                  {
                    name: 'Kalamata',
                    href: '/yachtcharter/mittelmeer/peloponnese/kalamata',
                  },
                  {
                    name: 'Ermioni',
                    href: '/yachtcharter/mittelmeer/peloponnese/ermioni',
                  },
                ],
              },
              {
                name: 'South Aegean',
                href: '/yachtcharter/mittelmeer/south-aegean',
                subList: [
                  {
                    name: 'Kos',
                    href: '/yachtcharter/mittelmeer/south-aegean/kos',
                  },
                  {
                    name: 'Tourlos',
                    href: '/yachtcharter/mittelmeer/south-aegean/tourlos',
                  },
                  {
                    name: 'Ermoupoli',
                    href: '/yachtcharter/mittelmeer/south-aegean/ermoupoli',
                  },
                  {
                    name: 'Syros',
                    href: '/yachtcharter/mittelmeer/south-aegean/syros',
                  },
                  {
                    name: 'Heraklion',
                    href: '/yachtcharter/mittelmeer/south-aegean/heraklion',
                  },
                  {
                    name: 'Paros',
                    href: '/yachtcharter/mittelmeer/south-aegean/paros',
                  },
                  {
                    name: 'Santorini',
                    href: '/yachtcharter/mittelmeer/south-aegean/santorini',
                  },
                  {
                    name: 'Mykonos',
                    href: '/yachtcharter/mittelmeer/south-aegean/mykonos',
                  },
                  {
                    name: 'Mikonos',
                    href: '/yachtcharter/mittelmeer/south-aegean/mikonos',
                  },
                  {
                    name: 'Piso Livadi',
                    href: '/yachtcharter/mittelmeer/south-aegean/piso-livadi',
                  },
                  {
                    name: 'Rhodos',
                    href: '/yachtcharter/mittelmeer/south-aegean/rhodos',
                  },
                  {
                    name: 'Ialisos',
                    href: '/yachtcharter/mittelmeer/south-aegean/ialisos',
                  },
                  {
                    name: 'Naoussa',
                    href: '/yachtcharter/mittelmeer/south-aegean/naoussa',
                  },
                  {
                    name: 'Rhodos',
                    href: '/yachtcharter/mittelmeer/south-aegean/rhodos',
                  },
                ],
              },
              {
                name: 'Thessalien',
                href: '/yachtcharter/mittelmeer/thessalien',
                subList: [
                  {
                    name: 'Skiathos',
                    href: '/yachtcharter/mittelmeer/thessalien/skiathos',
                  },
                  {
                    name: 'Volos',
                    href: '/yachtcharter/mittelmeer/thessalien/volos',
                  },
                  {
                    name: 'Skopelos',
                    href: '/yachtcharter/mittelmeer/thessalien/skopelos',
                  },
                  {
                    name: 'Achillio',
                    href: '/yachtcharter/mittelmeer/thessalien/achillio',
                  },
                ],
              },
              {
                name: 'West Griechenland',
                href: '/yachtcharter/mittelmeer/west-griechenland',
                subList: [
                  {
                    name: 'Paleros',
                    href: '/yachtcharter/mittelmeer/west-griechenland/paleros',
                  },
                  {
                    name: 'Palairos',
                    href: '/yachtcharter/mittelmeer/west-griechenland/palairos',
                  },
                  {
                    name: 'Patra ',
                    href: '/yachtcharter/mittelmeer/west-griechenland/patra',
                  },
                ],
              },
              {
                name: 'Basilikata',
                href: '/yachtcharter/mittelmeer/basilikata',
                subList: [
                  {
                    name: 'Marina',
                    href: '/yachtcharter/mittelmeer/basilikata/marina',
                  },
                ],
              },
              {
                name: 'Elba',
                href: '/yachtcharter/mittelmeer/elba',
                subList: [
                  {
                    name: 'Portoferraio',
                    href: '/yachtcharter/mittelmeer/elba/portoferraio',
                  },
                ],
              },
              {
                name: 'Kalabrien',
                href: '/yachtcharter/mittelmeer/kalabrien',
                subList: [
                  {
                    name: 'Marina',
                    href: '/yachtcharter/mittelmeer/kalabrien/marina',
                  },
                  {
                    name: 'Tropea',
                    href: '/yachtcharter/mittelmeer/kalabrien/tropea',
                  },
                  {
                    name: 'Vibo Marina',
                    href: '/yachtcharter/mittelmeer/kalabrien/vibo-marina',
                  },
                  {
                    name: 'Reggio Calabria',
                    href: '/yachtcharter/mittelmeer/kalabrien/reggio-calabria',
                  },
                ],
              },
              {
                name: 'Kampanien',
                href: '/yachtcharter/mittelmeer/kampanien',
                subList: [
                  {
                    name: 'Mergellina',
                    href: '/yachtcharter/mittelmeer/kampanien/mergellina',
                  },
                  {
                    name: 'Pozzuoli',
                    href: '/yachtcharter/mittelmeer/kampanien/pozzuoli',
                  },
                  {
                    name: 'Procida',
                    href: '/yachtcharter/mittelmeer/kampanien/procida',
                  },
                  {
                    name: 'Castellammare di Stabia',
                    href: '/yachtcharter/mittelmeer/kampanien/castellammare-di-stabia',
                  },
                  {
                    name: 'Agropoli',
                    href: '/yachtcharter/mittelmeer/kampanien/agropoli',
                  },
                  {
                    name: 'Amalfi',
                    href: '/yachtcharter/mittelmeer/kampanien/amalfi',
                  },
                  {
                    name: 'Porto di Castellammare di Stabia',
                    href: '/yachtcharter/mittelmeer/kampanien/porto-di-castellammare-di-stabia',
                  },
                  {
                    name: 'Salerno',
                    href: '/yachtcharter/mittelmeer/kampanien/salerno',
                  },
                  {
                    name: 'Napoli',
                    href: '/yachtcharter/mittelmeer/kampanien/napoli',
                  },
                  {
                    name: 'Naples',
                    href: '/yachtcharter/mittelmeer/kampanien/naples',
                  },
                  {
                    name: 'Neapel',
                    href: '/yachtcharter/mittelmeer/kampanien/neapel',
                  },
                ],
              },
              {
                name: 'Latium',
                href: '/yachtcharter/mittelmeer/latium',
                subList: [
                  {
                    name: 'Rome',
                    href: '/yachtcharter/mittelmeer/latium/rome',
                  },
                  {
                    name: 'Anzio',
                    href: '/yachtcharter/mittelmeer/latium/anzio',
                  },
                  {
                    name: 'Nettuno',
                    href: '/yachtcharter/mittelmeer/latium/nettuno',
                  },
                ],
              },
              {
                name: 'Ligurien',
                href: '/yachtcharter/mittelmeer/ligurien',
                subList: [
                  {
                    name: 'La Spezia',
                    href: '/yachtcharter/mittelmeer/ligurien/la-spezia',
                  },
                  {
                    name: 'Portovenere',
                    href: '/yachtcharter/mittelmeer/ligurien/portovenere',
                  },
                  {
                    name: 'Bocca di Magra',
                    href: '/yachtcharter/mittelmeer/ligurien/bocca-di-magra',
                  },
                ],
              },
              {
                name: 'Neapel',
                href: '/yachtcharter/mittelmeer/neapel',
                subList: [
                  {
                    name: 'Mergellina',
                    href: '/yachtcharter/mittelmeer/neapel/mergellina',
                  },
                  {
                    name: 'Pozzuoli',
                    href: '/yachtcharter/mittelmeer/neapel/pozzuoli',
                  },
                  {
                    name: 'Procida',
                    href: '/yachtcharter/mittelmeer/neapel/procida',
                  },
                  {
                    name: 'Napoli',
                    href: '/yachtcharter/mittelmeer/neapel/napoli',
                  },
                  {
                    name: 'Naples',
                    href: '/yachtcharter/mittelmeer/neapel/naples',
                  },
                  {
                    name: 'Neapel',
                    href: '/yachtcharter/mittelmeer/neapel/neapel',
                  },
                ],
              },
              {
                name: 'Sardinien',
                href: '/yachtcharter/mittelmeer/sardinien',
                subList: [
                  {
                    name: 'Cagliari',
                    href: '/yachtcharter/mittelmeer/sardinien/cagliari',
                  },
                  {
                    name: 'La Maddalena',
                    href: '/yachtcharter/mittelmeer/sardinien/la-maddalena',
                  },
                  {
                    name: 'Olbia',
                    href: '/yachtcharter/mittelmeer/sardinien/olbia',
                  },
                  {
                    name: 'Genova',
                    href: '/yachtcharter/mittelmeer/sardinien/genova',
                  },
                  {
                    name: 'Golfo Aranci',
                    href: '/yachtcharter/mittelmeer/sardinien/golfo-aranci',
                  },
                  {
                    name: 'Santa Maria Navarrese',
                    href: '/yachtcharter/mittelmeer/sardinien/santa-maria-navarrese',
                  },
                  {
                    name: 'Porto Rotondo',
                    href: '/yachtcharter/mittelmeer/sardinien/porto-rotondo',
                  },
                  {
                    name: 'Portisco',
                    href: '/yachtcharter/mittelmeer/sardinien/portisco',
                  },
                  {
                    name: 'Carloforte',
                    href: '/yachtcharter/mittelmeer/sardinien/carloforte',
                  },
                  {
                    name: 'Cannigione',
                    href: '/yachtcharter/mittelmeer/sardinien/cannigione',
                  },
                  {
                    name: 'Alghero',
                    href: '/yachtcharter/mittelmeer/sardinien/alghero',
                  },
                  {
                    name: 'Arzachena',
                    href: '/yachtcharter/mittelmeer/sardinien/arzachena',
                  },
                  {
                    name: 'Aranci',
                    href: '/yachtcharter/mittelmeer/sardinien/aranci',
                  },
                  {
                    name: 'Cannigione Arzachena',
                    href: '/yachtcharter/mittelmeer/sardinien/cannigione-arzachena',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Indischer Ozean',
        icon: GlobeAltIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Indischer Ozean',
            href: '/yachtcharter/indischer-ozean',
            list: [
              {
                name: 'Seychellen',
                href: '/yachtcharter/indischer-ozean/seychellen',
                subList: [
                  {
                    name: 'Baie Ste Anne',
                    href: '/yachtcharter/indischer-ozean/seychellen/baie-ste-anne',
                  },
                  {
                    name: 'Mahe, Victoria',
                    href: '/yachtcharter/indischer-ozean/seychellen/mahe-victoria',
                  },
                  {
                    name: 'Grand Anse Village',
                    href: '/yachtcharter/indischer-ozean/seychellen/grand-anse-village',
                  },
                  {
                    name: 'Mahe',
                    href: '/yachtcharter/indischer-ozean/seychellen/mahe',
                  },
                  {
                    name: 'Praslin',
                    href: '/yachtcharter/indischer-ozean/seychellen/praslin',
                  },
                ],
              },
              {
                name: 'Malaysia',
                href: '/yachtcharter/indischer-ozean/malaysia',
                subList: [
                  {
                    name: 'Nongsa Point Marina',
                    href: '/yachtcharter/indischer-ozean/malaysia/nongsa-point-marina',
                  },
                  {
                    name: 'ONE\u00b015 Marina Sentosa Cove',
                    href: '/yachtcharter/indischer-ozean/malaysia/one-15-marina-sentosa-cove',
                  },
                ],
              },
              {
                name: 'Thailand',
                href: '/yachtcharter/indischer-ozean/thailand',
                subList: [
                  {
                    name: 'Tambon Mai Khao',
                    href: '/yachtcharter/indischer-ozean/thailand/tambon-mai-khao',
                  },
                  {
                    name: 'Tambon Koh Chang Tai',
                    href: '/yachtcharter/indischer-ozean/thailand/tambon-koh-chang-tai',
                  },
                  {
                    name: 'Koh Chang',
                    href: '/yachtcharter/indischer-ozean/thailand/koh-chang',
                  },
                  {
                    name: 'Phuket',
                    href: '/yachtcharter/indischer-ozean/thailand/phuket',
                  },
                  {
                    name: 'Ko Samui',
                    href: '/yachtcharter/indischer-ozean/thailand/ko-samui',
                  },
                  {
                    name: 'Koh Samui',
                    href: '/yachtcharter/indischer-ozean/thailand/koh-samui',
                  },
                ],
              },
              {
                name: 'Phuket',
                href: '/yachtcharter/indischer-ozean/phuket',
                subList: [
                  {
                    name: 'Tambon Mai Khao',
                    href: '/yachtcharter/indischer-ozean/phuket/tambon-mai-khao',
                  },
                  {
                    name: 'Phuket',
                    href: '/yachtcharter/indischer-ozean/phuket/phuket',
                  },
                ],
              },
              {
                name: 'Ko Samui',
                href: '/yachtcharter/indischer-ozean/ko-samui',
                subList: [
                  {
                    name: 'Ko Samui',
                    href: '/yachtcharter/indischer-ozean/ko-samui/ko-samui',
                  },
                  {
                    name: 'Koh Samui',
                    href: '/yachtcharter/indischer-ozean/ko-samui/koh-samui',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Pazifischer Ozean',
        icon: GlobeAltIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Pazifischer Ozean',
            href: '/yachtcharter/pazifischer-ozean',
            list: [
            ],
          },
        ],
      },
      {
        name: 'Atlantischer Ozean',
        icon: GlobeAltIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Atlantischer Ozean',
            href: '/yachtcharter/atlantischer-ozean',
            list: [
              {
                name: 'Bretagne',
                href: '/yachtcharter/atlantischer-ozean/bretagne',
                subList: [
                  {
                    name: 'Melesse',
                    href: '/yachtcharter/atlantischer-ozean/bretagne/melesse',
                  },
                  {
                    name: 'Saint-Martin-sur-Oust',
                    href: '/yachtcharter/atlantischer-ozean/bretagne/saint-martin-sur-oust',
                  },
                  {
                    name: 'Gl\u00e9nac',
                    href: '/yachtcharter/atlantischer-ozean/bretagne/glenac',
                  },
                  {
                    name: 'Messac',
                    href: '/yachtcharter/atlantischer-ozean/bretagne/messac',
                  },
                  {
                    name: 'La Trinit\u00e9-sur-Mer',
                    href: '/yachtcharter/atlantischer-ozean/bretagne/la-trinite-sur-mer',
                  },
                  {
                    name: 'Redon',
                    href: '/yachtcharter/atlantischer-ozean/bretagne/redon',
                  },
                ],
              },
              {
                name: 'Nouvelle-Aquitanien',
                href: '/yachtcharter/atlantischer-ozean/nouvelle-aquitanien',
                subList: [
                  {
                    name: 'Agen',
                    href: '/yachtcharter/atlantischer-ozean/nouvelle-aquitanien/agen',
                  },
                  {
                    name: 'Buzet-sur-Baise',
                    href: '/yachtcharter/atlantischer-ozean/nouvelle-aquitanien/buzet-sur-baise',
                  },
                  {
                    name: 'Sireuil',
                    href: '/yachtcharter/atlantischer-ozean/nouvelle-aquitanien/sireuil',
                  },
                  {
                    name: "Le Mas d'Agenais",
                    href: '/yachtcharter/atlantischer-ozean/nouvelle-aquitanien/le-mas-dagenais',
                  },
                  {
                    name: 'Jarnac',
                    href: '/yachtcharter/atlantischer-ozean/nouvelle-aquitanien/jarnac',
                  },
                  {
                    name: 'La Rochelle',
                    href: '/yachtcharter/atlantischer-ozean/nouvelle-aquitanien/la-rochelle',
                  },
                  {
                    name: 'Cognac',
                    href: '/yachtcharter/atlantischer-ozean/nouvelle-aquitanien/cognac',
                  },
                  {
                    name: "Le Mas d'Agenais",
                    href: '/yachtcharter/atlantischer-ozean/nouvelle-aquitanien/le-mas-dagenais',
                  },
                ],
              },
              {
                name: 'Galicien',
                href: '/yachtcharter/atlantischer-ozean/galicien',
                subList: [
                  {
                    name: 'Pontevedra',
                    href: '/yachtcharter/atlantischer-ozean/galicien/pontevedra',
                  },
                ],
              },
              {
                name: 'Gran Canaria',
                href: '/yachtcharter/atlantischer-ozean/gran-canaria',
                subList: [
                  {
                    name: 'San Bartolom\u00e9 de Tirajana',
                    href: '/yachtcharter/atlantischer-ozean/gran-canaria/san-bartolome-de-tirajana',
                  },
                  {
                    name: 'Lomo Quiebre',
                    href: '/yachtcharter/atlantischer-ozean/gran-canaria/lomo-quiebre',
                  },
                  {
                    name: 'Gran Canaria',
                    href: '/yachtcharter/atlantischer-ozean/gran-canaria/gran-canaria',
                  },
                ],
              },
              {
                name: 'Lanzarote',
                href: '/yachtcharter/atlantischer-ozean/lanzarote',
                subList: [
                  {
                    name: 'Arrecife',
                    href: '/yachtcharter/atlantischer-ozean/lanzarote/arrecife',
                  },
                  {
                    name: 'Lanzarote',
                    href: '/yachtcharter/atlantischer-ozean/lanzarote/lanzarote',
                  },
                ],
              },
              {
                name: 'Alentejo - Grande Lago',
                href: '/yachtcharter/atlantischer-ozean/alentejo-grande-lago',
                subList: [
                  {
                    name: 'Amieira',
                    href: '/yachtcharter/atlantischer-ozean/alentejo-grande-lago/amieira',
                  },
                ],
              },
              {
                name: 'Algarve',
                href: '/yachtcharter/atlantischer-ozean/algarve',
                subList: [
                  {
                    name: 'Portimao',
                    href: '/yachtcharter/atlantischer-ozean/algarve/portimao',
                  },
                  {
                    name: 'Ayamonte',
                    href: '/yachtcharter/atlantischer-ozean/algarve/ayamonte',
                  },
                ],
              },
              {
                name: 'Lissabon',
                href: '/yachtcharter/atlantischer-ozean/lissabon',
                subList: [
                  {
                    name: 'Lissabon',
                    href: '/yachtcharter/atlantischer-ozean/lissabon/lissabon',
                  },
                  {
                    name: 'Cascais',
                    href: '/yachtcharter/atlantischer-ozean/lissabon/cascais',
                  },
                ],
              },
              {
                name: 'Nordportugal / Porto',
                href: '/yachtcharter/atlantischer-ozean/nordportugal-porto',
                subList: [
                  {
                    name: 'Freixo',
                    href: '/yachtcharter/atlantischer-ozean/nordportugal-porto/freixo',
                  },
                  {
                    name: 'Vila Nova Gaia',
                    href: '/yachtcharter/atlantischer-ozean/nordportugal-porto/vila-nova-gaia',
                  },
                ],
              },
              {
                name: 'Maryland',
                href: '/yachtcharter/atlantischer-ozean/maryland',
                subList: [
                  {
                    name: 'Annapolis',
                    href: '/yachtcharter/atlantischer-ozean/maryland/annapolis',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Saronischer Golf',
        icon: GlobeAltIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        children: [
          {
            name: 'Saronischer Golf',
            href: '/yachtcharter/saronischer-golf',
            list: [],
          },
        ],
      },
    ],
  },
  {
    name: 'Informationen',
    href: '/',
    icon: BlogIcon,
    list: [
      {
        name: 'Planung vor der Reise',
        icon: LicenseIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        href: '/wissenswertes/planung-vor-der-reise',
      },
      {
        name: 'Übernahme des Bootes',
        icon: ChildrenIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        href: '/wissenswertes/uebernahme-des-bootes',
      },
      {
        name: 'Manövrieren des Bootes',
        icon: DogIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        href: '/wissenswertes/manoevrieren',
      },
      {
        name: 'Vorfahrts- & Navigationsregeln',
        icon: BoatIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        href: '/wissenswertes/vorfahrts-und-navigationsregeln',
      },
      {
        name: 'Zusammenleben an Bord',
        icon: InspoIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        href: '/wissenswertes/zusammenleben-an-bord',
      },
      {
        name: 'Rückgabe',
        icon: RouteIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        href: '/wissenswertes/rueckgabe',
      },
      {
        name: 'Sicherheit & Unfallverhalten',
        icon: PartnerIcon,
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
        href: '/wissenswertes/sicherheit-unfall',
      },
      /*
      {
        name: 'Charterrücktritt-Versicherung',
        icon: NewspaperIcon,
        href: '/wissenswertes/charterruecktritt-versicherung-plus-von-yacht-pool',
        mainClass: 'border-b border-gray-50 border-opacity-20 text-lg lg:text-lg font-light py-2',
      },

       */
      {
        name: 'Blog',
        icon: NewspaperIcon,
        href: '/blog',
        mainClass: 'text-sm lg:text-base font-semibold py-3 lg:py-2  mt-7',
      },
      {
        name: 'Shop',
        icon: ShoppingCartIcon,
        href: `${appConfig.planbar24_url}/shop`,
        mainClass: 'text-sm lg:text-base font-semibold py-3 lg:py-2',

      },
      {
        name: 'Häufige Fragen',
        icon: QuestionMarkCircleIcon,
        mainClass: 'text-sm lg:text-base font-semibold py-3 lg:py-2',
        children: faqs,
      },
      {
        name: 'Newsletter',
        icon: MailIcon,
        href: '/newsletter',
        mainClass: 'text-sm lg:text-base font-semibold py-3 lg:py-2',
      },
      {
        name: 'Über uns',
        icon: Favicon,
        href: '/ueber-uns',
        mainClass: 'text-sm lg:text-base font-semibold py-3 lg:py-2',
      },
    ],
  },
];

export default HeaderData;
