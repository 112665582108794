const config = {
  phone: ' +49 (0)89 80990788-0',
  opening: 'Mo-Fr. 9-12 Uhr und 14-18 Uhr',
  endpoint: 'https://assets.5-anker.com',
  planbar24_url: 'https://portal.chartercheck.com',
  channel: 'cc',
  primarySearchPage: '/yachtcharter',
  social: {
    facebook: 'https://www.facebook.com/chartercheckcom',
  },
  // ids of multinational regions like Mittelmeer, Nordsee and unsupported countries like russia etc..
  regionIdsToHide: [],
};

export default config;
