import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import Image from 'next/image';

import { useContext, useEffect, useState } from 'react';
import { DispatchStateContext, GlobalStateContext } from '@/lib/app-server-context';
import dynamic from 'next/dynamic';

import Newsletter from '@/components/globals/newsletter';
import appConfig from '../../../app.config';
import Questions from '../home/questions';

import Link from '../helper/link';
import Payments from '../../../public/assets/images/payment-methods.svg';

const PartnerMap = dynamic(() => import('@/components/globals/partner-map.client'), { ssr: false });

export default function Footer() {
  const useGlobalState = () => [
    useContext(GlobalStateContext),
    useContext(DispatchStateContext),
  ];

  const [state, dispatch] = useGlobalState();
  const [showPartnerMap, setShowPartnerMap] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowPartnerMap(state.open);
    }, state.open ? 50 : 200);
  }, [state]);

  const navigate = [
    {
      title: 'Interessante Links',
      list: [
        { name: 'Planung vor der Reise', href: '/wissenswertes/planung-vor-der-reise' },
        { name: 'Sicherheit & Unfallverhalten', href: '/wissenswertes/sicherheit-unfall' },
        /*
        {
          name: 'Charterrücktritt-Versicherung',
          href: '/wissenswertes/charterruecktritt-versicherung-plus-von-yacht-pool',
        },

         */
      ],
    },
    {
      title: 'Beliebte Fahrgebiete',
      list: [
        { name: 'Yachtcharter Kroatien', href: '/yachtcharter/kroatien' },
        { name: 'Yachtcharter Karibik', href: '/yachtcharter/karibik' },
        { name: 'Yachtcharter Ostsee', href: '/yachtcharter/deutschland/ostsee-und-peene' },
        { name: 'Yachtcharter Mallorca', href: '/yachtcharter/spanien/mallorca' },
        { name: 'Yachtcharter Griechenland', href: '/yachtcharter/griechenland' },
      ],
    },
  ];
  const navigation = {
    legal: [
      { name: 'Impressum', href: '/impressum' },
      { name: 'Allgemeine Geschäftsbedingungen', href: '/agb' },
      { name: 'Datenschutzerklärung', href: '/datenschutzerklaerung' },
    ],
    social: [
      {
        name: 'Facebook',
        href: appConfig.social.facebook,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  };

  return (
    <div className="mt-10">
      <Questions />
      <footer>
        <div className="bg-primary shadow lg:pt-12">
          <div className="max-w-7xl mx-auto py-10 px-6 xl:px-0 text-white">
            {navigate.map((v, k) => (
              <Disclosure
                as="div"
                className="focus:outline-none lg:hidden"
                key={v.title + k}
              >
                {({ open }) => (
                  <div className="border-b border-gray-100 sm:bg-white focus:outline-none">
                    <Disclosure.Button
                      className="focus:outline-none group relative w-full py-4 flex justify-between items-center text-left"
                    >
                      {v.title ? (
                        <span
                          className="lg:text-2xl md:text-xl text-base"
                        >
                          {v.title}
                        </span>
                      ) : (
                        <v.icon
                          className="lg:w-9 md:w-7 w-6"
                        />
                      )}
                      <span className="ml-6 flex items-center">
                        <ChevronDownIcon
                          className={
                          `${open ? '-rotate-180' : 'rotate-0'
                          } h-5 w-5 transform transition-all text-white sm:text-primary`
                        }
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                    <Disclosure.Panel
                      as="div"
                      className="pb-6 md:pr-10 pr-5 text-sm md:text-base lg:font-light  transform transition-all"
                    >
                      <ul role="list" className="mt-2 space-y-6">
                        {v.list.map((item, k2) => (
                          <li key={item.name + k2}>
                            {item.showAdac ? (
                              <a
                                onClick={() => (item.showAdac ? dispatch({ open: true }) : '')}
                                className="md:text-base text-sm text-white sm:text-primary hover:underline"
                              >
                                {item.name}
                              </a>
                            ) : (
                              <Link href={item.href}>
                                <a
                                  className="md:text-base text-sm text-white sm:text-primary hover:underline"
                                >
                                  {item.name}
                                </a>
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))}
            <div className="lg:grid md:grid-cols-4 md:gap-8 hidden">
              {navigate.map((v, k3) => (
                <div key={v.title + k3}>
                  {v.title ? (
                    <div className="text-xl font-semibold uppercase">
                      {v.title}
                    </div>
                  ) : (
                    <v.icon className="lg:w-10 w-7 fill-primary" />
                  )}

                  <ul role="list" className="mt-6 space-y-2">
                    {v.list.map((item, k4) => (
                      <li key={item.name + k4}>
                        {item.showAdac ? (
                          <a
                            onClick={() => (item.showAdac ? dispatch({ open: true }) : '')}
                            className="text-base hover:underline"
                          >
                            {item.name}
                          </a>
                        ) : (
                          <Link href={item.href}>
                            <a
                              className="text-base hover:underline"
                            >
                              {item.name}
                            </a>
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                  {v.social ? (
                    <div className="flex space-x-6 mt-6">
                      {navigation.social.map((item, k5) => (
                        <a
                          key={item.name + k5}
                          href={item.href}
                          target="_blank"
                          className="text-primary"
                          rel="noreferrer"
                        >
                          <span className="sr-only">{item.name}</span>
                          <item.icon className="h-6" />
                        </a>
                      ))}
                    </div>
                  ) : ''}
                </div>
              ))}
              <div className="col-span-2">
                <Newsletter />
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto sm:py-10 px-6 xl:px-0">
          <div
            className="mt-10 md:flex md:items-center md:justify-between"
          >
            <div className="grid lg:grid-cols-7 gap-y-8 sm:gap-2 lg:gap-10 w-full">
              <div className="lg:col-span-3">
                <p className="">Bezahlmethoden</p>
                <div className="flex gap-4 py-4">
                  <Payments className="w-full md:w-auto md:max-h-16" />
                </div>
                <div className="space-y-1">
                  <ul
                    role="list"
                    className="space-y-0 md:space-x-3 lg:space-x-4 md:flex"
                  >
                    {navigation.legal.map((item, k7) => (
                      <li key={item.name + k7}>
                        <Link
                          href={item.href}
                        >
                          <a
                            className="text-sm hover:underline text-primary"
                          >
                            {item.name}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="lg:col-span-2">
                <p className="">In Kooperation mit</p>
                <div
                  className="grid grid-cols-3 gap-4 md:gap-10 lg:gap-4 py-4"
                >
                  <div className="relative">
                    <a href="https://skipper.adac.de" target="_blank" rel="noreferrer">
                      <Image
                        alt="ADAC Partner"
                        title="ADAC Partner"
                        src="/images/adac.svg"
                        layout="responsive"
                        objectFit="contain"
                        height={300}
                        width={300}
                      />
                    </a>
                  </div>
                  <div className="relative">
                    <a href="https://www.5-anker.com" target="_blank" rel="noreferrer">
                      <Image
                        alt="5 Anker"
                        title="5 Anker"
                        src="/images/5anker.svg"
                        layout="responsive"
                        objectFit="contain"
                        height={300}
                        width={300}
                      />
                    </a>
                  </div>
                  <div className="relative">
                    <a href="https://www.yacht-pool.de" target="_blank" rel="noreferrer">
                      <Image
                        alt="Checked & Trusted"
                        title="Checked & Trusted"
                        src="/assets/images/yacht-pool-trust-badge.png"
                        layout="responsive"
                        objectFit="contain"
                        height={300}
                        width={300}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-2">
                <div className="flex space-x-6 sm:mt-14 text-right justify-end">
                  {navigation.social.map((item, k6) => (
                    <a
                      key={item.name + k6}
                      href={item.href}
                      target="_blank"
                      className="text-primary"
                      rel="noreferrer"
                    >
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-8" />
                    </a>
                  ))}
                </div>
                <p className="mt-5 mb-5 sm:mb-0 text-sm md:pt-0 text-right">
                  Copyright © 2008-
                  {new Date().getFullYear()}
                  {' '}
                  CharterCheck GmbH
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {state.open && (
        <PartnerMap open={showPartnerMap} setOpen={() => dispatch({ open: false })} />
      )}
    </div>
  );
}
