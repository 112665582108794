import { Popover, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import CollapsableLocation from '@/components/globals/header/collapsable-location';
import Link from '../../helper/link';

function accentFold(inStr) {
  return inStr.replace(
    /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
    (str, a, c, e, i, n, o, s, u, y, ae) => {
      if (a) return 'a';
      if (c) return 'c';
      if (e) return 'e';
      if (i) return 'i';
      if (n) return 'n';
      if (o) return 'o';
      if (s) return 's';
      if (u) return 'u';
      if (y) return 'y';
      if (ae) return 'ae';
      return '';
    },
  );
}

const contains = (needle, haystack) => accentFold(`${haystack}`).toLowerCase().indexOf(accentFold(`${needle}`).toLowerCase()) !== -1;

export default function HeaderSidebar({ data, click, closeParent }) {
  const [nav, setNav] = useState(data);
  const [menu, setMenu] = useState(data.list);
  const { list } = data;

  useEffect(() => {
    setNav(data);
    setMenu(data.list);
  }, [data]);

  const search = (e) => {
    const nav = [].concat(...list).slice();
    const term = `${e.target.value}`;

    const x = [].concat(...list.slice()).slice().filter((i) => !i.header).map((i1) => {
      const children = i1.children?.map((i2) => {
        const list = i2.list?.map((i3) => ({
          ...i3,
          collapsed: false,
          subList: i3.subList?.filter((d) => !term || contains(term, d.name)),
        }));

        return {
          ...i2,
          collapsed: false,
          list: list?.filter((c) => !term || c.subList?.length > 0 || contains(term, c.name)),
        };
      });

      return {
        ...i1,
        collapsed: false,
        children: children?.filter((b) => !term || b.list?.length > 0 || contains(term, b.name)),
      };
    })
      .filter((a) => !term || a.children?.length > 0 || contains(term, a.name));

    setMenu(term ? x : nav);
  };

  return (
    <>
      <div className="relative">
        <SearchIcon className="absolute left-2 top-2.5 h-5 text-primary text-opacity-70 " />
        <input
          className="bg-white bg-opacity-20 px-3 py-2 pl-10 -ml-1 mb-3 w-full placeholder:text-action-50 placeholder:text-opacity-40 focus:ring-1 focus:ring-primary focus:ring-opacity-25"
          placeholder={`"${nav.name}" durchsuchen`}
          onKeyUp={search}
        />
      </div>
      {menu.map((item) => (
        <div key={item.name}>
          {!item.children ? (
            <div className={item.mainClass}>
              {item.header ? (
                <div className="uppercase font-semibold text-xs text-white mt-5">{item.name}</div>
              ) : (
                <a
                  onClick={() => click}
                  href={item.href}
                  className="text-action-50 group cursor-pointer w-full flex items-center hover:underline"
                >
                  <div className="w-10">
                    {item.icon !== ''
                      && (
                        <item.icon
                          className="text-action-50 mr-3 flex-shrink-0 h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                  </div>
                  {item.name}
                </a>
              )}
            </div>
          ) : (
            <Popover as="div">
              <>
                <Popover.Button
                  className={`${item.mainClass} text-action-50 group w-full flex items-center hover:underline`}
                >
                  <div className="w-10">
                    {item.icon !== ''
                      && (
                        <item.icon
                          className="text-action-50 mr-3 flex-shrink-0 h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                  </div>
                  <span className="flex-1 text-left">
                    {item.name}
                  </span>
                  <ChevronRightIcon
                    className="text-action-50 flex-shrink-0 h-6 w-6"
                    aria-hidden="true"
                  />
                </Popover.Button>
                <Transition
                  enter="transition duration-200 ease-out"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition duration-100 ease-in"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Popover.Panel
                    className="bg-white absolute top-12 md:top-12 lg:top-18 left-2.5 md:left-full lg:left-[470px] w-full md:w-[407px] lg:w-full md:right-0 h-screen"
                  >
                    {({ close }) => (
                      <div className="px-6 py-8 lg:px-16 lg:pb-26 pb-18 overflow-y-auto h-full">
                        <Popover.Button
                          className="w-full flex items-center space-x-2 mb-6 text-sm uppercase font-semibold text-primary"
                        >
                          <ChevronRightIcon
                            className="h-6 w-6 text-primary -ml-2 transform rotate-180"
                            aria-hidden="true"
                          />
                          <span>Zurück</span>
                        </Popover.Button>
                        {item.children.map((subItem) => (
                          <Popover.Button
                            className="w-full"
                            key={subItem.name}
                          >
                            {subItem.name ? (
                              <Link href={subItem.href}>
                                <a
                                  className="text-primary hover:underline w-full flex items-center justify-between text-lg font-medium py-3"
                                  onClick={() => {
                                    close();
                                    closeParent();
                                  }}
                                >
                                  <span>{subItem.name}</span>
                                  <ChevronRightIcon
                                    className="flex-shrink-0 h-6 w-6 text-primary"
                                    aria-hidden="true"
                                  />
                                </a>
                              </Link>
                            ) : ''}
                            {subItem?.list?.map((v, k) => (
                              <CollapsableLocation
                                collapsed={v.collapsed ?? true}
                                element={v}
                                close={close}
                                closeParent={closeParent}
                                key={k}
                              />
                            ))}
                          </Popover.Button>
                        ))}
                      </div>
                    )}
                  </Popover.Panel>
                </Transition>
              </>
            </Popover>
          )}

        </div>
      ))}
    </>

  );
}
