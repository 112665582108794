import Input from '@/components/elements/input';
import Button from '@/components/elements/button';
import { useForm } from 'react-hook-form';
import { fetchAPIPost } from '@/lib/connect';
import { useState } from 'react';
import Link from '@/components/helper/link';

export default function Newsletter() {
  const [showMessage, setShowMessage] = useState(false);
  const {
    register, handleSubmit, reset, formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    fetchAPIPost('/newsletter', { email: data.email })
      .then(() => {
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
          reset();
        }, 3000);
      });
  };

  return (
    <>
      <div className="text-xl font-semibold uppercase mb-7">Newsletter abonnieren</div>
      <p className="md:text-sm text-xs font-light">
        Abonnieren Sie unseren Newsletter, um keine unserer besten
        Angebote, neuesten Nachrichten, Artikel und vieles mehr zu verpassen.
      </p>
      <form className="mt-4 divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center space-x-3">
          <Input
            label="E-Mail Adresse"
            type="email"
            name="email"
            id="email"
            autocomplete="email"
            required=""
            placeholder="E-Mail Adresse"
            error={errors?.email}
            register={{ ...register('email', { required: true, pattern: /^\S+@\S+$/i }) }}
          />
          <Button type="submit">abonnieren</Button>
        </div>
        <div>
          <input
            type="checkbox"
            id="nl-footer-privacy"
            placeholder="agb"
            {...register('agb', { required: true })}
          />
          <label
            htmlFor="nl-footer-privacy"
            className={`ml-2 text-sm ${errors?.agb ? 'text-red-600' : ''}`}
          >
            Ich stimme den
            {' '}
            <Link href="/datenschutz">
              <a className="underline">Datenschutzbedingungen</a>
            </Link>
            {' '}
            zu
          </label>
        </div>
        {showMessage && (
          <div className="mt-2 p-3 bg-primary text-white">
            Sie haben sich erfolgreich für unseren Newsletter angemeldet. Danke für die Registrierung.
          </div>
        )}
      </form>
    </>
  );
}
