export default function Input({
  label, type, id, placeholder, register, error, name, mainClass = '',
}) {
  return (
    <div
      className={`${mainClass} relative bg-gray-100 border border-gray-400 group w-full transition-all overflow-hidden`}
    >
      <input
        {...register}
        type={type}
        className={`input-text z-[3] relative block w-full px-2 py-1 pt-5 bg-transparent border-b-2 border-transparent group-hover:border-secondary focus:outline-none focus:border-primary ring-0 ${error ? 'border-red-600s' : ''}`}
        id={id}
        placeholder={placeholder}
        name={name}
      />
      <label
        className={`absolute top-1 z-10 block w-full px-2 text-xs font-bold ${error ? 'text-red-600' : 'text-primary '}`}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
}
