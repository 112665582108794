import { useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import HeaderNavMenu from './header/nav-menu';
import HeaderSidebar from './header/sidebar';
import Link from '../helper/link';
import HeaderData from './header/data';
import config from '../../../app.config';

import LogoInverted from '../../../public/assets/images/logo-inverted.svg';
import PhoneIcon from '../../../public/assets/icons/f_phone2.svg';
import UserIcon from '../../../public/assets/icons/user.svg';

export default function Header() {
  const [menu, setMenu] = useState(false);
  const [openTab, setOpenTab] = useState(HeaderData[0]);

  const router = useRouter();

  const openMenu = () => {
    setMenu(!menu);
  };
  const toggleTabs = (tabNumber) => {
    setOpenTab(tabNumber);
  };

  // const exp = useExperiment('pfM453XPRJq2AzrB3YGa4Q');

  const { phone } = config;

  function PhoneBlock() {
    return (<span>{config.phone}</span>);
  }

  return (
    <div>
      <Popover className="fixed top-0 left-0 right-0 w-full z-[10000] bg-primary">
        {({ open }) => (
          <>
            <div
              aria-hidden="true"
              className="absolute inset-0 shadow-sm bg-primary-600 text-white z-50 pointer-events-none"
            />
            <div className="relative z-50">
              <div
                className="max-w-7xl mx-auto flex justify-between items-center px-6 md:py-0 py-2 xl:px-0 md:justify-start md:space-x-5 lg:space-x-8"
              >
                <div className="flex items-center space-x-4">
                  <Link className="flex" href="/">
                    <a>
                      <span className="sr-only">Chartercheck</span>
                      <LogoInverted alt="Chartercheck Logo" className="h-8 w-auto md:h-9 lg:h-10" />
                    </a>
                  </Link>
                  <div className="border-l border-gray-100 pl-2">
                    {open ? (
                      <button
                        className=" rounded-md lg:p-2 p-1 inline-flex items-center justify-center text-white focus:outline-none"
                      >
                        {!open ? <MenuIcon aria-hidden="true" className="h-6 w-6" />
                          : <XIcon aria-hidden="true" className="h-6 w-6" />}
                      </button>
                    ) : (
                      <Popover.Button
                        className=" rounded-md lg:p-2 p-1 inline-flex items-center justify-center text-white focus:outline-none"
                      >
                        {!open ? <MenuIcon aria-hidden="true" className="h-6 w-6" />
                          : <XIcon aria-hidden="true" className="h-6 w-6" />}
                      </Popover.Button>
                    )}
                  </div>
                </div>
                <div
                  className="md:flex-1 md:flex md:items-center md:justify-between md:pl-5 lg:pl-0"
                >
                  <HeaderNavMenu open={(v) => toggleTabs(v)} />
                  {/* <LocaleSwitch /> */}
                  <div
                    className="flex items-center lg:space-x-2 bg-primary-500 text-white px-4 md:px-3 pl-2 md:pl-3 py-2 -mt-4 md:mt-0 -mb-3 md:-mb-2"
                  >
                    <div
                      className="hidden lg:block cursor-pointer order-gray-200 -mt-3"
                    >
                      <PhoneIcon aria-hidden="true" className="h-12 w-12 fill-white" />
                    </div>
                    <a
                      href={`tel:${phone}`}
                    >
                      <div
                        className="text-right hidden lg:block border-gray-200 border-opacity-50 border-r pr-4 cursor-pointer"
                      >
                        <p className="font-light">
                          Mo-Fr. 9-12 Uhr und 14-18 Uhr
                        </p>
                        <div className="text-white">
                          <strong className="text-xl">
                            <PhoneBlock />
                          </strong>
                        </div>
                      </div>
                    </a>
                    <div className="cursor-pointer lg:hidden">
                      <SearchIcon aria-hidden="true" className="h-7 w-7 mx-2 text-white" />
                    </div>
                    <a
                      className="cursor-pointer border-l border-gray-100 lg:border-l-0 py-1.5 pl-3 lg:p-0"
                      href={config.planbar24_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <UserIcon aria-hidden="true" className="h-7 w-7 lg:h-8 lg:w-8 text-white" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <Transition
              enter="transition duration-200 ease-out"
              enterFrom="opacity-0 "
              enterTo="opacity-100 "
              leave="transition duration-100 ease-in"
              leaveFrom="opacity-100 "
              leaveTo="opacity-0 "
            >
              <div
                aria-hidden="true"
                className="absolute left-0 top-0 top-header h-screen w-full z-[30] bg-black bg-opacity-50"
              />
              <Popover.Panel className="absolute top-0 left-0 w-full h-screen">
                {({ close }) => (
                  <div
                    className="relative w-full lg:w-[470px] md:w-90 z-[40] inset-x-0"
                  >
                    <div
                      className="bg-primary-500 h-screen overflow-y-auto p-6 pb-10 lg:px-14 lg:pt-24 pt-18 flex flex-col justify-between"
                    >
                      <div className="sm:pb-8 text-left">
                        <ul className="flex pt-1 list-none flex-wrap pb-7 space-x-4">
                          {HeaderData.map((h) => (
                            <li key={h.name}>
                              <a
                                className={`${openTab !== h ? 'text-gray-200' : 'border-b border-action border-opacity-30 text-action-50 font-semibold'} text-base lg:text-lg cursor-pointer leading-normal pb-2 px-0.5`}
                                onClick={() => toggleTabs(h)}
                              >
                                {h.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <div>
                          <HeaderSidebar click={openMenu} data={openTab} closeParent={close} />
                        </div>
                      </div>
                      <div
                        className="text-primary font-light mt-auto hidden"
                      >
                        <h3 className="text-xs">Sprache:</h3>
                        <ul className="flex items-center space-x-2 pt-2">

                          {router.locales.map((i, k) => (
                            <li key={i}>
                              <Link href="/" locale={i} activeClassName="text-white font-semibold">
                                <a className="text-base hover:underline transition-all cursor-pointer">
                                  {i.toUpperCase()}
                                </a>
                              </Link>
                              {k < router.locales.length - 1 ? (<span className="ml-2">|</span>) : ''}
                            </li>
                          ))}

                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <style jsx>
        {`
a.router-link-exact-active {
  @apply font-semibold border-primary !important;
}
  `}
      </style>
    </div>
  );
}
