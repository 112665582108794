import Button from '@/components/elements/button';
import { ChevronRightIcon, MailIcon, PhoneIcon } from '@heroicons/react/outline';
import ContentCta from '../content/cta';
import appConfig from '../../../app.config';

export default function Questions() {
  return (
    <ContentCta
      title="Sie wünschen ein Individuelles Angebot oder möchten sich telefonisch beraten lassen?"
      content={(
        <div>
          <div className="prose">
            <p className="font-semibold">Unsere Experten und Serviceberater stehen Ihnen zur Verfügung!</p>
            <p>
              Über unser Kontaktformular können Sie sich zu jeder Zeit ein individuelles Angebot für Ihre nächste Reise
              anfordern. Senden Sie uns unkompliziert alle wichtigen Angaben zu Ihrer Planung (Zeitraum, Personen,
              Fahrgebiet usw.) damit wir Ihnen ein maßgeschneidertes Angebot zusenden können.
            </p>
            <p>
              Natürlich steht Ihnen auch unsere freundlichen Kundenberater*innen des Serviceteams - für alle Fragen zur
              Yachtvermietung (Montag bis Freitag, jeweils zwischen 09:00 Uhr und 12:00 Uhr sowie 14:00 Uhr und 18:00 Uhr) zur Verfügung. Bei
              CharterCheck gelten übrigens die gesetzlichen Feiertage des Bundeslandes Bayern.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-5">
            <Button
              size="sm"
              href={`tel:${appConfig.phone}`}
            >
              <div className="flex items-center">
                <PhoneIcon className="h-6 text-primary mr-2" />
                <span>
                  {appConfig.phone}
                  {' '}
                  *
                </span>
                <ChevronRightIcon className="w-5 text-primary" />
              </div>
            </Button>
            <Button
              size="sm"
              href="/kontakt"
            >
              <div className="flex items-center">
                <MailIcon className="h-6 text-primary mr-2" />
                <span> Kontaktformular</span>
                <ChevronRightIcon className="w-5 text-primary" />
              </div>
            </Button>
          </div>
        </div>
      )}
    />
  );
}
