import Image from 'next/image';
import ContentHeadline from './headline';

export default function ContentCta({
  title,
  content,
  children,
  right = false,
}) {
  return (

    <div className="lg:py-0 relative overflow-hidden bg-gray-100">
      <div
        className="relative z-30 flex items-center"
      >
        <div
          className={`max-w-7xl mx-auto w-full px-6 xl:px-0 flex ${right ? 'justify-end' : 'justify-start'} h-full py-10`}
        >
          <div
            className="bg-white md:p-10 p-4 shadow-sm md:px-16 max-w-3xl"
          >
            <ContentHeadline tag="div">{title}</ContentHeadline>

            <div
              className="border-t border-secondary pt-4 pb-3"

            >
              {content}
            </div>
            {children}
          </div>
        </div>
      </div>

      <div
        className="hidden lg:grid grid-cols-2 items-end py-10 w-full h-full z-20 lg:absolute w-full h-full top-0 left-0 items-center"
      >
        <div className={`${right ? 'order-last justify-end' : 'items-end'} lg:flex hidden`}>
          <svg
            width="272px"
            height="176px"
            viewBox="0 0 272 176"
            className="fill-action"
          >
            <circle cx="16" cy="16" r="16" />
            <circle cx="16" cy="64" r="16" />
            <circle cx="16" cy="112" r="16" />
            <circle cx="16" cy="160" r="16" />
            <circle cx="112" cy="16" r="16" />
            <circle cx="112" cy="64" r="16" />
            <circle cx="112" cy="112" r="16" />
            <circle cx="112" cy="160" r="16" />
            <circle cx="208" cy="16" r="16" />
            <circle cx="208" cy="64" r="16" />
            <circle cx="208" cy="112" r="16" />
            <circle cx="208" cy="160" r="16" />
            <circle cx="64" cy="16" r="16" />
            <circle cx="64" cy="64" r="16" />
            <circle cx="64" cy="112" r="16" />
            <circle cx="64" cy="160" r="16" />
            <circle cx="160" cy="16" r="16" />
            <circle cx="160" cy="64" r="16" />
            <circle cx="160" cy="112" r="16" />
            <circle cx="160" cy="160" r="16" />
            <circle cx="256" cy="16" r="16" />
            <circle cx="256" cy="64" r="16" />
            <circle cx="256" cy="112" r="16" />
            <circle cx="256" cy="160" r="16" />
          </svg>

        </div>
        <div className={`${right ? 'justify-end' : ''} flex`}>
          <Image
            title="Sie haben Fragen?"
            alt="Notizzettel"
            height={400}
            width={600}
            objectFit="cover"
            src="/images/notice.png"
          />
        </div>
      </div>
    </div>
  );
}
