/**
 * Get full Strapi URL from path
 * @param {string} path Path of the URL
 * @returns {string} Full Strapi URL
 */

export function getConnectUrl(path = '') {
  return `${
    process.env.NEXT_PUBLIC_CONNECT_API_URL || 'https://demo.connect.demo.5-anker.com'
  }${path}`;
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchAPI(path, urlParamsObject = {}, options = {}) {
  const cid = ''; // @todo look at WLS
  // Merge default and user options
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'de-DE',
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONNECT_API_TOKEN}`,
      'Client-Id': cid,

    },
    ...options,
  };

  // Build request URL
  const queryString = new URLSearchParams(urlParamsObject);
  const requestUrl = `${getConnectUrl(
    `/dnet/com${path}${queryString ? `?${queryString}` : ''}`,
  )}`;
  console.log('call', requestUrl);

  // Trigger API call
  const response = await fetch(requestUrl, mergedOptions);

  // Handle response
  if (!response.ok) {
    console.error(response.statusText);
    return null;
  }

  return response.json();
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object }body POST body content
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchAPIPost(path, body, urlParamsObject = {}, options = {}) {
  const cid = ''; // @todo look at WLS
  // Merge default and user options
  const mergedOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONNECT_API_TOKEN}`,
      'Client-Id': cid,
      Accept: 'application/json',
      // 'Access-Control-Allow-Origin': 'http://localhost:3869',

    },
    body: JSON.stringify(body),
    ...options,
  };

  // Build request URL
  const queryString = new URLSearchParams(urlParamsObject);
  const requestUrl = `${getConnectUrl(
    `/dnet/com${path}${queryString ? `?${queryString}` : ''}`,
  )}`;

  // Trigger API call
  const response = await fetch(requestUrl, mergedOptions);
  const json = await response.json();
  if (!response.ok) {
    return Promise.reject(json);
  }

  return json;
}
