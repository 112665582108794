import slugify from '../../utils/slugify';

export default function ContentHeadline({
  tag = '2',
  after = '',
  before = '',
  children,
  className = '',
  id = '',
  line = true,
  ...props
}) {
  const aTag = tag || '2';
  const ElementTag = /^\d*$/.test(aTag) ? `h${aTag}` : aTag;
  const generatedId = id || `toc-g-${slugify(children.toString())}`;

  return (
    <div className="pb-4" id={generatedId}>
      <ElementTag
        className={`text-primary inline ${className}`}
        {...props}
      >
        {before && (
          <span className="font-serif text-4xl md:text-5xl pr-2 relative leading-1">
            {before}
            &nbsp;
          </span>
        )}
        <span className="text-xl md:text-2xl font-semibold uppercase tracking-tight">
          {children}
        </span>
        {after && (
          <span className="font-serif text-4xl md:text-5xl pl-1 relative leading-1">
            &nbsp;
            {after}
          </span>
        )}
      </ElementTag>
      {line && (
        <div className="h-[2px] mt-2 ml-10 w-30 bg-action" />
      )}
    </div>
  );
}
