import { Popover } from '@headlessui/react';

import HeaderData from './data';

export default function HeaderNavMenu({ open }) {
  const menuList = HeaderData;

  return (
    <div className="hidden md:flex items-center lg:space-x-10 space-x-8">
      {menuList.map((v, k) => (
        <Popover.Button
          key={v.name}
          className="dropdown group inline text-base cursor-pointer px-1 lg:py-2 py-2 text-white transition-all ring:0"
          onClick={() => open(v, k)}
        >
          <div
            className="flex items-center space-x-2 transition-all group-hover:font-semibold py-1.5 border-b border-transparent hover:border-primary"
          >
            <div className="lg:w-6 w-4">
              <v.icon
                aria-hidden="true"
                className="flex-shrink-0 lg:h-5 lg:w-5 h-4 w-4 text-white fill-white"
              />
            </div>
            <span>{v.name}</span>
          </div>
        </Popover.Button>
      ))}
    </div>

  );
}
