import Link from '../helper/link';

export default function Button({
  mainClass = '',
  width = '',
  variant = 'action',
  type = 'button',
  size = 'md',
  href = '',
  children,
  ...props
}) {
  const variantPrimary = 'bg-primary hover:bg-primary-600 text-white lg:text-base text-sm border border-primary-600 hover:border-primary-700 uppercase font-bold';
  const variantAction = 'bg-action hover:bg-action-600 text-primary lg:text-base text-sm border border-action-600 hover:border-action-700 uppercase font-bold';

  const cl = mainClass || (
    variant === 'primary' ? variantPrimary : variantAction
  );

  const sl = size === 'sm' ? 'py-2.5 lg:px-4 px-3' : 'py-3.5 lg:px-6 px-5';

  if (href) {
    return (
      <Link href={href}>
        <a
          className={`flex justify-center text-center focus:outline-none transition-all ${cl} ${sl} ${width}`}
          {...props}
        >
          {children}
        </a>
      </Link>
    );
  }

  return (
    <button
      className={`flex justify-center text-center focus:outline-none transition-all ${cl} ${sl} ${width}`}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
}
